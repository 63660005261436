import React, { Component } from 'react';
import { InputGroup, Input, ButtonGroup, TabContent, TabPane, Container, Card, CardBody, CardHeader, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import classnames from 'classnames';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';


export class TicketDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };
        this.cancel = this.cancel.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    cancel() {

    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        })
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetTicket(id);
            
            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async handleMessageChange(event) {
        this.setState({ message: event.currentTarget.value });
    }

    async addMessage() {

        var message = this.state.message;
        this.setState({ message: "" });

        if (message) {
            var response = await HengyiApi.AddTicketMessage(this.props.match.params.id, message);
            this.setState({
                data: response.data
            });
        }
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Reports" subTitle="Details about the report you lodged" />


                <Container style={{ "paddingTop": "20px" }}>
                    <div className="mb-3">
                        <h2>Report: {this.state.data && this.state.data.location}
                            <Link to="/requests" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}> Back </Link>
                        </h2>
                        <hr />
                    </div>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>
                                Details
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                disable
                                href="#"
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}>
                                Messages
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Status:</td>
                                                        <td style={{ textTransform: 'capitalize' }}>
                                                            {this.state.data.status && <span>{this.state.data.status}</span>}
                                                            {!this.state.data.status && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Location:</td>
                                                        <td>
                                                            {this.state.data.location && <span>{this.state.data.location}</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Type:</td>
                                                        <td>
                                                            {this.state.data.type && <span>{this.state.data.type.name}</span>}
                                                            {!this.state.data.type && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    {this.state.data.apartment && <tr>
                                                        <td className="pr-2">Space:</td>
                                                        <td>
                                                            {this.state.data.apartment && <Link key={this.state.data.apartment.id + "-view"} to={"/apartments/" + this.state.data.apartment.id}>{this.state.data.apartment.name}</Link>}
                                                            {!this.state.data.apartment && <i>Not specified</i>}
                                                        </td>
                                                    </tr>}
                                                    <tr>
                                                        <td className="pr-2">Floor:</td>
                                                        <td>
                                                            {this.state.data.floor && <span>{this.state.data.floor.name}</span>}
                                                            {!this.state.data.floor && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Category:</td>
                                                        <td>
                                                            {this.state.data.category && <span>{this.state.data.category.name}</span>}
                                                            {!this.state.data.category && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Assigned to:</td>
                                                        <td>
                                                            {this.state.data.assignedTo && <span>{this.state.data.assignedTo.name}</span>}
                                                            {!this.state.data.assignedTo && <i>Not assigned</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Resolved by:</td>
                                                        <td>
                                                            {this.state.data.resolvedBy && <span>{this.state.data.resolvedBy.name}</span>}
                                                            {!this.state.data.resolvedBy && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Category:</td>
                                                        <td>
                                                            {this.state.data.category && <span>{this.state.data.category.name}</span>}
                                                            {!this.state.data.category && <i>Not specified</i>}
                                                        </td>
                                                    </tr>

                                                    <hr />

                                                    <tr>
                                                        <td>
                                                            {this.state.data.description && <span>{this.state.data.description}</span>}
                                                            {!this.state.data.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table></Col>
                                        <Col>
                                            {this.state.data && this.state.data.images.map(item =>
                                                <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + item + ".jpg"} style={{ maxWidth: "250px" }} />
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <div>

                                <Card>
                                    <CardHeader>
                                        <Row className="mt-2">
                                            <Col>
                                                <InputGroup>
                                                    <Input type="text" placeholder="Enter your message to send" value={this.state.message || ''} onChange={this.handleMessageChange} />
                                                    <ButtonGroup className="input-group-append">
                                                        <button style={{ float: "right", padding: "6px" }} onClick={() => { this.addMessage(); }} disabled={this.state.loading} className="btn btn-outline-dark" >SEND</button>
                                                    </ButtonGroup>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>

                                <Card>
                                    <CardBody className="p-0">
                                        {this.state.data && this.state.data.messages &&
                                            <table className='table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                        <th style={{ borderTop: "none" }}>From</th>
                                                        <th style={{ borderTop: "none" }}>Message</th>
                                                    <th className="text-right" style={{ borderTop: "none" }}>Sent</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.data.messages.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.user.name} {item.user.surname}</td>
                                                        <td>{item.message}</td>
                                                        <td className="text-right">
                                                            <Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment>
                                                            
                                                            </td>
                                                        </tr>
                                                    )}
                                                {this.state.data.messages.length === 0 && <tr><td colSpan="3"><h4 className="text-muted text-center mt-3"><i>No notes to display</i></h4></td></tr>}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                </Card>

                            </div>}
                        </TabPane>
                    </TabContent>

                </Container>

            </div>
        );
    }

}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class InvoiceQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithShowVoided(value: Boolean) {
        this.WithParam("showVoided", value);
        return this;
    }

    WithShowDeleted(value: Boolean) {
        this.WithParam("showDeleted", value);
        return this;
    }
}

//******************************
// INVOICES
//******************************
export class HengyiInvoiceClient {

    static async List(params: InvoiceQueryParams) {

        var query = (await GetAPIDomain()) + "/invoices" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/" + id, {
            headers: GetAPIHeaders()
        }));

    }

    static async GetLinks(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/" + id + "/links", {
            headers: GetAPIHeaders()
        }));

    }

    static async IssueForUser(userId: string) {

        var request: any = {
            "userId": userId
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invoices/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

}
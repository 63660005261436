import React, { Component } from 'react';
import { Badge, Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, Card, CardHeader, Spinner } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'react-moment';
import { HengyiParcelClient } from '../infrastructure/HengyiClient/Parcels';
import { ParcelQueryParams } from '../infrastructure/HengyiClient/Parcels';
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox, faBoxes, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Parcels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            parcels: [],
            modal: false,
            id: "",
            parcelDeliveryCost: 0,
            hideCollected: true,
            panelToDisplay: "none",
            selectedParcelId: null
        };
        this.handleHideCollected = this.handleHideCollected.bind(this);
    }

    handleHideCollected = (evt) => {
        this.setState({
            hideCollected: evt.target.checked,
            skip: 0
        }, () => this.fetchData());
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({ loading: true });
        var currentUser = await HengyiApi.GetCurrentUser();

        var parcels = (await HengyiParcelClient.List(new ParcelQueryParams()
            .WithHideCollected(this.state.hideCollected ? "true" : "false")
            .WithUser(currentUser.id)
            .Descending()
            .Sort("created")
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            parcels: parcels.data.data,
            parcelDeliveryCost: currentUser.building.parcelDeliveryCost,
            total: parcels.data.total
        });
    }

    async residentWillCollect(id) {
        this.setState({
            panelToDisplay: "will-collect",
            selectedParcelId: id
        });
    }

    async takeToApartment(id) {
        this.setState({
            panelToDisplay: "drop-off",
            selectedParcelId: id
        });
    }

    renderTable() {
        return (

            <Card>
                <CardHeader>
                    <h2>Your deliveries
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button></h2>
                </CardHeader>

                <CardHeader>

                    <div className="form-group">
                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCollected"
                                        type="checkbox"
                                        checked={this.state.hideCollected}
                                        onChange={this.handleHideCollected} />  Hide collected deliveries
                                        </label>
                            </li>
                        </ul>
                    </div>
                </CardHeader>

                <CardBody className="p-0">
                    <div className="table-responsive">
                        <table className='table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr style={{ borderBottom: "2px solid black" }}>
                                    <th style={{ border: "none" }}>Type</th>
                                    <th style={{ border: "none" }}>Location</th>
                                    <th style={{ border: "none" }}>Notes</th>
                                    <th style={{ border: "none" }}>Collected</th>
                                    <th style={{ border: "none" }}>Status</th>
                                    <th style={{ border: "none" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.parcels.map(item => <>
                                    <tr key={item.id} style={{borderTop: "2px solid black"}}>
                                        <td>
                                            {item.parcelType === "mail" && <Badge color="primary"><FontAwesomeIcon icon={faEnvelope} /> Mail</Badge>}
                                            {item.parcelType === "parcel" && <Badge color="primary">{item.numberOfPackages === 1 && <FontAwesomeIcon icon={faBox} />}{item.numberOfPackages !== 1 && <FontAwesomeIcon icon={faBoxes} />} Parcel</Badge>}
                                        </td>
                                        <td style={{ textTransform: 'capitalize' }}>
                                            {item.location && item.location}
                                            {item.numberOfPackages > 1 && <span> ({item.numberOfPackages})</span>}
                                            {!item.location && <i>Not specified</i>}
                                        </td>
                                        <td style={{ textTransform: 'capitalize' }}>
                                            {item.deliveryService && item.deliveryService}
                                            {!item.deliveryService && <i>None</i>}
                                        </td>
                                        <td>
                                            {item.handedOver && <Moment utc local format="DD/MM h:mma" date={item.handedOver} />}
                                            {!item.handedOver && item.status === 'requestedDropOff' && <i>Not dropped off</i>}
                                            {!item.handedOver && item.status !== 'requestedDropOff' && <i>Not collected</i>}
                                        </td>
                                        <td>
                                            {item.status === "ready" && <Badge color="primary">New delivery</Badge>}
                                            {item.status === "requestedDropOff" && <Badge color="warning">Deliver to my space</Badge>}
                                            {item.status === "requestedPickUp" && <Badge color="info">Ready to collect</Badge>}
                                            {item.status === "collected" && <Badge color="secondary">Collected</Badge>}
                                            {item.status === "delivered" && <Badge color="secondary">Collected</Badge>}
                                            {item.status === "inTransit" && <Badge color="success">In transit</Badge>}
                                        </td>
                                        <td>
                                            {item.status === "ready" &&
                                                <Button key={item.id + "-collect"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.residentWillCollect(item.id)}>I will collect</Button>
                                            }
                                            {item.status === "requestedPickUp" &&
                                                <Button key={item.id + "-collect"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.residentWillCollect(item.id)}>Change date</Button>
                                            }
                                            {item.status === "ready" &&
                                                <Button key={item.id + "-bringup"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.takeToApartment(item.id)}>Please bring it up</Button>
                                            }
                                            {item.status === "requestedDropOff" &&
                                                <Button key={item.id + "-collect"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.takeToApartment(item.id)}>Change date</Button>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6">
                                            Concierge received the parcel at <strong> <Moment utc local format="ddd DD MMM YY">{item.created}</Moment></strong>.
                                          
                                                {item.requestedHandoverDate &&
                                                    <p>
                                                        Parcel requested to be brought up on <strong><Moment utc local format="ddd DD MMM YY">{item.requestedHandoverDate}</Moment></strong> &nbsp;
               
                                            {item.requestedHandoverDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                                        {item.requestedHandoverDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                                        {item.requestedHandoverDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}

                                                    </p>
                                                }
                                        </td>
                                    </tr></>
                                )}
                                {this.state.parcels.length === 0 && <tr>
                                    <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No deliveries to display</i></h4></td>
                                </tr>}
                            </tbody>
                        </table>

                        <Modal isOpen={this.state.panelToDisplay == "drop-off"}>


                            <Formik
                                initialValues={{
                                    collectDate: new Date(),
                                    deliverySlot: "evening"
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    if (!fields.collectDate) {
                                        setFieldError("collectDate", "Please specify the date to drop off");
                                        return;
                                    }

                                    if (!fields.deliverySlot) {
                                        setFieldError("deliverySlot", "Please specify the time to drop off");
                                        return;
                                    }


                                    await HengyiParcelClient.SetParcelAsTakeUpToApartment(this.state.selectedParcelId, fields.collectDate.toISOString(), fields.deliverySlot);
                                    await this.fetchData();

                                    this.setState({
                                        panelToDisplay: "none",
                                        selectedParcelId: null
                                    });
                                }}
                            >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                <Form>

                                        <ModalHeader toggle={() => this.setState({ panelToDisplay: "none" })}> Delivery confirmation</ModalHeader>
                                    <ModalBody>
                                            This will incur a small service feed of <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(this.state.parcelDeliveryCost / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                        <hr />
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="collectDate">Date</label>
                                                    <DatePicker id="collectDate"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        selected={values.collectDate}
                                                        dateFormat='dd/MM/yyyy'
                                                        onChange={(v, f) => {
                                                            setFieldValue("collectDate", v);
                                                        }} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="deliverySlot">Time of day</label>
                                                    <select type="select" name="deliverySlot"
                                                        value={values.deliverySlot}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={'form-control' + (errors.deliverySlot && touched.deliverySlot ? ' is-invalid' : '')}>
                                                        <option value="morning">Morning</option>
                                                        <option value="afternoon">Afternoon</option>
                                                        <option value="evening">Evening</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => this.setState({ panelToDisplay: "none", selectedParcelId: null })}>Cancel request</Button>
                                        <Button disabled={isSubmitting} color="success">
                                            {!isSubmitting && "Confirm"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </Button>
                                    </ModalFooter>

                                </Form>
                            )}</Formik>
                        </Modal>


                        <Modal isOpen={this.state.panelToDisplay == "will-collect"}>

                            <Formik
                                initialValues={{
                                    collectDate: new Date(),
                                    deliverySlot: "evening"
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    if (!fields.collectDate) {
                                        setFieldError("collectDate", "Please specify the date you will collect");
                                        return;
                                    }

                                    if (!fields.deliverySlot) {
                                        setFieldError("deliverySlot", "Please specify the time you will collect");
                                        return;
                                    }


                                    await HengyiParcelClient.SetParcelAsResidentWillCollect(this.state.selectedParcelId, fields.collectDate.toISOString(), fields.deliverySlot);
                                    await this.fetchData();

                                    this.setState({
                                        panelToDisplay: "none",
                                        selectedParcelId: null
                                    });
                                }}
                            >{({ errors, handleChange, handleBlur, status, fields, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                <Form>

                                        <ModalHeader toggle={() => this.setState({ panelToDisplay: "none" })}>Collection confirmation</ModalHeader>
                                    <ModalBody>
                                        Please confirm when you expect to pick up the parcel from front desk
                                        <hr /> 
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="collectDate">Date</label>
                                                    <DatePicker id="collectDate"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        selected={values.collectDate}
                                                        dateFormat='dd/MM/yyyy'
                                                        onChange={(v, f) => {
                                                            setFieldValue("collectDate", v);
                                                        }} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="deliverySlot">Time of day</label>
                                                    <select type="select" name="deliverySlot"
                                                        value={values.deliverySlot}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={'form-control' + (errors.deliverySlot && touched.deliverySlot ? ' is-invalid' : '')}>
                                                        <option value="morning">Morning</option>
                                                        <option value="afternoon">Afternoon</option>
                                                        <option value="evening">Evening</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>

                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={() => this.setState({ panelToDisplay: "none", selectedParcelId: null })}>Cancel request</Button>
                                        <Button disabled={isSubmitting} color="success">
                                            {!isSubmitting && "Confirm"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </Button>
                                    </ModalFooter>

                                </Form>
                                )}</Formik>

                        </Modal>
                    </div>

                </CardBody>
            </Card>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Deliveries" subTitle="Parcels which courier companies have dropped off to the building" />

                <Container style={{ "paddingTop": "20px" }}>
                    {this.renderTable()}
                </Container>
            </div>
        );
    }
}

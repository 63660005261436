import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ListSchedulesQueryParams extends HengyiListQueryParams {

    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithEvent(value: string) {
        this.WithParam("eventId", value);
        return this;
    }

    WithDate(value: string) {
        this.WithParam("date", value);
        return this;
    }
}

export class HengyiSchedulesClient {

    static async List(params: ListSchedulesQueryParams) {

        var query = (await GetAPIDomain()) + "/schedules" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/schedules/" + id, {
            headers: GetAPIHeaders()
        }));
    }

    static async Create(
        name: string,
        timeslot: number,
        daysOfWeek: string[],
        open: string,
        close: string,
        reminderEnabled: boolean,
        reminderText: string,
        reminderLeadTime: string,
        capacity: number,
        facilityId?: string,
        eventId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/schedules", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "timeslot": timeslot,
                "daysOfWeek": daysOfWeek,
                "open": open,
                "close": close,
                "reminderEnabled": reminderEnabled,
                "facilityId": facilityId,
                "eventId": eventId,
                "reminderText": reminderText,
                "reminderLeadTime": reminderLeadTime,
                "capacity": capacity
            })
        }));
    }


    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/schedules/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async SetSpecificDates(
        id: string,
        dates: string[]) {

        var updates = [{
            "op": "replace",
            "path": "specificDates",
            "value": true
        }, {
            "op": "replace",
            "path": "dates",
            "value": dates
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/schedules/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }


    static async SetAnyDates(
        id: string) {

        var updates = [{
            "op": "replace",
            "path": "specificDates",
            "value": false
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/schedules/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Update(
        id: string,
        name: string,
        timeslot: number,
        daysOfWeek: string[],
        open: string,
        close: string,
        reminderEnabled: boolean,
        reminderText: string,
        reminderLeadTime: string,
    capacity: number) {

        var updates = [{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "timeslot",
                "value": timeslot
            }, {
                "op": "replace",
                "path": "daysOfWeek",
                "value": daysOfWeek
            }, {
                "op": "replace",
                "path": "open",
                "value": open
            }, {
                "op": "replace",
                "path": "close",
                "value": close
            }, {
                "op": "replace",
                "path": "reminderEnabled",
                "value": reminderEnabled
            }, {
                "op": "replace",
                "path": "reminderText",
                "value": reminderText
            }, {
                "op": "replace",
                "path": "reminderLeadTime",
                "value": reminderLeadTime
            }, {
                "op": "replace",
                "path": "capacity",
                "value": capacity
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/schedules/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }
}
import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class MessageThreadQueryParams extends HengyiListQueryParams {

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithCampaign(value: string) {
        this.WithParam("campaignId", value);
        return this;
    }

    WithNoneOfStatus(value: string) {
        this.WithParam("noneOfstatus", value);
        return this;
    }

    ExcludeOutboundCampaignsWithNoReply() {
        this.WithParam("ExcludeOutboundCampaignsWithNoReply", true);
        return this;
    }

    WithUnreadForResident() {
        this.WithParam("unreadFor", "toResident");
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiMessageThreadClient {

    static async List(params: MessageThreadQueryParams) {

        var query = (await GetAPIDomain()) + "/message-threads" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/message-threads/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders(),
            method: 'delete'
        }));
    }

    static async MarkAsSeen(id: string) {

        var query = (await GetAPIDomain()) + "/message-threads/" + id+"/mark-as-seen";

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders(),
            method: 'post',
            body: "{}"
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/message-threads/" + id, {
            headers: GetAPIHeaders()
        }));

    }

}
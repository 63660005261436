import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter,Badge, Button, Container, Card, CardBody, Row, Col } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';
import { HengyiEventClient } from '../infrastructure/HengyiClient/Events';
import { HengyiSchedulesClient, ListSchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { BookingNewItem } from '../bookings/BookingNewItem';
import { HengyiBookingsClient, ListBookingQueryParams } from '../infrastructure/HengyiClient/Bookings';
import { PartnerOfferClient } from '../infrastructure/HengyiClient/PartnerOffers';


export class OfferDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, init: false
        };
        this.toggle = this.toggle.bind(this);
    }

    async toggle(id) {
        this.setState({ modal: !this.state.modal, id: id })
    }

    async componentDidMount() {

        this.populateData(this.props.match.params.id);
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        })
    }

    async downloadFile(id, name) {

        var data = await PartnerOfferClient.GetDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        await this.populateData(id);

    }

    async trackLinkClick(id, dest) {
        await PartnerOfferClient.TrackClickEvent(id);
        window.location.href = dest;
    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await PartnerOfferClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }

        if (!this.state.init) {
            this.setState({ init: true });
            await PartnerOfferClient.TrackViewEvent(id);
        }
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.data && this.state.data.imageId ? this.state.assetUrl + "/image/" + this.state.data.imageId + ".jpg" : ""} padding="100px" coverTitle="Offers" subTitle="Details about the offer" />


                <Container style={{ "paddingTop": "20px" }}>
                    <div className="mb-3">
                        {this.state.data && <h2>{this.state.data.name}
                            <Link to="/offers" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}> Back </Link>
                            {this.state.data.fileUploaded && <Link onClick={() => { this.downloadFile(this.state.data.id, this.state.data.fileName); return false; }} to="#" className="btn btn-outline-dark mt-2 mr-2" style={{ padding: "6px", float: "right" }}> Download </Link>}
                            {this.state.data.webLink && <Link onClick={() => { this.trackLinkClick(this.state.data.id, this.state.data.webLink); return false; }} to="#" className="btn btn-outline-dark mt-2 mr-2" style={{ padding: "6px", float: "right" }}> Visit website </Link>}
                        </h2>}
                        <hr />
                    </div>
                    {this.state.data && <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Category:</td>
                                                <td>
                                                    {this.state.data.partnerOfferCategory && this.state.data.partnerOfferCategory.name}
                                                    {!this.state.data.partnerOfferCategory && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td className="pr-2">Expiry:</td>
                                                    <td>
                                                    <i>{this.state.data.expiry && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.expiry}</Moment>}</i>
                                                    {!this.state.data.expiry && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>}

                </Container>

            </div>
        );
    }

}

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { HengyiApi, UserQueryParams, ListQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import "react-datepicker/dist/react-datepicker.css";
import { HengyiMessageClient } from '../infrastructure/HengyiClient/Messages';
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { HeroImage } from '../layout/HeroImage';
import { GetAssetDomain } from '../infrastructure/Helper';

export class ConversationsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

    }
    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                message: "",
                mode: "general"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.message) {
                    setFieldError("message", "Please enter a message to send");
                    return;
                }

                var response = await HengyiMessageClient.Create(fields.message, null);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.threadId });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
               
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-3">
                        <button style={{ padding: "6px" }}   type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SEND"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/messages?currentThreadId=" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="New message" subTitle="Send a new message to the concierge team" />

                    <Container style={{ "paddingTop": "20px" }}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New message
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Send a new message to concierge</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/messages" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {

            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}

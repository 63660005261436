import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, TicketTypeQueryParams, TicketCategoryQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { GetAssetDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiFloorClient, FloorQueryParams } from '../infrastructure/HengyiClient/Floors';
import { HengyiApartmentClient } from '../infrastructure/HengyiClient/Apartments';
import { ApartmentQueryParams } from '../infrastructure/HengyiClient/Apartments';

function required(value) {
    if (!value) {
        return 'This is a required field';
    }
}

export class TicketsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = (await HengyiApi.GetTicketTypes(new TicketTypeQueryParams(0, 50)));
            var user = await HengyiApi.GetCurrentUser();

            var apartments = await HengyiApartmentClient.List(new ApartmentQueryParams()
                .Sort("name")
                .WithUser(user.id)
                .Paginate(0, 50))

            var floorResponse = await HengyiFloorClient.List(new FloorQueryParams().Sort("ordinal", "asc").Paginate(0, 10000));

            this.setState({
                init: true,
                location: "",
                types: response.data.data,
                typeOptions: response.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                floorOptions: floorResponse.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                apartmentOptions: apartments.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }

    renderForm() {

        return (<Formik
            initialValues={{
                ticketTypeId: "",
                apartmentId: "",
                categoryId:"",
                floorId: "",
                location: "",
                description: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.ticketTypeId) {
                    setFieldError("ticketTypeId", 'This is a required field');
                    return;
                }
                if (!fields.categoryId) {
                    setFieldError("categoryId", 'This is a required field');
                    return;
                }
                if (!fields.floorId) {
                    setFieldError("floorId", 'This is a required field');
                    return;
                }

                const params = {
                    ticketTypeId: fields.ticketTypeId.value,
                    ticketCategoryId: fields.categoryId.value,
                    apartmentId: fields.apartmentId ? fields.apartmentId.value : this.state.apartmentOptions.length === 1 ? this.state.apartmentOptions[0].value : null,
                    floorId: fields.floorId.value,
                    description: fields.description,
                    location: fields.location
                };

                const response = await fetch((await GetAPIDomain()) + "/tickets", {
                    method: 'post',
                    headers: GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {


                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }

                } else {

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        await fetch((await GetAPIDomain()) + "/tickets/" + data.id + "/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });
                    }

                    this.setState({ next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <div className="form-group">
                    <SelectField
                        id="ticketTypeId"
                        name="ticketTypeId"
                        label="Type"
                        placeholder="Select a type"
                        options={this.state.typeOptions}
                        value={values.ticketTypeId}
                        isMulti={false}
                        onChange={async (field, value) => {
                            setFieldValue(field, value);

                            var response = (await HengyiApi.GetTicketCategories(new TicketCategoryQueryParams(0, 50, null, value.value)));
                            this.setState({ categoryOptions: response.data.data.map(function (item) { return { value: item.id, label: item.name }; }) });

                            setFieldValue("categoryId", null);
                        }}
                        onBlur={setFieldTouched}
                        touched={touched.ticketTypeId}
                        error={errors.ticketTypeId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                    <ErrorMessage name="ticketTypeId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    {!values.ticketTypeId && <><label className="input-label">Category</label><select className="form-control" placeholder="" disabled="disabled">
                        <option>Please select a type first</option>
                        </select></>}
                        {values.ticketTypeId && <SelectField
                            id="categoryId"
                            name="categoryId"
                            label="Category"
                            placeholder="Select a category"
                            options={this.state.categoryOptions}
                            value={values.categoryId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.categoryId}
                            error={errors.categoryId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        />}
                        <ErrorMessage name="categoryId" component="div" className="invalid-feedback" />
                </div>

                    {this.state.apartmentOptions.length > 1 && <div className="form-group">
                        <SelectField
                            id="apartmentId"
                            name="apartmentId"
                            label="Space"
                            placeholder="Select a space"
                            options={this.state.apartmentOptions}
                            value={values.apartmentId}
                            isMulti={false}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            touched={touched.apartmentId}
                            error={errors.apartmentId}
                            isClearable={true}
                            backspaceRemovesValue={true}
                        />
                        <ErrorMessage name="apartmentId" component="div" className="invalid-feedback" />
                    </div>}

                <div className="form-group">
                    <SelectField
                        id="floorId"
                        name="floorId"
                        label="Floor"
                        placeholder="Which floor is this about?"
                        options={this.state.floorOptions}
                        value={values.floorId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.floorId}
                        error={errors.floorId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                    <ErrorMessage name="floorId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <Field validate={required} name="location" type="text" className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} />
                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="file">Photo</label>
                    <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                        this.setState({
                            file: URL.createObjectURL(event.target.files[0])
                        })
                        setFieldValue("file", event.currentTarget.files[0]);
                    }} />

                    {this.state.file && <div>
                        <h6 style={{ marginTop: "25px" }}>Preview</h6>
                        <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "100%", marginTop: "20px" }} />
                    </div>}
                </div>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "CREATE REPORT"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/requests" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Reports" subTitle="Lodge a new report for us to look at" />


                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <h2>New report
                                    <Link to="/requests" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                                </h2>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}

import React, { Component } from 'react';
import { Container, Spinner, Alert } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiApi } from '../infrastructure/fetchHengy';

function required(value) {
    if (!value)
        return 'This is a required field';
}

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = { complete: false };
    }

    renderForm() {
        return (<Formik
            initialValues={{
                email: "",
                subject: "Enquiry",
                content: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                await HengyiApi.ContactUs(fields.email, fields.subject, fields.content)

               
                this.setState({ complete: true });

            }}
            render={({ errors, status, touched, isSubmitting, values }) => (
                <Form>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field name="email" validate={required} type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <Field name="subject" type="text" validate={required} className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')} />
                        <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="content">Message</label>
                        <Field name="content" validate={required} type="text" component="textarea" className={'form-control' + (errors.content && touched.content ? ' is-invalid' : '')} />
                        <ErrorMessage name="content" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SUBMIT"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccess() {
        return (<div><Alert color="success">
            <h5>Success</h5>
            We have sent your message to the team
                    </Alert>
            <button onClick={() => { this.setState({ complete: false }) }} className="btn mr-2 btn-outline-dark mt-2">DONE</button></div>);
    }

    render() {
        return (
            <Container className="privacyPolicy" style={{ "paddingTop": "130px" }}>
                <h1>Contact us</h1>
                <hr style={{ "paddingBottom": "40px" }} />

                {!this.state.complete && this.renderForm()}
                {this.state.complete && this.renderSuccess()}
               
            </Container>
        );
    }
}

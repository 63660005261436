import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { HengyiMessageClient } from '../infrastructure/HengyiClient/Messages';
import { HengyiBookingsClient } from '../infrastructure/HengyiClient/Bookings';

export class ConversationsBookingsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                message: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.message) {
                    setFieldError("message", "Please enter a message to send");
                    return;
                }

                var response = await HengyiMessageClient.CreateForBooking(fields.message, this.props.match.params.id, this.state.data.user.id);

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true, id: response.data.threadId });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mt-3">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "SEND"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/messages?currentThreadId=" + this.state.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New conversation
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Start a new conversation with {this.state.data && this.state.data.user && (this.state.data.user.name + " " + this.state.data.user.surname)} about the booking</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/bookings/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {

            this.setState({ loading: true });
            var booking = await HengyiBookingsClient.Get(this.props.match.params.id);

            this.setState({
                init: true,
                data: booking.data,
                loading: false
            });
        }
    }
}

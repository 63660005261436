import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class VehicleQueryParams extends HengyiListQueryParams {

    WithFuelType(value: string) {
        this.WithParam("fuelType", value);
        return this;
    }

    WofExpired(value: Boolean) {
        this.WithParam("wofExpired", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

}

//******************************
// VEHICLES
//******************************
export class HengyiVehicleClient {

    static async List(params: VehicleQueryParams) {

        var query = (await GetAPIDomain()) + "/vehicles" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/vehicles/" + id, {
            headers: GetAPIHeaders()
        }));

    }

    static async Create(colour: string, fuelType: string, licensePlate?: string,
        make?: string, model?: string, wofExpiry?: string,
        userId?: string, apartmentId?: string) {

        var request : any = {
            colour: colour,
            fuelType: fuelType,
            licensePlate: licensePlate,
            make: make,
            model: model
        }

        if (wofExpiry)
            request.wofExpiry = wofExpiry;

        if (userId)
            request.userId = userId;

        if (apartmentId)
            request.apartmentId = apartmentId;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/vehicles/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(id: string, colour: string, fuelType: string,
        licensePlate?: string, make?: string, model?: string, wofExpiry?: string,
        userId?: string, apartmentId?: string, registrationExpiry?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/vehicles/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "colour",
                "value": colour
            }, {
                "op": "replace",
                "path": "fuelType",
                "value": fuelType
            }, {
                "op": "replace",
                "path": "licensePlate",
                "value": licensePlate
            }, {
                "op": "replace",
                "path": "make",
                "value": make
            }, {
                "op": "replace",
                "path": "model",
                "value": model
            }, {
                "op": "replace",
                "path": "wofExpiry",
                "value": wofExpiry
                }, {
                    "op": "replace",
                    "path": "registrationExpiry",
                    "value": registrationExpiry
                }, {
                    "op": "replace",
                    "path": "userId",
                    "value": userId
                }, {
                    "op": "replace",
                    "path": "apartmentId",
                    "value": apartmentId
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/vehicles/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders(),
            method: 'delete'
        }));
    }
}
import { GetAPIDomain, GetAPIHeaders } from './Helper';

/////////////////// NEW ENDPOINT FOR LISTINGS ///////////////////

export class HengyiListQueryParams {

    constructor() {
        this.skip = 0;
        this.take = 50;
        this.direction = "ascending"
        this.params = {};
    }

    Sort(sort: string) {
        this.sort = sort;
        return this;
    }

    Descending() {
        this.direction = "descending";
        return this;
    }

    Ascending() {
        this.direction = "ascending";
        return this;
    }

    Paginate(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
        return this;
    }

    Search(search: string) {
        if (search) {
            this.params.q = search;
        } else {
            delete this.params.q;
        }
        return this;
    }

    WithStart(value: any) {
        this.WithParam("start", value);
        return this;
    }

    WithEnd(value: any) {
        this.WithParam("end", value);
        return this;
    }

    WithParam(param: string, value: any) {
        if (param) {
            this.params[param] = value;
        } else {
            delete this.params[param];
        }
        return this;
    }

    skip: number;
    take: number;
    direction: string;
    params: any;
    sort?: string;

    GenerateQueryString() {
        var query = "?skip=" + this.skip + "&take=" + this.take;
        if (this.sort)
            query = query + "&sort=" + this.sort + "&direction=" + this.direction;

        if (this.params) {
            for (var property in this.params) {
                if (this.params.hasOwnProperty(property)) {
                    var val = this.params[property];
                    if (val != null) {
                        if (val instanceof Date)
                            query = query + "&" + property + "=" + (<Date>val).toISOString();
                        else
                            query = query + "&" + property + "=" + val;
                    }
                }
            }
        }

        return query;
    }
}

export class HengyiApiResponse {

    constructor() {
        this.successful = false;
        this.authenticated = false;
    }

    static async Create(response: Response) {
        var result = new HengyiApiResponse();

        result.authenticated = true;


        if (!response.ok) {
            result.successful = false;

            if (response.status === 401) {
                HengyiApi.LogOut();
                result.authenticated = false;
            } else {
                try {
                    var data = await response.json();
                    result.data = data;
                    result.validationErrors = data.validationErrors;
                } catch (ex) {
                    console.log("No validate errors for this request");
                }
            }
        } else {
            result.successful = true;
            try {
                result.data = await response.json();
            } catch (ex) {
                console.log("No content for this request");
            }
        }

        return result;
    }

    successful: Boolean;
    validationErrors: any;
    authenticated: Boolean;
    data: any;
}

/////////////////////////////////////////////////////////////////

export class ApiResponse {

    constructor(authenticated: Boolean, data: any) {
        this.successful = false;
        this.authenticated = authenticated;
        this.data = data;
    }

    static async Create(response: Response) {
        var result = new ApiResponse(true, null);

        if (!response.ok) {
            if (response.status === 401) {
                HengyiApi.LogOut();
                result.authenticated = false;
            } else {
                try {
                    var data = await response.json();
                    result.data = data;
                    result.validationErrors = data.validationErrors;
                } catch (ex) {
                    console.log("No validate errors for this request");
                }
            }
        } else {
            result.successful = true;
            try {
                result.data = await response.json();
            } catch (ex) {
                console.log("No content for this request");
            }
        }

        return result;
    }

    successful: Boolean;
    validationErrors: any;
    authenticated: Boolean;
    data: any;
}

export class ListQueryParams {

    constructor(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
    }

    skip: number;
    take: number;
}

export class TicketTypeQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string) {
        super(skip, take);
        this.search = search;
    }

    search?: string;
}

export class TicketCategoryQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string, ticketTypeId?: string) {
        super(skip, take);
        this.search = search;
        this.ticketTypeId = ticketTypeId;
    }

    search?: string;
    ticketTypeId?: string;
}
export class TicketQueryParams extends HengyiListQueryParams {

    WithHideResolved(value: Boolean) {
        this.WithParam("hideResolved", value);
        return this;
    }

    WithHideCancelled(value: Boolean) {
        this.WithParam("hideCancelled", value);
        return this;
    }


    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithType(value: string) {
        this.WithParam("ticketTypeId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithEmailed(value: string) {
        this.WithParam("emailed", value);
        return this;
    }

}


export class BookingQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string, status?: string) {
        super(skip, take);
        this.search = search;
        this.status = status;
    }

    search?: string;
    status?: string;
}

export class FacilityQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string, status?: string) {
        super(skip, take);
        this.search = search;
        this.status = status;
    }

    search?: string;
    status?: string;
}

export class ScheduleQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string, daysOfWeek?: number, facilityId?: string, eventId?: string, serviceProviderId?: string) {
        super(skip, take);
        this.search = search;
        this.daysOfWeek = daysOfWeek;
        this.facilityId = facilityId;
        this.eventId = eventId;
        this.serviceProviderId = serviceProviderId;
    }

    search?: string;
    daysOfWeek?: number;
    facilityId?: string;
    eventId?: string;
    serviceProviderId?: string;
}

export class BookingTypeQueryParams extends ListQueryParams {

    constructor(skip: number, take: number, search?: string, scheduleId?: string) {
        super(skip, take);
        this.search = search;
        this.scheduleId = scheduleId;
    }

    search?: string;
    scheduleId?: string;
}


export class HengyiApi {

    //******************************
    // AUTHENTICATION
    //******************************

    static refresh_lock = false;

    //SIGN IN
    static async SignIn(username: string, password: string, setError?: Function) {

        let formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('client_id', '5f3d5a23-cb78-44e1-af18-ac962fe85daf');
        formData.append('grant_type', 'password');

        const response = await fetch((await GetAPIDomain()) + "/connect/token", {
            body: formData,
            method: "post"
        });

        if (!response.ok) {

            localStorage.clear();

            if (setError) {
                const data = await response.json();
                setError(data.error_description);
            }

            return false;
        }

        const data = await response.json();

        localStorage.setItem("acess-token", data.access_token);
        localStorage.setItem("refresh-token", data.refresh_token);
        localStorage.setItem("expires", new Date((new Date()).getTime() + data.expires_in * 1000).toISOString());

        return true;
    }

    //REFRESH TOKEN
    static async RefreshToken() {

        if (!this.refresh_lock) {
            this.refresh_lock = true;

            var token = localStorage.getItem("refresh-token");

            if (!token) {
                localStorage.clear();
                return;
            }

            let formData = new URLSearchParams();
            formData.append('refresh_token', token);
            formData.append('client_id', '5f3d5a23-cb78-44e1-af18-ac962fe85daf');
            formData.append('grant_type', 'refresh_token');

            const response = await fetch((await GetAPIDomain()) + "/connect/token", {
                body: formData,
                method: "post"
            });

            if (!response.ok) {
                localStorage.clear();
                return false;
            }

            const data = await response.json();

            localStorage.setItem("acess-token", data.access_token);
            localStorage.setItem("refresh-token", data.refresh_token);
            localStorage.setItem("expires", new Date((new Date()).getTime() + data.expires_in * 1000).toISOString());

            this.refresh_lock = false;

            return true;
        }
    }

    //SIGN OUT
    static LogOut() {
        localStorage.removeItem("acess-token");
        localStorage.removeItem("refresh-token");
        localStorage.clear();
        return;
    }

    //IS AUTHENTICATED
    static IsAuthenticated() {

        if (localStorage == null)
            return false;

        if (localStorage.getItem("acess-token") == null)
            return false;

        var token = localStorage.getItem("acess-token");
        if (token != null && token.length === 0)
            return false;

        return true;
    }


    //******************************
    // DOCUMENTS
    //******************************

    //COUNT OF NEW FILES
    static async GetNewFileCount() {

        var query = (await GetAPIDomain()) + "/documents?take=1&documentType=file&newDocuments=true";

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401) {
                await this.LogOut();
                return new ApiResponse(false, null);
            }
        }

        if (!response.ok) {
            if (response.status === 401) {
                await this.LogOut();
                return new ApiResponse(false, null);
            }
        }

        return new ApiResponse(true, (await response.json()).total);
    }

    //REQUEST DOCUMENT INFORMATION
    static async RequestDocumentInformation(id: string) {
        const response = await fetch((await GetAPIDomain()) + "/documents/" + id + "/request-more-info", {
            method: 'post',
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401) {
                await this.LogOut();
                return new ApiResponse(false, null);
            }
        }

        return new ApiResponse(true, null);
    }

    //GET ONE DOCUMENT
    static async GetDocument(id: string) {

        if (id === "home")
            return new ApiResponse(true, null);

        const response = await fetch((await GetAPIDomain()) + "/documents/" + id, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, (await response.json()));
    }

    static async DeleteDocument(id: string) {

        if (id === "home")
            return new ApiResponse(true, null);

        const response = await fetch((await GetAPIDomain()) + "/documents/" + id, {
            headers: GetAPIHeaders(),
            method: 'delete'
        });

        return new ApiResponse(true, null);
    }

    static async GetDocumentDownloadLink(id: string) {

        if (id === "home")
            return null;

        const response = await fetch((await GetAPIDomain()) + "/documents/" + id + "/download", {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return;
        }

        return (await response.json());
    }

    static async GetFiles(parentId: string) {
        var query = (await GetAPIDomain()) + "/documents?documentType=file&sort=weight";

        if (parentId) {
            if (parentId === "home") {
                query = query + "&rootLevel=true"
            } else {
                query = query + "&parentId=" + parentId

            }
        }

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return;
        }

        return (await response.json()).data;
    }

    static async GetDocuments(parentId: string) {
        var query = (await GetAPIDomain()) + "/documents?documentType=directory&sort=weight";

        if (parentId) {
            if (parentId === "home") {
                query = query + "&rootLevel=true"
            } else {
                query = query + "&parentId=" + parentId

            }
        }

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return;
        }

        return (await response.json()).data;
    }

    static async BeginPasswordReset(email: string) {
        const response = await fetch((await GetAPIDomain()) + "/reset-password/begin", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "email": email
            })
        });

        return (await response.json()).successful;
    }

    static async CompletePasswordReset(code: string, password: string) {

        const response = await fetch((await GetAPIDomain()) + "/reset-password/complete", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": code,
                "password": password
            })
        });

        const data = await response.json();

        var details = new HengyiApiResponse();
        details.successful = response.ok;

        if (!response.ok) {
            details.validationErrors = data.validationErrors;
        } else {
            details.data = data;
        }

        return details;
    }


    static async ContactUs(email: string, subject: string, content: string) {
        await fetch((await GetAPIDomain()) + "/contact-us", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "subject": subject,
                "email": email,
                "content": content
            })
        });

        return true;
    }

    static async GetCurrentUser() {
        var accessToken = localStorage.getItem("acess-token");
        if (!accessToken)
            return;

        const userResponse = await fetch((await GetAPIDomain()) + "/users/current", {
            headers: GetAPIHeaders()
        });

        if (userResponse.ok) {
            return await userResponse.json();
        }

        await this.LogOut();

        return;
    }


    static async GetCurrentUserActions() {
        var accessToken = localStorage.getItem("acess-token");
        if (!accessToken)
            return;

        const userResponse = await fetch((await GetAPIDomain()) + "/users/current/actions", {
            headers: GetAPIHeaders()
        });

        if (userResponse.ok) {
            return await userResponse.json();
        }

        await this.LogOut();

        return;
    }


    static async SetUserPassword(existingPassword: string, newPassword: string) {
        const response = await fetch((await GetAPIDomain()) + "/reset-password/with-existing-credentials", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "existingPassword": existingPassword,
                "newPassword": newPassword
            })
        });

        return await ApiResponse.Create(response);
    }

    static async CompleteInvitation(invitationCode: string, email: string, password: string) {

        const response = await fetch((await GetAPIDomain()) + "/resident-invitations/complete", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": invitationCode,
                "email": email,
                "password": password
            })
        });

        const data = await response.json();

        var details = new HengyiApiResponse();
        details.successful = response.ok;

        if (!response.ok) {
            details.validationErrors = data.validationErrors;
        } else {
            details.data = data;
        }

        return details;
    }

    static async CheckInvitation(invitationCode: string) {

        const response = await fetch((await GetAPIDomain()) + "/resident-invitations/validate", {
            method: 'post',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify({
                "code": invitationCode
            })
        });

        const data = await response.json();

        return data.status;
    }


    //******************************
    // TICKETS
    //******************************

    //ADD NEW TICKET MESSAGE
    static async AddTicketMessage(ticketId: string, message: string) {

        const response = await fetch((await GetAPIDomain()) + "/tickets/" + ticketId +"/messages", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "message": message
            })
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, (await response.json()));
    }

    //GET TICKETS
    static async GetTickets(params: TicketQueryParams) {

        var query = (await GetAPIDomain()) + "/tickets" + params.GenerateQueryString();

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET ONE TICKET
    static async GetTicket(id: string) {

        const response = await fetch((await GetAPIDomain()) + "/tickets/" + id, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, (await response.json()));
    }

    //GET TICKET TYPES
    static async GetTicketTypes(params: TicketTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/ticket-types?skip=" + params.skip + "&take=" + params.take;

        if (params.search)
            query = query + "&q=" + params.search;

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET TICKET Categories
    static async GetTicketCategories(params: TicketCategoryQueryParams) {

        var query = (await GetAPIDomain()) + "/ticket-categories?skip=" + params.skip + "&take=" + params.take;

        if (params.search)
            query = query + "&q=" + params.search;

        if (params.ticketTypeId)
            query = query + "&ticketTypeId=" + params.ticketTypeId;

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }


    //GET BOOKINGS
    static async GetBookings(params: BookingQueryParams) {

        var query = (await GetAPIDomain()) + "/bookings?skip=" + params.skip + "&take=" + params.take;

        if (params.status)
            query = query + + "&status=" + params.status;

        if (params.search)
            query = query + "&q=" + params.search;


        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET ONE BOOKING
    static async GetBooking(id: string) {

        const response = await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, (await response.json()));
    }

    //POST CANCEL BOOKING
    static async CancelBooking(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id + "/cancel", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "reasonCancelled":"Cancelled by the user"
            })
        }));
    }
    
    //POST BOOKING
    static async CreateBooking(notes: string, contactName: string, contactNumber: string, userId: string, bookingTypeId: string, scheduleId: string, start: string, end: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "notes": notes,
                "contactName": contactName,
                "contactNumber": contactNumber,
                "userId": userId,
                "bookingTypeId": bookingTypeId,
                "scheduleId": scheduleId,
                "status": 0,
                "start": start,
                "end": end,
            })
        }));
    }

    //GET FACILITIES
    static async GetFacilities(params: FacilityQueryParams) {

        var query = (await GetAPIDomain()) + "/facilities?skip=" + params.skip + "&take=" + params.take + "&status=" + params.status;

        if (params.search)
            query = query + "&q=" + params.search;


        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    static async GetFacilityById(id:string) {

        var query = (await GetAPIDomain()) + "/facilities/"+id;

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET SCHEDULES
    static async GetSchedules(params: ScheduleQueryParams) {

        var query = (await GetAPIDomain()) + "/schedules?skip=" + params.skip + "&take=" + params.take + "&daysOfWeek=" + params.daysOfWeek + "&facilityId=" + params.facilityId;

        if (params.search)
            query = query + "&q=" + params.search;


        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET BOOKING TYPES
    static async GetBookingTypes(params: BookingTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/booking-types?skip=" + params.skip + "&take=" + params.take + "&scheduleId=" + params.scheduleId;

        if (params.search)
            query = query + "&q=" + params.search;


        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }

    //GET BOOKING SLOTS
    static async GetBookingSlots(scheduleId: string, bookindate: string) {

        var query = (await GetAPIDomain()) + "/booking-slots?scheduleId=" + scheduleId + "&bookingDate=" + bookindate;

        const response = await fetch(query, {
            headers: GetAPIHeaders()
        });

        if (!response.ok) {
            if (response.status === 401)
                await this.LogOut();
            return new ApiResponse(false, null);
        }

        return new ApiResponse(true, await response.json())
    }
}


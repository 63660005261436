import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { Link } from 'react-router-dom';
import { GetAssetDomain } from './infrastructure/Helper';

export class OnboardingTwo extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
    }

    render() {

        return (

            <div>
                <HeroImage hideNav={true} backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Your pets" subTitle="Do you have a pet? Set up a pet passport for building access" />
                <Container style={{ "paddingTop": "20px" }}>
                    <h2>Your pets</h2>
                    <hr />
                    <h3><i>This feature is in development</i></h3>

                    <Link to="/onboarding/step-01" className="btn btn-primary mr-2">Back</Link>
                    <Link to="/" className="btn btn-primary mr-2">Finish</Link>
                </Container>
            </div>
        );

    }
}

OnboardingTwo.contextType = userContext;
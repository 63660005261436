import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import Moment from 'react-moment';
import { HengyiPetClient, PetQueryParams } from '../../infrastructure/HengyiClient/Pets';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class Pets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            pets: [],
            modal: false,
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var pets = (await HengyiPetClient.List(new PetQueryParams()
            .WithUser(currentUser.id)
            .Sort("name")
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            pets: pets.data.data,
            total: pets.data.total
        });
    }

    async deletePet() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            await HengyiPetClient.Delete(this.state.id);


            this.setState({
                loading: false
            });

            this.toggle("");
            await this.fetchData();
        }
    }

    async toggle(id) {
        this.setState({ modal: !this.state.modal, id: id })
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}></th>
                            <th style={{ borderTop: "none" }}>Name</th>
                            <th style={{ borderTop: "none" }}>Breed</th>
                            <th style={{ borderTop: "none" }}>Type</th>
                            <th style={{ borderTop: "none" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.pets.map(item =>
                            <tr key={item.id}>
                                <td style={{ textTransform: 'capitalize', lineHeight: "50px" }}>
                                    {!item.profileImageId && <i>No image</i>}
                                    {item.profileImageId && <img alt="Uploaded by submitted" className="rounded-circle" src={this.state.assetUrl + "/image/" + item.profileImageId + ".jpg"} style={{ width: "50px", height: "50px", objectFit: "cover" }} />}
                                </td>
                                <td style={{ textTransform: 'capitalize', lineHeight: "50px" }}>
                                    {item.name && item.name}
                                    {!item.name && <i>Not specified</i>}
                                </td>
                                <td style={{ textTransform: 'capitalize', lineHeight: "50px" }}>
                                    {item.breed && item.breed}
                                    {!item.breed && <i>Not specified</i>}
                                </td>
                                <td style={{ textTransform: 'capitalize', lineHeight: "50px" }}>
                                    {item.type && item.type}
                                    {!item.type && <i>Not specified</i>}
                                </td>
                                <td>
                                    <Button key={item.id + "-delete"} style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.toggle(item.id)}>Delete</Button>
                                    <Link to={"/profile/pets/edit/" + item.id} className="btn btn-outline-dark" style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }}>Edit</Link>
                                </td>
                            </tr>
                        )}
                        {this.state.pets.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No pets to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={() => this.toggle()}>Remove pet</ModalHeader>
                    <ModalBody>
                        This permanently remove the pet from your profile
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.toggle("")}>Cancel</Button>
                        <Button color="danger" onClick={() => this.deletePet()}>Remove</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Pets" subTitle="Please register your pets for their access to the building" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>
                            <h2>Your pets
                            {this.state.loading && <Spinner animation="border" />}
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                                <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/profile/pets/new" className="btn btn-outline-dark mr-2">Register pet</Link>
                            </h2>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.renderTable()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { TabContent, TabPane, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import classnames from 'classnames';
import { CreditCardsTable } from './CreditCardsTable';
import { InvoicesTable } from './InvoicesTable';
import { ChargesTable } from './ChargesTable';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class Billing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        return (

            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Billing" subTitle="View your account, invoices, and manage your payment methods" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Nav pills style={{ boxShadow: "none" }}>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>
                                Account
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}>
                                Invoices
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}>
                                Credit Cards
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && <ChargesTable onInvoiceClick={() => { this.toggle('2'); return false; }} />}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <InvoicesTable />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && <CreditCardsTable />}
                        </TabPane>
                    </TabContent>

                </Container>
            </div>
        );
    }
}

import React from 'react';

const userContext = React.createContext({
    user: {
        authenticated: localStorage.getItem("access-token") != null
    }
}); // Create a context object

export {
    userContext // Export it so it can be used by other Components
};
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApartmentClient } from '../../infrastructure/HengyiClient/Apartments';
import { HeroImage } from '../../layout/HeroImage';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class ApartmentEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, next: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });

        var response = await HengyiApartmentClient.Get(this.props.match.params.id)

        this.setState({
            loading: false,
            init: true,

            description: response.data.description,
            name: response.data.name,

            lettingAgentName: response.data.lettingAgentName,
            lettingAgentBusiness: response.data.lettingAgentBusiness,
            lettingAgentMobile: response.data.lettingAgentMobile,
            lettingAgentEmail: response.data.lettingAgentEmail,

            hasAccessibilityRequirements: response.data.hasAccessibilityRequirements,
            accessibilityRequirement: response.data.accessibilityRequirement,
            accessibilityOrientationRequired: response.data.accessibilityOrientationRequired,
            accessibilityMedicalRequired: response.data.accessibilityMedicalRequired
        });
    }

    render() {

        if (this.state.next) {
            return (<Redirect to={"/profile/apartments"} />);
        } else {
            return (<div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Your space" subTitle="Manage your details about your space" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update space details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Keep your space details up to date</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ padding: "6px", float: "right" }} to="/profile/apartments" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.state.init && <Formik
                                initialValues={{

                                    description: this.state.description,
                                    name: this.state.name,

                                    lettingAgentName: this.state.lettingAgentName,
                                    lettingAgentBusiness: this.state.lettingAgentBusiness,
                                    lettingAgentMobile: this.state.lettingAgentMobile,
                                    lettingAgentEmail: this.state.lettingAgentEmail,

                                    hasAccessibilityRequirements: this.state.hasAccessibilityRequirements,
                                    accessibilityRequirement: this.state.accessibilityRequirement,
                                    accessibilityOrientationRequired: this.state.accessibilityOrientationRequired,
                                    accessibilityMedicalRequired: this.state.accessibilityMedicalRequired

                                }}
                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    if (fields.hasAccessibilityRequirements && !fields.accessibilityRequirement) {
                                        setFieldError("accessibilityRequirement", "Please enter the accessibility requirements");
                                        return;
                                    }

                                    var response = await HengyiApartmentClient.Update(
                                        this.props.match.params.id,
                                        fields.name,
                                        fields.description,
                                        fields.lettingAgentName,
                                        fields.lettingAgentBusiness,
                                        fields.lettingAgentMobile,
                                        fields.lettingAgentEmail);

                                    await HengyiApartmentClient.UpdateAccessibilityRequirements(
                                        this.props.match.params.id,
                                        fields.hasAccessibilityRequirements,
                                        fields.accessibilityRequirement,
                                        fields.accessibilityOrientationRequired,
                                        fields.accessibilityMedicalRequired);

                                    if (!response.successful) {

                                        response.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });

                                    } else {
                                        this.setState({ next: true });
                                    }

                                }}
                            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                <Form>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <Field name="name" type="text" className="form-control" />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="description">Description</label>
                                                    <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                </div>

                                            </Col>
                                        </Row>

                                        <h6 className="mt-4">Letting agent</h6>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="lettingAgentName">Name</label>
                                                    <Field name="lettingAgentName" type="text" className="form-control" />
                                                    <ErrorMessage name="lettingAgentName" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="lettingAgentBusiness">Business</label>
                                                    <Field name="lettingAgentBusiness" type="text" className="form-control" />
                                                    <ErrorMessage name="lettingAgentBusiness" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="lettingAgentMobile">Mobile</label>
                                                    <Field name="lettingAgentMobile" type="text" className="form-control" />
                                                    <ErrorMessage name="lettingAgentMobile" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="lettingAgentEmail">Email</label>
                                                    <Field name="lettingAgentEmail" type="text" className="form-control" />
                                                    <ErrorMessage name="lettingAgentEmail" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <h6 className="mt-4">Accessibility</h6>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <label><Field name="hasAccessibilityRequirements" type="checkbox" checked={values.hasAccessibilityRequirements} /> Space has accessibility requirements</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                        {values.hasAccessibilityRequirements && <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="accessibilityRequirement">What are the accessibility requirements?*</label>
                                                    <Field name="accessibilityRequirement" type="text" className={'form-control' + (errors.accessibilityRequirement && touched.accessibilityRequirement ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="accessibilityRequirement" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="accessibilityMedicalRequired">Any medical requirements to be mindful of?</label>
                                                    <Field name="accessibilityMedicalRequired" type="text" className="form-control" />
                                                    <ErrorMessage name="accessibilityMedicalRequired" component="div" className="invalid-feedback" />
                                                </div>
                                            </Col>
                                        </Row>}

                                        {values.hasAccessibilityRequirements && <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <label><Field name="accessibilityOrientationRequired" type="checkbox" checked={values.accessibilityOrientationRequired} /> Space users require an accessibility orientation</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>}

                                    <div className="form-group mt-3">
                                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                            {!isSubmitting && "SAVE"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </button>
                                    </div>
                                </Form>
                            )}</Formik>}
                        </CardBody>
                    </Card>
                </Container>
            </div>);
        }
    }
}

import React, { Component } from 'react';
import { Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiInvitationClient, InvitationQueryParams } from '../../infrastructure/HengyiClient/Invitations';

export class InvitationsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };

        this.cancelInvite = this.cancelInvite.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }


    async cancelInvite(id) {
        await HengyiInvitationClient.DeleteInvitation(id);
        await this.fetchData();
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();

        var response = (await HengyiInvitationClient.List(new InvitationQueryParams()
            .WithCreatedByUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th style={{ borderTop: "none" }}>Details</th>
                        <th style={{ borderTop: "none" }}>User</th>
                        <th style={{ borderTop: "none" }}>Status</th>
                        <th style={{ borderTop: "none" }}>Space</th>
                        <th style={{ borderTop: "none" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data && this.state.data.map(invite =>
                        <tr key={invite.id}>
                            <td>
                                {invite.name} {invite.surname}
                                <p className="muted">{invite.email}</p>
                            </td>
                            <td>
                                {invite.user && invite.user.name+" "+invite.user.surname}
                                {!invite.user && <i>Invite not used</i>}
                            </td>
                            <td style={{ textTransform: 'capitalize' }}>{invite.status}</td>
                            <td>
                                {invite.apartments && invite.apartments.length > 0 &&
                                    <ul className="list-unstyled">
                                        {invite.apartments.map(apartment =>
                                            <li key={invite.id + "-" + apartment.id}> {apartment.name}</li>
                                        )}
                                    </ul>
                                }
                                {invite.apartments.length === 0 && <i>None</i>}
                            </td>
                            <td>
                                {invite.status === "active" && <button style={{ float: "right", padding: "6px" }} onClick={() => this.cancelInvite(invite.id)} className="btn mr-2 btn-outline-dark mt-2">Cancel</button>}
                              
                            </td>
                        </tr>
                    )}
                    {this.state.data && this.state.data.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No invitations to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        return (
            <Card style={{ marginTop: "40px" }}>
                <CardHeader>
                    <h2>Invitations
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/profile/occupants/new" className="btn btn-outline-dark mr-2">Invite new user</Link>
                    </h2>
                </CardHeader>
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }
}

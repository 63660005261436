import React, { Component } from 'react';
import { Container, Alert, Spinner } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { HengyiApi } from './infrastructure/fetchHengy';
import { GetAssetDomain } from './infrastructure/Helper';

export class InvitationResponse extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, next: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    renderForm() {
        return (<Formik
            initialValues={{
                email: '',
                password: '',
                acceptTerms: false,
                acceptPrivacy: false
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.acceptTerms) {
                    setFieldError("acceptTerms", "You must review & accept the terms to sign up.");
                }

                if (!fields.acceptPrivacy) {
                    setFieldError("acceptPrivacy", "You must review & accept the privacy policy to sign up.");
                }

                if (!fields.acceptTerms)
                    return;
                if (!fields.acceptPrivacy)
                    return;

                var response = await HengyiApi.CompleteInvitation(this.props.match.params.code, fields.email, fields.password);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    await HengyiApi.SignIn(fields.email, fields.password);
                    this.context.refresh();
                    this.setState({
                        next: true
                    });
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Create new password</label>
                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group form-check">
                        <Field id="acceptTerms" name="acceptTerms" type="checkbox" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                        <label htmlFor="acceptTerms" className="form-check-label">I have read and accepted the <a href="/terms-and-conditions" target="_blank">terms and conditions  <FontAwesomeIcon icon={faExternalLinkAlt} /></a></label>
                        <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group form-check">
                        <Field id="acceptPrivacy" name="acceptPrivacy" type="checkbox" className={'form-check-input ' + (errors.acceptPrivacy && touched.acceptPrivacy ? ' is-invalid' : '')} />
                        <label htmlFor="acceptPrivacy" className="form-check-label">I have read and accepted the <a href="/privacy-policy" target="_blank">privacy policy <FontAwesomeIcon icon={faExternalLinkAlt} /></a></label>
                        <ErrorMessage name="acceptPrivacy" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "JOIN NOW"}
                            {isSubmitting && <Spinner animation="border" /> }
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderContent() {

            this.checkCodeStatus(this.props.match.params.code);
        if (this.state.next === true) {
            return (<Redirect to="/onboarding/step-01" />);
        }else 
            if (this.state.loading) {
                return (
                    <h4><i>Loading...</i></h4>)
            } else {
                if (this.state.codeStatus === "notFound") {
                    return (<Alert color="danger">
                        Invalid invitation code
                    </Alert>);
                } else if (this.state.codeStatus === "expired") {
                    return (<Alert color="danger">
                        Your invite as expired, please contact us to request a new one
                    </Alert>);
                } else if (this.state.codeStatus === "used") {
                    return (<Alert color="warning">
                        This invite has already been used, please sign in
                    </Alert>);
                } else if (this.state.codeStatus === "active") {
                    return this.renderForm();
                } else {
                    return (<Alert color="primary">
                        Invalid invitation code, please contact us to request a new one
                    </Alert>);
                }
            }
    }

    render() {

        return (

            <div>
                <HeroImage hideNav={true} backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Welcome Home" subTitle="Complete the invitation process to join" />
                <Container style={{ "paddingTop": "20px" }}>
                    <h2>Your invitation</h2>
                    <hr />
                    {this.renderContent()}
                </Container>
            </div>
        );

    }

    async checkCodeStatus(code) {

        if (this.state.codeStatus === "") {

            this.setState({ codeStatus: await HengyiApi.CheckInvitation(code), loading: false });
        }
    }
}

InvitationResponse.contextType = userContext;
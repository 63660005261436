import React, { Component } from 'react';
import { TabContent, TabPane, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import classnames from 'classnames';
import { OccupantsTable } from './OccupantsTable';
import { InvitationsTable } from './InvitationsTable';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class Occupants extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        return (

            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Occupants" subTitle="Manage who has access to the resident portal for your space" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Nav pills style={{ boxShadow: "none" }}>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>
                                Occupants
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}>
                                Invitations
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && <OccupantsTable />}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <InvitationsTable />}
                        </TabPane>
                    </TabContent>

                </Container>
            </div>
        );
    }
}

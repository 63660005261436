import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../../infrastructure/SelectField";
import { HengyiInvitationClient } from '../../infrastructure/HengyiClient/Invitations';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HeroImage } from '../../layout/HeroImage';

export class InvitationsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { apartmentOptions: [], init: false, loading: false, authenticated: true, next: false };

    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: "",
                surname: "",
                email: "",
                mobile: "",
                trust: "",
                apartments: "",
                apartmentLivingIn: "",
                userType: "resident",
                apartmentId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.userType)
                    setFieldError("userType", "Please specify the type of user");

                if (!fields.apartmentId)
                    setFieldError("apartmentId", "Please select an apartment");

                var apartmentsLivingIn;
                var apartmentsOwned;

                if (fields.userType == "resident")
                    apartmentsLivingIn = fields.apartmentId.value;

                if (fields.userType == "ownerOccupier") {
                    apartmentsLivingIn = fields.apartmentId.value;
                    apartmentsOwned = [fields.apartmentId.value]
                }

                if (fields.userType == "owner") {
                    apartmentsOwned = [fields.apartmentId.value]
                }

                var response = await HengyiInvitationClient.Create(
                    fields.name,
                    fields.surname,
                    fields.email,
                    fields.mobile,
                    null,
                    apartmentsOwned ? apartmentsOwned : [],
                    apartmentsLivingIn ? apartmentsLivingIn : null
                );

                if (!response.successful) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange }) => (
            <Form>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div></Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="surname">Surname</label>
                            <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div></Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                            <Field name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                        </div>
                    </Col>
                </Row>

                <Row>
                        <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartmentId"
                                    name="apartmentId"
                                    label="Space"
                                    placeholder="Select space"
                                    options={this.state.apartmentOptions}
                                    value={values.apartmentId}
                                    isMulti={false}
                                    onChange={(key, item) => {
                                        setFieldValue(key, item);
                                        setFieldValue("userType", "resident");
                                    }}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartmentId}
                                    error={errors.apartmentId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>


                        <Col>

                            <div className="form-group">
                                <label htmlFor="userType">User Type</label>
                                <select name="userType"
                                    value={values.userType}
                                    onChange={handleChange}
                                    onBlur={setFieldTouched}
                                    className={'form-control' + (errors.userType && touched.userType ? ' is-invalid' : '')}>
                                    <option value="resident" label="Resident" />
                                    {values.apartmentId && values.apartmentId.relationship != "resident" && <option value="owner" label="Owner" />}
                                    {values.apartmentId && values.apartmentId.relationship != "resident" && <option value="ownerOccupier" label="Owner occupier" />}
                                </select>
                                <ErrorMessage name="userType" component="div" className="invalid-feedback" />
                            </div>

                        </Col>
                </Row>
                <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" style={{ padding: "6px"}} disabled={isSubmitting}>
                        {!isSubmitting && "SEND INVITE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to="/profile/occupants" />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Invitations" subTitle="Send an invitation for a user to join" />

                    <Container style={{ "paddingTop": "20px" }}>
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invite
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Send a new invite for a resident or owner</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right", padding: "6px" }} to="/profile/occupants" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.init && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                        </div>


                    </Container>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetCurrentUser();

            this.setState({ init: true });

            this.setState({
                apartmentOptions: response.apartments.map(function (apartment) { return { value: apartment.apartment.id, label: apartment.apartment.name, relationship: apartment.relationship }; }), loading: false
            });
        }
    }
}

import React, { Component } from 'react';
import { Button, Badge, Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiCreditCardClient, CreditCardQueryParams } from '../../infrastructure/HengyiClient/CreditCards';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { HengyiChargeClient, ChargeQueryParams } from '../../infrastructure/HengyiClient/Charges';

export class ChargesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var response = (await HengyiChargeClient.List(new ChargeQueryParams()
            .WithUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {


        const {
            onInvoiceClick
        } = this.props;


        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Status</th>
                            <th style={{ borderTop: "none" }}>Description</th>
                            <th style={{ borderTop: "none" }}>Amount</th>
                            <th style={{ borderTop: "none" }}>Invoice</th>
                            <th style={{ borderTop: "none" }}>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(item =>
                            <tr key={item.id}>
                                <td>
                                    {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                    {item.status === "invoiced" && <Badge color="success">Invoiced</Badge>}
                                    {item.status === "voided" && <Badge color="secondary">Voided</Badge>}
                                </td>
                                <td>
                                    {item.description && item.description}
                                    {!item.description && <i>Not specified</i>}
                                </td>
                                <td>
                                    <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                </td>
                                <td>
                                    {!item.invoice && <i>Not invoiced yet</i>}

                                    {item.invoice &&
                                        <>
                                            {item.invoice.status === "draft" && <Badge color="primary">Draft</Badge>}
                                            {item.invoice.status === "open" && <Badge color="primary">Open</Badge>}
                                            {item.invoice.status === "paid" && <Badge color="success">Paid</Badge>}
                                            {item.invoice.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                            {item.invoice.status === "void" && <Badge color="secondary">Voided</Badge>}
                                            {item.invoice.status === "deleted" && <Badge color="secondary">Deleted</Badge>}

                                        <a href="#" onClick={(e) => { onInvoiceClick(); e.preventDefault(); } }>
                                        {!item.invoice.invoiceNumber && <i> &nbsp;No invoice number</i>} &nbsp;
                                                {item.invoice.invoiceNumber && item.invoice.invoiceNumber}
                                            </a>
                                        </>
                                    }

                                </td>
                                <td>
                                    <Moment utc local format="ddd, DD/MM h:mma" date={item.created} />
                                </td>
                            </tr>
                        )}
                        {this.state.data.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No charges to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <Card style={{ marginTop: "40px" }}>
                <CardHeader>
                    <h2>Account transactions
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                    </h2>
                </CardHeader>
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }
}

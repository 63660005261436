import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { userContext } from '../../../userContext';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiNoticeClient } from '../../infrastructure/HengyiClient/Notices';

export class NoticeDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { authenticated: true };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });

        this.setState({
            data: (await HengyiNoticeClient.Get(this.props.match.params.id)).data,
            loading: false
        });
    }

    render() {

        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (<div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg" } padding="100px" coverTitle="Notice details" subTitle="Details about the notice" />

                <Container style={{ "paddingTop": "20px" }}>

                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Notice details
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ padding: "6px", float: "right" }} to="/profile/notices" className="btn mr-2 btn-outline-dark mt-2 mb-2">Back</Link>
                                        {this.state.data.status == "pending" && <Link style={{ padding: "6px", float: "right" }} to={"/profile/notices/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    {this.state.data && <>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Title:</td>
                                                    <td>
                                                        {this.state.data.title && this.state.data.title}
                                                        {!this.state.data.title && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Type:</td>
                                                    <td>
                                                        {this.state.data.type === "info" && <Badge color="info">Info</Badge>}
                                                        {this.state.data.type === "warning" && <Badge color="warning">Warning</Badge>}
                                                        {this.state.data.type === "alert" && <Badge color="danger">Alert</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td>
                                                        {this.state.data.status === "pending" && <Badge color="primary">Pending</Badge>}
                                                        {this.state.data.status === "approved" && <Badge color="success">Approved</Badge>}
                                                        {this.state.data.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Expiry:</td>
                                                    <td>
                                                        {this.state.data.expiry && <Moment utc local format="ddd DD MMM YY, yyyy">{this.state.data.expiry}</Moment>}
                                                        {!this.state.data.expiry && <i>No expiry</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Created:</td>
                                                    <td>
                                                        <Moment utc local format="ddd, DD/MM h:mma" date={this.state.data.created} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                        <p>{this.state.data.description}</p></>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}
                </Container>
            </div>);
        }
    }
}

NoticeDetails.contextType = userContext;
import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, TicketTypeQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../infrastructure/Helper';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiFloorClient, FloorQueryParams } from '../infrastructure/HengyiClient/Floors';
import { HengyiVehicleClient } from '../infrastructure/HengyiClient/Vehicles';
import { VehicleQueryParams } from '../infrastructure/HengyiClient/Vehicles';
import { HengyiEVChargingSessionClient } from '../infrastructure/HengyiClient/EVChargingSessions';
import { GetAssetDomain } from '../infrastructure/Helper';

function required(value) {
    if (!value) {
        return 'This is a required field';
    }
}

export class EVChargingSessionsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = (await HengyiApi.GetTicketTypes(new TicketTypeQueryParams(0, 50)));

            var currentUser = await HengyiApi.GetCurrentUser();
            var vehicles = (await HengyiVehicleClient.List(new VehicleQueryParams()
                .WithUser(currentUser.id)
                .WithFuelType("ev")
                .WithFuelType("hybrid")
                .Sort("licensePlate")
                .Paginate(0, 50)));

            this.setState({
                init: true,
                vehicleOptions: vehicles.data.data.map(function (item) { return { value: item.id, label: item.make + " " + item.model + " - " + item.licensePlate }; }),
                loading: false
            });
        }
    }

    renderForm() {

        return (<Formik
            initialValues={{
                vehicleId: "",
                message: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.vehicleId) {
                    setFieldError("vehicleId", 'This is a required field');
                    return;
                }

                var response = await HengyiEVChargingSessionClient.Request(fields.vehicleId.value, fields.message);

                if (!response.successful) {
                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {
                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }
                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <div className="form-group">
                    <SelectField
                        id="vehicleId"
                        name="vehicleId"
                        label="Vehicle"
                        placeholder="Select a vehicle"
                        options={this.state.vehicleOptions}
                        value={values.vehicleId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.vehicleId}
                        error={errors.vehicleId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                        <ErrorMessage name="vehicleId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                        <Field name="notes" type="text" component="textarea" className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')} />
                        <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                </div>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "REQUEST CHARGE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/ev-charging-sessions" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Requests" subTitle="Lodge a new request for us to look at" />


                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <h2>Request EV Charge
                                    <Link to="/ev-charging-sessions" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                                </h2>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}

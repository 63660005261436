import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { HengyiApartmentClient } from '../../infrastructure/HengyiClient/Apartments';
import { ApartmentQueryParams } from '../../infrastructure/HengyiClient/Apartments';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiPetClient } from '../../infrastructure/HengyiClient/Pets';
import { GetAPIDomain } from '../../infrastructure/Helper';
import { SelectField } from '../../infrastructure/SelectField';
import DatePicker from 'react-datepicker';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class PetsNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async fetchData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var currentUser = await HengyiApi.GetCurrentUser();
            var apartments = (await HengyiApartmentClient.List(new ApartmentQueryParams().WithUser(currentUser.id).Paginate(0, 10000))).data.data;

            this.setState({
                init: true,
                apartmentOptions: apartments.map(function (item) { return { value: item.id, label: item.name }; }),
                userOptions: [],
                apartments: apartments,
                loading: false,
                userId: currentUser.id
            });
        }
    }

    renderForm() {
        return (
            <Formik
                initialValues={{
                    name: "",
                    apartmentId: "",
                    breed: "",
                    description: "",
                    type: "dog"
                }}

                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    var apartmentId = null;
                    if (this.state.apartmentOptions.length == 1) {
                        apartmentId = this.state.apartmentOptions[0].value;
                    } else if (fields.apartmentId) {
                        apartmentId = fields.apartmentId.value;
                    }

                    if (!apartmentId) {
                        setFieldError("apartmentId", "Please choose the space where this pet resides");
                        return;
                    }

                    var imageId = null;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var response = await HengyiPetClient.Create(
                        fields.name,
                        "dog",
                        fields.type,
                        fields.breed,
                        fields.description,
                        this.state.userId,
                        imageId,
                        apartmentId);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true, id: response.data.id });
                    }

                }}>
                {({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                    <Form>
                        <Row>
                            <Col>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Name*</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    {this.state.apartmentOptions.length != 1 && <Col>
                                        <SelectField
                                            id="apartmentId"
                                            name="apartmentId"
                                            label="Space*"
                                            placeholder="Select space"
                                            options={this.state.apartmentOptions}
                                            value={values.apartmentId}
                                            isMulti={false}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.apartmentId}
                                            error={errors.apartmentId}
                                            isClearable={true}
                                            backspaceRemovesValue={true}
                                        /></Col>}
                                </Row>

                                <Row>

                                    <Col>

                                        <div className="form-group">
                                            <label htmlFor="type">Pet type</label>
                                            <select name="type"
                                                value={values.type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                                <option value="dog" label="Dog" />
                                                <option value="cat" label="Cat" />
                                                <option value="bird" label="Bird" />
                                                <option value="other" label="Other" />
                                            </select>
                                            <ErrorMessage name="fuelType" component="div" className="invalid-feedback" />
                                        </div>

                                    </Col>

                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="breed">Breed</label>
                                            <Field name="breed" type="text" className={'form-control' + (errors.breed && touched.breed ? ' is-invalid' : '')} />
                                            <ErrorMessage name="breed" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="pt-3">
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="description">Description</label>
                                            <Field name="description" type="text" component="textarea" className="form-control" />
                                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                            <Col md="3" className="text-center">
                                <div className="form-group">
                                    <h6 className="text-left">Profile image</h6>

                                    {this.state.file && <div>
                                        <img className="rounded-circle" alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                    </div>}

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                        this.setState({
                                            file: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("file", event.currentTarget.files[0]);
                                    }} />

                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/profile/pets"} />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Register pet" subTitle="Register your pet to allow it access to the building" />

                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> New pet
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Register your pet</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right" }} to="/profile/pets" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }
}

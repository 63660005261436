import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import { HengyiVehicleClient } from '../../infrastructure/HengyiClient/Vehicles';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class VehiclesEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [], init: false, loading: false, authenticated: true, next: false, apartmentOptions: []
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async fetchData() {
        if (!this.state.init) {

            this.setState({ loading: true });
            var response = await HengyiVehicleClient.Get(this.props.match.params.id);

            if (response.data.wofExpiry) {
                this.setState({ wofExpiry: new Date(response.data.wofExpiry) });
            }

            if (response.data.registrationExpiry) {
                this.setState({ registrationExpiry: new Date(response.data.registrationExpiry) });
            }

            this.setState({
                init: true,
                vehicle: response.data
            });

            this.setState({ init: true, loading: false });

        }
    }


    renderForm() {

        return (
            <Formik
                initialValues={{
                    fuelType: this.state.vehicle.fuelType,
                    registrationExpiry: this.state.registrationExpiry,
                    wofExpiry: this.state.wofExpiry
                }}

                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    const response = await HengyiVehicleClient.Update(
                        this.props.match.params.id,
                        this.state.vehicle.colour,
                        fields.fuelType,
                        this.state.vehicle.licensePlate,
                        this.state.vehicle.make,
                        this.state.vehicle.model,
                        fields.wofExpiry,
                        this.state.vehicle.user ? this.state.vehicle.user.id : null,
                        this.state.vehicle.apartment ? this.state.vehicle.apartment.id : null
                        , fields.registrationExpiry);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}>
                {({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                    <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="fuelType">Fuel Type</label>
                                    <select name="fuelType"
                                        value={values.fuelType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.fuelType && touched.fuelType ? ' is-invalid' : '')}>
                                        <option value="ev" label="Electric" />
                                        <option value="petrol" label="Petrol" />
                                        <option value="diesel" label="Diesel" />
                                        <option value="hybrid" label="Hybrid" />
                                    </select>
                                    <ErrorMessage name="fuelType" component="div" className="invalid-feedback" />
                                </div>
                            </Col>


                            <Col>
                                <div className="form-group">
                                    <label htmlFor="wofExpiry">WoF Expiry</label>

                                    <DatePicker id="wofExpiry"
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.wofExpiry}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            setFieldValue("wofExpiry", v);
                                        }} />
                                </div>
                            </Col>

                            <Col>
                                <div className="form-group">
                                    <label htmlFor="registrationExpiry">Registration Expiry</label>

                                    <DatePicker id="registrationExpiry"
                                        autoComplete="off"
                                        className="form-control"
                                        selected={values.registrationExpiry}
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(v, f) => {
                                            setFieldValue("registrationExpiry", v);
                                        }} />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/profile/vehicles"} />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Update vehicle" subTitle="Update details about your vehicle" />

                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Update vehicle
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Update vehicle details</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right" }} to="/profile/vehicles" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }

}

import React, { Component } from 'react';
import { Badge, Spinner, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { ChargeQueryParams } from '../../infrastructure/HengyiClient/Charges';
import { HengyiUserClient } from '../../infrastructure/HengyiClient/Users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { HengyiApartmentClient } from '../../infrastructure/HengyiClient/Apartments';

export class OccupantsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };

        this.removeUserFromApartment = this.removeUserFromApartment.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }


    async removeUserFromApartment(userId, apartmentId) {
        await HengyiApartmentClient.RemoveUser(apartmentId, userId);
        await this.fetchData();
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();

        var apartmentsOwned = currentUser.apartments.filter(a => a.relationship == "ownerOccupier" || a.relationship == "owner")
            .map(a => a.apartment.id);
        var apartmentsResiding = currentUser.apartments.filter(a => a.relationship == "resident")
            .map(a => a.apartment.id);

        this.setState({
            apartmentsOwned: apartmentsOwned,
            apartmentsResiding: apartmentsResiding
        })

        var response = (await HengyiUserClient.List(new ChargeQueryParams()
            .WithUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Name</th>
                            <th style={{ borderTop: "none" }}>Email</th>
                            <th style={{ borderTop: "none" }}>Mobile</th>
                            <th style={{ borderTop: "none" }}>Apartment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(user =>
                            <tr key={user.id}>
                                <td>{user.name} {user.surname}</td>
                                <td>{user.email}</td>
                                <td>{user.mobile}</td>
                                <td>{user.apartments && user.apartments.length > 0 && user.apartments.map((apartment, index) => {
                                    return (this.state.apartmentsResiding.includes(apartment.apartment.id) || this.state.apartmentsOwned.includes(apartment.apartment.id)) && <div key={user.id + "-" + apartment.apartment.id}>
                                    <Row>
                                        <Col> {apartment.apartment.name}</Col>
                                        <Col>
                                            {apartment.relationship === "resident" && <Badge color="primary">Resident</Badge>}
                                            {apartment.relationship === "owner" && <Badge color="primary">Owner</Badge>}
                                            {apartment.relationship === "ownerOccupier" && <Badge color="primary">Owner occupier</Badge>}
                                        </Col>
                                        <Col>
                                            {((apartment.relationship == "resident" && this.state.apartmentsResiding.includes(apartment.apartment.id)) || this.state.apartmentsOwned.includes(apartment.apartment.id)) &&
                                                <button onClick={() => this.removeUserFromApartment(user.id, apartment.apartment.id)} className="btn btn-outline-dark btn-default" style={{ minWidth: "0", padding: "2px 10px 2px 10px", margin: "3px" }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                                        </Col>
                                    </Row>
                                </div>
                                }
                                )}</td>
                            </tr>
                        )}
                        {this.state.data.length === 0 && <tr>
                            <td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No other occupants to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <Card style={{ marginTop: "40px" }}>
                <CardHeader>
                    <h2>Occupants
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                    </h2>
                </CardHeader>
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }
}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class PartnerOfferQueryParams extends HengyiListQueryParams {

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithCategory(value: string) {
        this.WithParam("categoryId", value);
        return this;
    }

    WithNewOffers() {
        this.WithParam("newOffers", true);
        return this;
    }

    WithExpiredOffers(value: Boolean) {
        this.WithParam("includeExpiredOffers", value);
        return this;
    }
}

//******************************
// Partner offer categories
//******************************
export class PartnerOfferClient {

    static async List(params: PartnerOfferQueryParams) {

        var query = (await GetAPIDomain()) + "/partner-offers" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async TrackViewEvent(id: string) {

        var request: any = {
            eventType: "offerViewed"
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id + "/event", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async TrackClickEvent(id: string) {

        var request: any = {
            eventType: "linkClicked"
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id + "/event", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Create(name: string, description: string, expiry: string, weight: number, categoryId:string, webLink?: string, imageId?: string) {

        var request: any = {
            name: name,
            description: description,
            categoryId: categoryId,
            weight: weight,
            webLink: webLink,
            imageId: imageId
        }

        if (expiry)
            request.expiry = expiry;

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async GetDownloadLink(id: string) {

        if (id === "home")
            return null;

        var response = await fetch((await GetAPIDomain()) + "/partner-offers/" + id + "/download", {
            headers: await GetAPIHeaders()
        });

        if (!response.ok) {
            return;
        }

        return (await response.json());
    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id + "/approve", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Reject(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id + "/reject", {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/partner-offers/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    static async Update(id: string,
        name: string, description: string, expiry: string, weight: number, categoryId: string, webLink?: string, imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offers/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "description",
                "value": description
            }, {
                "op": "replace",
                "path": "expiry",
                "value": expiry
            }, {
                "op": "replace",
                "path": "weight",
                "value": weight
            }, {
                "op": "replace",
                "path": "categoryId",
                "value": categoryId
            }, {
                "op": "replace",
                "path": "webLink",
                "value": webLink
            }, {
                "op": "replace",
                "path": "imageId",
                "value": imageId
            }])
        }));
    }

}
import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody, Row, Col, Badge, Button } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SelectField } from "../infrastructure/SelectField";
import { HengyiEVChargingSessionClient } from '../infrastructure/HengyiClient/EVChargingSessions';
import Moment from 'react-moment';
import { HengyiParkedVehicleClient } from '../infrastructure/HengyiClient/ParkedVehicles';
import { HengyiValetRequestClient, ValetRequestQueryParams } from '../infrastructure/HengyiClient/ValetRequests';
import { GetAssetDomain } from '../infrastructure/Helper';

export class ParkedVehiclesDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: false, authenticated: true, next: false, showForm: "none" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    async cancelRequest(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            await HengyiValetRequestClient.Cancel(id, "");

            this.setState({
                loading: false
            }, () => this.populateData());
        }
    }

    async requestCar() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            await HengyiValetRequestClient.RequestCar(this.props.match.params.id)

            this.setState({
                loading: false
            },() => this.populateData());
        }
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var vehicle = await HengyiParkedVehicleClient.Get(this.props.match.params.id);

            var valetRequests = await HengyiValetRequestClient.List(new ValetRequestQueryParams()
                .WithParkedVehicle(this.props.match.params.id)
                .Sort("created").Descending());

            this.setState({
                init: true,
                data: vehicle.data,
                loading: false,
                valetRequests: valetRequests.data.data
            });
        }
    }

    renderForm() {

        return (<Formik
            initialValues={{
                vehicleId: "",
                message: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.vehicleId) {
                    setFieldError("vehicleId", 'This is a required field');
                    return;
                }

                var response = await HengyiEVChargingSessionClient.Request(fields.vehicleId.value, fields.message);

                if (!response.successful) {
                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {
                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }
                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
            <Form>

                <div className="form-group">
                    <SelectField
                        id="vehicleId"
                        name="vehicleId"
                        label="Vehicle"
                        placeholder="Select a vehicle"
                        options={this.state.vehicleOptions}
                        value={values.vehicleId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.vehicleId}
                        error={errors.vehicleId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                        <ErrorMessage name="vehicleId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                        <Field name="notes" type="text" component="textarea" className={'form-control' + (errors.notes && touched.notes ? ' is-invalid' : '')} />
                        <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                </div>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "REQUEST CHARGE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/ev-charging-sessions" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Requests" subTitle="Lodge a new request for us to look at" />

                    <Container style={{ "paddingTop": "20px" }}>

                        {this.state.showForm === "request-item" && <Card>
                            <CardBody>
                                <Formik
                                    initialValues={{
                                        message: ""
                                    }}
                                    onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                        var response = await HengyiValetRequestClient.RequestItem(this.props.match.params.id, fields.message);

                                        if (!response.successful) {
                                            response.data.validationErrors.map(error => {
                                                setFieldError(error.key, error.message);
                                                return {
                                                    [error.key]: error.message
                                                };
                                            });

                                        } else {
                                            this.setState({ showForm: "none" });
                                            setFieldValue("message", "");
                                            await this.populateData();
                                        }
                                    }}
                                >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="message">Item to retreive</label>
                                            <Field name="message" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="mt-4">
                                            <button style={{ padding: "6px", float: "right" }} onClick={() => { this.setState({ showForm: "none" }); }} className="btn btn-outline-dark mt-2">Close</button>
                                            <button style={{ padding: "6px", float: "right" }} type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "Request"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>
                                    </Form>
                                )}</Formik>
                            </CardBody>
                        </Card>}

                        <Card>
                            <CardHeader>
                                <h2>Parked Vehicle
                                    {this.state.loading && <Spinner animation="border" />}
                                    <Link to="/valet" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Back</Link>
                                    {this.state.data && this.state.data.status === "parked" && <button style={{ padding: "6px", float: "right" }} onClick={() => { this.requestCar(); }} className="btn btn-outline-dark mr-2 mt-2">Request Car</button>}
                                    {this.state.data && this.state.data.status === "parked" && <button style={{ padding: "6px", float: "right" }} onClick={() => { this.setState({ showForm: "request-item" }); }} className="btn btn-outline-dark mr-2 mt-2">Request Item</button>}
                                </h2>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="8">
                                        {this.state.valetRequests && <table className='table' aria-labelledby="tabelLabel">
                                            <thead>
                                                <tr>
                                                    <th style={{ borderTop: "none" }}><h6>Type</h6></th>
                                                    <th style={{ borderTop: "none" }}><h6>Status</h6></th>
                                                    <th style={{ borderTop: "none" }}><h6>Message</h6></th>
                                                    <th style={{ borderTop: "none" }}><h6>Created</h6></th>
                                                    <th style={{ borderTop: "none" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.valetRequests.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.type === "requestDriver" && <Badge color="primary">Driver</Badge>}
                                                            {item.type === "requestCar" && <Badge color="success">Request Vehicle</Badge>}
                                                            {item.type === "requestItem" && <Badge color="info">Request Item</Badge>}
                                                        </td>
                                                        <td>
                                                            {item.status === "open" && <Badge color="primary">Open</Badge>}
                                                            {item.status === "completed" && <Badge color="success">Completed</Badge>}
                                                            {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                        </td>
                                                        <td>
                                                            {item.message && item.message}
                                                            {!item.message && <i>Not specified</i>}
                                                        </td>
                                                        <td><Moment utc local format="ddd DD MMM YY, h:mm a">{item.created}</Moment></td>
                                                        <td>
                                                            {item.status === "open" && <Button onClick={() => { this.cancelRequest(item.id); }} key={item.id + "-complete"} style={{ float: "right", padding: "6px" }} className="btn btn-outline-dark bg-white-btn">Cancel</Button>}
                                                        </td>
                                                    </tr>
                                                )}

                                                {this.state.valetRequests.length === 0 && <tr><td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No requests to display</i></h4></td></tr>}
                                            </tbody>
                                        </table>}
                                    </Col>
                                    <Col>
                                        <h6>Details</h6>
                                        <hr />

                                        {this.state.data && <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Ticket number:</td>
                                                    <td>
                                                        {this.state.data.ticketNumber && this.state.data.ticketNumber}
                                                        {!this.state.data.ticketNumber && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Parked:</td>
                                                    <td>
                                                        {this.state.data.parked && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.parked}</Moment>}
                                                        {!this.state.data.parked && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Picked up:</td>
                                                    <td>
                                                        {this.state.data.pickedUp && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.pickedUp}</Moment>}
                                                        {!this.state.data.pickedUp && <i>Not picked up</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Status:</td>
                                                    <td>
                                                        {this.state.data.status === "parked" && <Badge color="primary">Parked</Badge>}
                                                        {this.state.data.status === "readyForPickup" && <Badge color="info">Ready for collection</Badge>}
                                                        {this.state.data.status === "collected" && <Badge color="success">Collected</Badge>}
                                                        {this.state.data.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Vehicle:</td>
                                                    <td>
                                                        {this.state.data.vehicle && <span>{this.state.data.vehicle.licensePlate} ({this.state.data.vehicle.colour} {this.state.data.vehicle.make} {this.state.data.vehicle.model})</span>}
                                                        {!this.state.data.vehicle && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>}

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}

import React, { Component } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Col, Row } from 'reactstrap';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from './infrastructure/fetchHengy';
import { GetAssetDomain } from './infrastructure/Helper';

export class Documents extends Component {

    constructor(props) {
        super(props);

        this.state = { documents: "", partner: "", files: "", loading: false, parent: null, requestModal: false, successModal: false, documentToRequest: "", authenticated: true };

        this.closeModal = this.closeModal.bind(this);
        this.requestInfo = this.requestInfo.bind(this);
        this.requestInfoConfirmed = this.requestInfoConfirmed.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(nextProps.match.params.parent);
        }
    }

    async componentDidMount() {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(this.props.match.params.parent);
        }

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    async fetchData(parentId) {

        this.setState({
            loading: true
        });

        var response = await HengyiApi.GetDocument(parentId)
        var currentUser = await HengyiApi.GetCurrentUser();

        this.setState({
            currentUser: currentUser
        });

        if (!response.authenticated) {
            this.setState({
                loading: false,
                authenticated: false
            });
        } else {
            this.setState({
                loading: false,
                documents: await HengyiApi.GetDocuments(parentId),
                files: await HengyiApi.GetFiles(parentId),
                parent: response.data
            });
        }
    }

    async downloadFile(id, name) {

        var data = await HengyiApi.GetDocumentDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        this.setState({ loading: true });
        this.fetchData(this.props.match.params.parent);
    }


    async deleteDocument(id) {

        await HengyiApi.DeleteDocument(id);

        await this.fetchData(this.props.match.params.parent);
    }

    async viewFile(id, name) {
        var data = await HengyiApi.GetDocumentDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.dispatchEvent(new MouseEvent('click'));

        this.setState({ loading: true });
        this.fetchData(this.props.match.params.parent);
    }

    async requestInfoConfirmed() {
        await HengyiApi.RequestDocumentInformation(this.state.documentToRequest);
        this.setState({ requestModal: false, successModel: true });
    }

    async requestInfo(id, partner) {
        this.setState({ requestModal: true, documentToRequest: id, partner: partner });
    }

    async closeModal() {
        this.setState({ requestModal: false });
        this.setState({ successModel: false });
    }

    renderConfirmModal() {
        return (
            <div>
                <Modal isOpen={this.state.requestModal} toggle={this.closeModal}>
                    <ModalHeader toggle={this.closeModal}>Confirm request</ModalHeader>
                    <ModalBody>
                        We will share your email and mobile number with {this.state.partner}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.requestInfoConfirmed}>Send request</Button>{' '}
                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderSuccessModal() {
        return (
            <div>
                <Modal isOpen={this.state.successModal} toggle={this.closeModal}>
                    <ModalHeader toggle={this.closeModal}>Request sent</ModalHeader>
                    <ModalBody>
                        {this.state.partner} will be in touch soon to give you more information on their services.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModal}>Okay</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    getDocumentImageUrl(document, index) {
        if (!document.imageId) {
            return "/img/folderBg/" + index + ".jpg";
        } else {
            return this.state.assetUrl + "/image/" + document.imageId + ".jpg";
        }
    }

    renderDocumentContent() {

        const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

        return (<div>

            {array_chunks(this.state.documents, 3).map((chunk, index) =>

                <div key={index} className="row custom-gutter">

                    {chunk.map((document, innerIndex) =>
                        <div key={index + "-" + innerIndex} className="col-md-4">
                            {document.type === "directory" &&
                                <Link to={"/documents/" + document.id} >

                                    <div style={{ "minHeight": "150px", "backgroundPosition": "bottom", "backgroundSize": "cover", "backgroundImage": "url('" + this.getDocumentImageUrl(document, 3 * index + innerIndex) + "')" }} className="card card-outline-secondary mb-3 text-center">

                                        {document.numberOfUnreadFiles > 0 && <sup style={{ textAlign: "right", fontSize: "1.6em", top: "-0.2em", right: "-0.2em" }}><span style={{ backgroundColor: "rgb(25, 24, 24)", color: "rgb(253, 200, 175)" }} className="badge badge-dark">{document.numberOfUnreadFiles}</span></sup>}

                                        <div style={{ textAlign: "left", padding: "10px", "bottom": "0px", "position": "absolute" }}>
                                            <h4 style={{ color: "white" }}>{document.name}</h4>
                                        </div>
                                    </div>
                                </Link>
                            }
                        </div>)}

                </div>

            )}

        </div>)
    }

    renderFileContent() {

        return (<table className="table table-borderless" style={{ "marginTop": "15px" }}>
            <tbody>
                {this.state.files.map(file =>

                    <tr key={file.id + "-file"} >
                        <td>
                            <h5>
                                <img src={file.thumbnailImage} alt="File thumbnail" style={{ position: "relative", top: "-3px", height: "35px", marginRight: "15px" }} />
                                {file.name}
                                {!file.seen && <sup style={{ right: "-0.2em" }}><span className="badge badge-primary">new</span></sup>}
                                {file.visibility == "restricted" && <sup style={{ right: "-0.2em" }}>&nbsp;<span className="badge badge-primary">Restricted</span></sup>}
                            </h5>
                            {file.visibility == "restricted" && <p>{file.audiences.map(a => {
                                if (a.type == "all") {
                                    return (<span className="badge badge-success mr-2">Everyone {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "residents" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                } else if (a.type == "floor") {
                                    return (<span className="badge badge-success mr-2">Floor {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "resident" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                } else if (a.type == "apartment") {
                                    return (<span className="badge badge-success mr-2">{a.apartment.name} {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "resident" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                } else if (a.type == "building") {
                                    return (<span className="badge badge-success mr-2">{a.building.name} {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "resident" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                } else if (a.type == "user") {
                                    return (<span className="badge badge-success mr-2">{a.user.name} {a.user.surname} {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "resident" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                } else if (a.type == "apartmentType") {
                                    return (<span className="badge badge-success mr-2">{a.apartmentType.name} apartment {a.userType && <span> - {a.userType == "owner" ? "Owners" : a.userType == "resident" ? "Residents" : "Owner occupiers"}</span>}</span>)
                                }
                            })}</p>}
                        </td>
                        <td style={{
                            textAlign: "right"
                        }}>
                            {file.createdBy && file.createdBy.id == this.state.currentUser.id && <button onClick={() => this.deleteDocument(file.id)} className="btn btn-outline-dark btn-default" style={{ minWidth: "0", margin: "3px" }}><FontAwesomeIcon icon={faTrashAlt} /></button>}
                            {file.canRequestInfo && <button onClick={() => { this.requestInfo(file.id, file.partnerName); return false; }} className="btn btn-outline-dark" style={{ marginRight: "15px", right: "-15px", position: "relative" }}>Request Info</button>}
                            <button onClick={() => { this.downloadFile(file.id, file.name + "." + file.fileType); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>)

    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (

                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Documents" subTitle="All your documents" />
                    <Container style={{ "paddingTop": "20px" }}>

                        {!this.state.parent && <Row>
                            <Col>
                                <h2>
                                    Your documents {this.state.loading && <Spinner animation="border" />}
                                </h2>
                            </Col>
                        </Row>
                        }

                        {this.state.parent && <Row>
                            <Col>
                                <h2>
                                    {this.state.parent.name}
                                    {this.state.loading && <Spinner animation="border" />}
                                    <Link to={"/documents/" + (this.state.parent.parentId ? this.state.parent.parentId : "home")} className="btn btn-outline-dark mt-2 mr-2" style={{ padding: "6px", float: "right" }}> Back </Link>
                                    {this.state.parent.name == "My Documents" && <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to={"/my-documents/" + this.state.parent.id + "/upload"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Upload</Link>}
                                </h2>
                            </Col>
                        </Row>
                        }
                        <hr />

                        {this.state.documents.length !== 0 && this.renderDocumentContent()}
                        {this.state.documents.length !== 0 && this.state.files.length !== 0 && <hr />}
                        {this.state.files.length !== 0 && this.renderFileContent()}
                        {this.state.documents.length === 0 && this.state.files.length === 0 && <h3><i>No documents uploaded</i></h3>}
                    </Container>

                    {this.renderConfirmModal()}
                    {this.renderSuccessModal()}
                </div>
            );
        }
    }

}

Documents.contextType = userContext;
import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ChargeQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithShowVoided(value: Boolean) {
        this.WithParam("showVoided", value);
        return this;
    }
}

//******************************
// CHARGES
//******************************
export class HengyiChargeClient {

    static async List(params: ChargeQueryParams) {

        var query = (await GetAPIDomain()) + "/charges" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/charges/" + id, {
            headers: GetAPIHeaders()
        }));

    }

}
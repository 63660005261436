import React, { Component } from 'react';
import { Container, Spinner, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, Card, CardBody } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, FacilityQueryParams, ScheduleQueryParams, BookingTypeQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage, ErrorSummary } from 'formik';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { GetAssetDomain } from '../infrastructure/Helper';
import { HengyiSchedulesClient, ListSchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { HengyiBookingTypesClient, ListBookingTypeQueryParams } from '../infrastructure/HengyiClient/BookingTypes';
import { HengyiBookingSlotsClient, ListBookingSlotsQueryParams } from '../infrastructure/HengyiClient/BookingSlots';

function required(value) {
    if (!value)
        return 'This is a required field';
}

export class BookingNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            init: true,
            loading: false,
            next: false,

            modal: false,
            errorMessage: "",
            weekdays: [64, 1, 2, 4, 8, 16, 32],

            bookingSlotsOptions: [],

            bookings: [],
            errorMessages: [],

            propsInit: false,

            tz: moment.tz.guess()
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("facilityId")) {

            this.setState({
                initialFacilityId: (await HengyiApi.GetFacilityById(urlParams.get("facilityId"))).data,
                propsInit: true
            });
        } else {
            this.setState({
                propsInit: true
            });
        }
    }

    async showMessage(msg) {
        this.setState({ modal: true, errorMessage: msg })
    }

    async clearMessage() {
        this.setState({ modal: false, errorMessage: "" })
    }

    async populateStepFourBookingSchedule(scheduleId, bookingDate) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiBookingSlotsClient.List(new ListBookingSlotsQueryParams()
                .WithScheduleId(scheduleId)
                .WithBookingDate(bookingDate));

            this.setState({
                loading: false,
                bookingSlotsOptions: response.data
            });
        }
    }

    onBookingChange = (index, duration) => {

        var bookings = this.state.bookings;

        //check if the user can select multiple bookings.
        //check if user want to de-select an item
        if (this.isDeselect(index)) {
            return;
        }

        //validate selection are consecutive eg. 1,2,3 and not 1,2,5
        if (!this.isConsecutiveSelection(index)) {
            this.showMessage("You can only select hours in a consecutive order.");
            return;
        }

        //validate that current selections does not exceed bookingType.maximumBookingLength.
        if (this.exceedsMaximumBookingLength(duration)) {
            this.showMessage("Sorry, you have reached the maximum allowance of " + this.state.bookingType.maximumBookingLength + " minutes for this booking.");
            return;
        }

        bookings.push(index);
        this.setState({ bookings: bookings });

        if (bookings.length == 1 && this.state.bookingType.maximumBookingLength > duration) {
            var count = 1;
            while (duration * count < this.state.bookingType.maximumBookingLength) {
                if (index + count < this.state.bookingSlotsOptions.length) {
                    console.log("added index");
                    this.onBookingChange(index + count, duration);
                }
                count++;
            }
        }
    }

    isDeselect(index) {
        var bookings = this.state.bookings;
        var idx = bookings.findIndex(i => i == index);
        //item not found (is new selection)
        if (idx == -1) {
            return false;
        }

        var length = bookings.length;
        //its first item, no need to remove subsequent items
        if (idx == 0) {
            length = 1;
        }

        bookings.splice(idx, length);
        this.setState({ bookings: bookings });
        return true;
    }

    exceedsMaximumBookingLength(duration) {
        var bookings = this.state.bookings;
        var total = (bookings.length + 1) * duration;

        return total > this.state.bookingType.maximumBookingLength;
    }

    isConsecutiveSelection(index) {
        var bookings = this.state.bookings;
        var length = bookings.length - 1;
        var lastIndex = bookings[length];
        lastIndex++;

        //No elements on array (is the first selection).
        if (isNaN(lastIndex)) {
            return true;
        }

        return index == lastIndex;
    }

    renderForm() {
        return (<Formik
            initialValues={{
                bookingDate: "",
                facilityId: this.state.initialFacilityId
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.bookingDate)
                    setFieldError("bookingDate", "Please select a booking date");

                var user = await HengyiApi.GetCurrentUser();

                var duration = this.state.bookingSlotsOptions[0].duration * this.state.bookings.length;
                var startTime = this.state.bookingSlotsOptions[this.state.bookings[0]].start;

                var time = moment(startTime, "HH:mm");
                var start = moment.tz(fields.bookingDate.toISOString(), "Pacific/Auckland")
                    .startOf('day')
                    .add(time.format("HH"), "hours")
                    .add(time.format("mm"), "minutes");

                var end = moment(start).add(duration, "minutes");

                var response = await HengyiApi.CreateBooking("", "", "", user.id, fields.bookingTypeId.value, fields.scheduleId.id, start.toISOString(), end.toISOString());

                if (!response.successful) {
                    this.showMessage(response.validationErrors.map(item => item.message).join(", "));
                } else {
                    this.setState({ next: true, id: response.data.id });
                }
            }}
        >{({ errors, isSubmitting, values, setFieldValue }) => (
            <Form>

                {this.state.initialFacilityId && <>
                    <h2>
                        Which day do you want to use the {values.facilityId.name}?
                        <Link to="/bookings" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                    </h2>
                    <hr /></>}

                {!this.state.initialFacilityId && <div className="form-group">


                        {this.state.tz != "Pacific/Auckland" &&
                            <div className="alert alert-info">
                                <h6>The booking schedule is for New Zealand</h6>
                                <p className="mt-2 mb-2">Your current time zone is <strong>{this.state.tz}</strong>, a booking for the facility is in local New Zealand time, not your current location.</p>
                            </div>
                        }

                    <h2>
                        What would you like to book today?
                            <Link to="/bookings" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                    </h2>
                    <hr />

                    <FacilitySelector onChange={(name, value) => {
                        setFieldValue(name, value);

                        setFieldValue("bookingDate", "");
                        setFieldValue("scheduleId", "");
                        setFieldValue("bookingTypeId", "");

                        this.setState({
                            bookingSlotsOptions: [],
                            bookings: []
                        });
                        this.clearMessage();
                    }} />


                        {values.facilityId && values.facilityId.description && <Card>
                        <CardBody>{values.facilityId.description}</CardBody>
                    </Card>}

                </div>}

                {values.facilityId && <>

                    <div className="form-group">

                        {!this.state.initialFacilityId && <h2 className="mt-4">Which day do you want to use the {values.facilityId.name}?</h2>}
                        {!this.state.initialFacilityId && <hr />}
                        <DatePicker id="scheduleDate"
                            autoComplete="off"
                            className="form-control"
                            selected={values.bookingDate}
                            filterDate={date => {
                                var today = new Date();
                                var yesterday = moment(today).subtract(1, "day");
                                return moment(date).isAfter(yesterday, "days");
                            }}
                            placeholderText="Select a date"
                            dateFormat='dd/MM/yyyy'
                            onChange={async (date) => {

                                setFieldValue("bookingDate", date);
                                setFieldValue("scheduleId", "");
                                setFieldValue("bookingTypeId", "");

                                this.setState({
                                    bookingSlotsOptions: [],
                                    bookings: []
                                });
                                this.clearMessage();

                            }} />
                    </div>

                    {values.bookingDate &&
                        <ScheduleSelector date={values.bookingDate} facilityId={values.facilityId ? values.facilityId.id : null} onChange={(name, value) => {

                            setFieldValue(name, value);
                            setFieldValue("bookingTypeId", "");
                            this.setState({
                                bookingSlotsOptions: [],
                                bookings: []
                            });
                            this.clearMessage();

                        }} />}

                    {values.bookingDate && values.scheduleId && <BookingTypeSelector scheduleId={values.scheduleId ? values.scheduleId.id : null} onChange={(name, value) => {

                        setFieldValue(name, value);

                        this.setState({
                            bookingSlotsOptions: [],
                            bookings: [],
                            bookingType: value
                        });

                        if (values.scheduleId)
                            this.populateStepFourBookingSchedule(values.scheduleId.id, values.bookingDate);

                        this.clearMessage();
                    }} />}

                </>}

                {this.state.bookingSlotsOptions && this.state.bookingSlotsOptions.length > 0 && <div className="form-group">
                    <h2>Select a booking time</h2>
                    <hr />

                    {this.state.bookingSlotsOptions.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No bookings to display</i></h3>}
                    <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap" }}>
                        {this.state.bookingSlotsOptions.map((item, index) => (
                            <ListGroupItem key={item.start}
                                style={{
                                    flexWrap: "wrap", "width": "120px"
                                }}
                                disabled={!item.available}
                                active={this.state.bookings && this.state.bookings.findIndex(i => i == index) != -1}
                                onClick={() => { this.onBookingChange(index, item.duration); }}>

                                <ListGroupItemHeading style={{ textAlign: "center" }}>{item.start.substring(0, item.start.length - 3)}</ListGroupItemHeading>
                                <small>{item.capacity - item.booked} remaining</small>
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                </div>}

                <Alert color="danger" isOpen={this.state.errorMessages.length > 0}>
                    {this.state.errorMessages.map(error => (
                        <p>{error.message}</p>
                    ))}
                </Alert>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={() => this.clearMessage()}>Information</ModalHeader>
                    <ModalBody>
                        {this.state.errorMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.clearMessage("")}>Ok</Button>
                    </ModalFooter>
                </Modal>

                <div className="form-group">
                    <Collapse isOpen={this.state.bookings.length != 0}>
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "CREATE BOOKING"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </Collapse>
                </div>

            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/bookings" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Bookings" subTitle="Lodge a new booking today" />

                    <Container style={{ "paddingTop": "20px" }}>

                        {this.state.init && this.state.propsInit && this.renderForm()}
                    </Container>
                </div>
            );
        }
    }
}

export class FacilitySelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        }, async () => await this.loadData());
    }

    handleChange(name, value) {

        const {
            onChange
        } = this.props;

        this.setState({
            facility: value
        });

        if (onChange)
            onChange(name ? name : "facilityId", value);
    }

    async loadData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiApi.GetFacilities(new FacilityQueryParams(0, 50, "", "available"));

            this.setState({
                loading: false,
                facilityOptions: response.data.data
            });
        }
    }

    render() {

        const {
            name
        } = this.props;

        return (<ListGroup className="list-group-horizontal">
            {this.state.facilityOptions && this.state.facilityOptions.map((item) => (
                <ListGroupItem key={item.id}
                    active={this.state.facility && item.id === this.state.facility.id}
                    onClick={() => this.handleChange(name, item)}
                    style={{ padding: "0", "minHeight": "150px", "width": "280px", "backgroundPosition": "bottom", "backgroundSize": "cover", "backgroundImage": "url('" + this.state.assetUrl + "/image/" + item.backgroundImageId + ".jpg" + "')" }}
                >
                    <div style={{
                        "color": "white", "backgroundColor": "#121213ba", "textAlign": "left", "padding": "10px", "bottom": "0px", "left": "0px", "right": "0px", "position": "absolute"
                    }}>
                        <ListGroupItemHeading >{item.name}</ListGroupItemHeading>
                    </div>
                </ListGroupItem>
            ))}
        </ListGroup>);
    }
}

export class ScheduleSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        const {
            facilityId,
            date
        } = this.props;

        if (prevProps.facilityId !== facilityId)
            await this.loadData();

        if (prevProps.date !== date)
            await this.loadData();
    }

    handleChange(name, value) {

        const {
            onChange
        } = this.props;

        this.setState({
            schedule: value
        });

        if (onChange)
            onChange(name ? name : "scheduleId", value);
    }

    async loadData() {

        const {
            name,
            facilityId,
            date
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true });

            var result = await HengyiSchedulesClient.List(new ListSchedulesQueryParams()
                .WithFacility(facilityId)
                .WithDate(date).Paginate(0, 1000));

            var scheduleOptions = result.data.data;
            this.setState({
                scheduleOptions: scheduleOptions,
                loading: false
            });

            if (scheduleOptions.length === 1)
                this.handleChange(name, scheduleOptions[0]);

        }
    }

    render() {

        const {
            name
        } = this.props;

        return (<div className="form-group">

            {!this.state.loading && this.state.scheduleOptions && this.state.scheduleOptions.length > 1 &&
                <>

                    <h2>Which schedule?</h2>
                    <hr />

                    <ListGroup>
                        {this.state.scheduleOptions && this.state.scheduleOptions.map((item) => (
                            <ListGroupItem key={item.id}
                                style={{ margin: "0", textAlign: "left" }}
                                active={this.state.schedule && item.id === this.state.schedule.id}

                                onClick={() => this.handleChange(name, item)}>

                                <ListGroupItemHeading>{item.name}</ListGroupItemHeading>
                                <ListGroupItemText style={{ textTransform: 'capitalize' }}>
                                    From {item.open} to {item.close}
                                </ListGroupItemText>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </>}

            {!this.state.loading && this.state.scheduleOptions && this.state.scheduleOptions.length == 0 &&
                <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Unavailable</strong> There are no slots available on that date, please choose a different day</p>
                </div>
            }

            {this.state.loading && <Spinner animation="border" />}
        </div>);
    }
}

export class BookingTypeSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, loading: false };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {

        const {
            scheduleId
        } = this.props;

        if (prevProps.scheduleId !== scheduleId)
            await this.loadData();
    }

    handleChange(name, value) {

        const {
            onChange
        } = this.props;

        this.setState({
            bookingType: value
        });

        if (onChange)
            onChange(name ? name : "bookingTypeId", value);
    }

    async loadData() {

        const {
            name,
            scheduleId
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true });

            var user = await HengyiApi.GetCurrentUser();

            var response = await HengyiBookingTypesClient.List(new ListBookingTypeQueryParams()
                .WithUser(user.id)
                .WithScheduleId(scheduleId).Paginate(0, 1000));

            var bookingOptions = response.data.data.map(i => { return { value: i.id, label: i.name, maximumBookingLength: i.maximumBookingLength, isMultiple: i.isMultiple }; });

            this.setState({
                loading: false,
                bookingOptions: bookingOptions
            });

            if (bookingOptions.length === 1)
                this.handleChange(name, bookingOptions[0]);

        }
    }

    render() {

        const {
            name,
        } = this.props;

        return (<div className="form-group">

            {!this.state.loading && this.state.bookingOptions && this.state.bookingOptions.length > 1 &&
                <>
                    <h2>Select a booking type</h2>
                    <hr />

                    <ListGroup>
                        {this.state.bookingOptions.map((item) => (
                            <ListGroupItem key={item.value}
                                style={{ margin: "0", textAlign: "left" }}
                                active={(this.state.bookingType && item.value === this.state.bookingType.value) ? true : false}
                                onClick={() => this.handleChange(name, item)}>

                                <ListGroupItemHeading>{item.label}</ListGroupItemHeading>
                                <ListGroupItemText>
                                    This booking has a maximum allowance of {item.maximumBookingLength} minutes.
                                        <br />
                                    {!item.isMultiple && <i>You can only have one active booking at a time.</i>}
                                </ListGroupItemText>
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                </>}

            {!this.state.loading && this.state.bookingOptions && this.state.bookingOptions.length == 0 &&
                <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Unavailable</strong> There are no slots available on that date, please choose a different day.</p>
                </div>
            }

            {this.state.loading && <Spinner animation="border" />}
        </div>);
    }
}
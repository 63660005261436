import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class FeatureQueryParams extends HengyiListQueryParams {

}

//******************************
// Features
//******************************
export class HengyiFeatureClient {

    static async List(params: FeatureQueryParams) {

        var query = (await GetAPIDomain()) + "/features";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

}
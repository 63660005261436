import React, { Component } from 'react';
import './HeroImage.css';
import { SubNav } from './SubNav';


export class HeroImage extends Component {

    render() {

        let coverTitle = this.props.coverTitle ? this.props.coverTitle : "Your space";
        let subTitle = this.props.subTitle ? this.props.subTitle : "Welcome home";

        return (
            <div>
                <header className="pos-rel bg-dark-grey pv-80 white bg-cover" style={{ "paddingTop": this.props.padding, "paddingBottom": this.props.padding, "backgroundImage": "url('" + this.props.backgroundImg + "')" }}>
                    <div className="container pt-40">
                        <h1 className="section-heading white ft-alt ft-caps pt-40">{coverTitle}</h1>
                        <p className="feature-content ft-caps pt-20 white ft-12">{subTitle}</p>
                    </div>
                </header>

                {this.props.hideNav !== true && <SubNav />}
            </div>
        );
    }
}

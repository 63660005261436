import React, { Component } from 'react';
import { Button, Badge, Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiCreditCardClient, CreditCardQueryParams } from '../../infrastructure/HengyiClient/CreditCards';

export class CreditCardsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };
        this.makeDefault = this.makeDefault.bind(this);
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async makeDefault(id) {
        await HengyiCreditCardClient.MakeDefault(id);
        await this.fetchData();
    }

    async delete(id) {
        await HengyiCreditCardClient.Delete(id);
        await this.fetchData();
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var response = (await HengyiCreditCardClient.List(new CreditCardQueryParams()
            .WithUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Type</th>
                            <th style={{ borderTop: "none" }}>Expiry</th>
                            <th style={{ borderTop: "none" }}>Last 4 digits</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(item =>
                            <tr key={item.id}>
                                <td style={{ textTransform: 'capitalize', lineHeight: "50px" }}>
                                    {item.cardType && <span>{item.cardType} </span>}
                                    {!item.cardType && <i>Not specified</i>}
                                    {item.isDefault && <Badge color="primary">Default</Badge>}
                                </td>
                                <td style={{ lineHeight: "50px" }}>
                                    {item.expiry && item.expiry}
                                    {!item.expiry && <i>Not specified</i>}
                                </td>
                                <td style={{ lineHeight: "50px" }}>
                                    {item.lastFourDigits && item.lastFourDigits}
                                    {!item.lastFourDigits && <i>Not specified</i>}
                                </td>
                                <td>
                                    <Button key={item.id + "-delete"} style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => { this.delete(item.id); }}>Delete</Button>
                                    {!item.isDefault && <Button key={item.id + "-default"} style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => { this.makeDefault(item.id); }}>Make default</Button>}
                                </td>
                            </tr>
                        )}
                        {this.state.data.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No cards to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <Card style={{ marginTop: "40px" }}>
                <CardHeader>
                    <h2>Credit cards
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/profile/credit-cards/new" className="btn btn-outline-dark mr-2">Add card</Link>
                    </h2>
                </CardHeader>
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }
}

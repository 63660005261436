import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Spinner, Alert  } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HeroImage } from '../layout/HeroImage';
import { userContext } from '../../userContext';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Link } from 'react-router-dom';
import { GetAssetDomain } from '../infrastructure/Helper';

export class PasswordResetComplete extends Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, success: false, badCode: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (HengyiApi.IsAuthenticated()) {
            HengyiApi.LogOut();
        }
    }


    renderContent() {

        return (<Formik
            initialValues={{
                password: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                var response = await HengyiApi.CompletePasswordReset(this.props.match.params.code, fields.password);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);

                        if (error.key === "code")
                            this.setState({ badCode: true });

                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ success: true });
                }
            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-light mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "RESET PASSWORD"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5>Success</h5>
            We have reset your password, please use your new password from now on
                    </Alert>
            <Link className="btn mr-2 btn-outline-light mt-2" to="/sign-in">SIGN IN</Link></div>);
    }

    render() {

        return (

            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Password reset" subTitle="You're almost there, use the form below to get a new password" />
                <Container style={{ "paddingTop": "20px" }}>
                    <h2>Choose your new password</h2>
                    <hr />
                    {!this.state.badCode && !this.state.success && this.renderContent()}
                    {!this.state.badCode && this.state.success && this.renderSuccessScreen()}
                    {this.state.badCode && <div><Alert color="danger">
                        <h5>Invalid code</h5>
                        The password reset link is no longer valid
                    </Alert>
                        <Link className="btn mr-2 btn-outline-light mt-2" to="/password-reset/begin">START THE PROCESS AGAIN</Link></div>}
                </Container>
            </div>
        );

    }
}

PasswordResetComplete.contextType = userContext;
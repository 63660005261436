import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class ParcelQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithHideCollected(value: Boolean) {
        this.WithParam("hideCollected", value);
        return this;
    }

    WithPreferenceInidcation(value: Boolean) {
        this.WithParam("hasIndicatedPreference", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }
}

//******************************
// PETS
//******************************
export class HengyiParcelClient {

    static async List(params: ParcelQueryParams) {

        var query = (await GetAPIDomain()) + "/parcels" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + id, {
            headers: GetAPIHeaders()
        }));

    }


    static async SetParcelAsResidentWillCollect(parcelId: string, date: string, deliverySlot: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/notify-pick-up", {
            method: 'post',
            headers: await GetAPIHeaders(), body: JSON.stringify({
                requestedHandoverDate: date,
                requestedHandoverDeliverySlot: deliverySlot
            })
        }));
    }

    static async SetParcelAsTakeUpToApartment(parcelId: string, date: string, deliverySlot: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parcels/" + parcelId + "/request-drop-off", {
            method: 'post',
            headers: await GetAPIHeaders(), body: JSON.stringify({
                requestedHandoverDate: date,
                requestedHandoverDeliverySlot: deliverySlot
            })
        }));
    }


}
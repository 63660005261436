import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, TicketQueryParams } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Tickets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            skip: 0, take: 50, tickets: "", loading: false, total: 0,
            hideCancelled: true, hideResolved: true
        };

        this.handleHideResolved = this.handleHideResolved.bind(this);
        this.handleHideCancelled = this.handleHideCancelled.bind(this);

    }

    handleHideResolved = (evt) => {
        this.setState({
            hideResolved: evt.target.checked
        }, () => this.fetchData());
    }

    handleHideCancelled = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked
        }, () => this.fetchData());
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(nextProps.match.params.parent);
        }
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(this.props.match.params.parent);
        }
    }

    async fetchData() {

        this.setState({ loading: true });
        var response = await HengyiApi.GetCurrentUser()
        var tickets = await HengyiApi.GetTickets(new TicketQueryParams()
            .WithUser(response.id)
            .WithHideResolved(this.state.hideResolved ? "true" : "false")
            .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
            .Paginate(this.state.skip, this.state.take));

        this.setState({
            loading: false,
            tickets: tickets.data.data,
            total: tickets.data.total
        });
    }

    renderTable() {
        return (
            <Card>
                <CardHeader>
                    <h2>Reports
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/requests/new" className="btn btn-outline-dark mr-2">New report</Link>
                    </h2>
                </CardHeader>
                <CardHeader>
                    <div className="form-group">
                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideResolved"
                                        type="checkbox"
                                        checked={this.state.hideResolved}
                                        onChange={this.handleHideResolved} />  Hide resolved
                                        </label>
                            </li>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCancelled"
                                        type="checkbox"
                                        checked={this.state.hideCancelled}
                                        onChange={this.handleHideCancelled} />  Hide cancelled
                                        </label>
                            </li>
                        </ul>
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    <div className="table-responsive">
                        {this.state.tickets && <table className='table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th style={{ borderTop: "none" }}>Type</th>
                                    <th className="d-none d-md-block" style={{ borderTop: "none" }}>Floor</th>
                                    <th style={{ borderTop: "none" }}>Location</th>
                                    <th style={{ borderTop: "none" }}>Status</th>
                                    <th style={{ borderTop: "none" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tickets.map(item =>
                                    <tr key={item.id}>
                                        <td>{item.type && item.type.name}{!item.type && <i>Not specified</i>}</td>
                                        <td className="d-none d-md-block">{item.floor && item.floor.name}{!item.floor && <i>Not specified</i>}</td>
                                        <td>{item.location}</td>
                                        <td>
                                            {item.status === "open" && <Badge color="primary">Open</Badge>}
                                            {item.status === "resolved" && <Badge color="success">Resolved</Badge>}
                                            {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                        </td>
                                        <td>
                                            <Link key={item.id + "-view"} style={{ float: "right", minWidth: "0", padding: "6px" }} className="btn btn-outline-dark" to={"/requests/details/" + item.id}>Details</Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>}

                        {this.state.tickets && this.state.tickets.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No reports to display</i></h3>}

                    </div>
                </CardBody>
            </Card>);
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Reports" subTitle="Reports you've submitted" />


                <Container style={{ "paddingTop": "20px" }}>

                    <Row>
                        <Col>
                            {this.renderTable()}
                        </Col>
                        <Col md="3">
                            <Card style={{ marginBottom: "15px" }}>
                                <CardHeader>Reports</CardHeader>
                                <CardBody>
                                    <p>See any issues or hazards around the building?</p>
                                    <p>Report it to notify staff and the building manager.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders } from '../Helper';

export class MessageQueryParams extends HengyiListQueryParams {

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithThread(value: string) {
        this.WithParam("threadId", value);
        return this;
    }

    WithDirection(value: string) {
        this.WithParam("messageDirection", value);
        return this;
    }
}

//******************************
// TICKETS
//******************************
export class HengyiMessageClient {

    static async List(params: MessageQueryParams) {

        var query = (await GetAPIDomain()) + "/messages" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async CreateForBooking(content: string, bookingId: string, userId: string) {

        var request: any = {
            content: content,
            direction: "toResident",
            bookingId: bookingId,
            userId: userId
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/messages/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Create(content: string, threadId?:string) {

        var request: any = {
            content: content,
            direction: "toConcierge",
            threadId: threadId
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/messages/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/messages/" + id, {
            headers: GetAPIHeaders()
        }));

    }

}
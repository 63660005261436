import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { GetAPIDomain, GetAPIHeadersPatch } from '../infrastructure/Helper';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { SelectField } from '../infrastructure/SelectField';
import { GetAssetDomain } from '../infrastructure/Helper';

export class UploadDocument extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }


    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        this.setState({
            init: true,
            loading: false,
            apartments: currentUser.apartments,
            apartmentOptions: currentUser.apartments.map(function (apartment) { return { value: apartment.apartment.id, label: apartment.apartment.name }; })
        });
    }


    renderForm() {

        return (<Formik
            initialValues={{
                file: "",
                userType: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                let formData = new FormData();
                formData.append("file", fields.file);

                const response = await fetch((await GetAPIDomain()) + "/documents/" + this.props.match.params.id + "/upload-file-to-my-documents", {
                    method: 'post',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                    },
                    body: formData
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {

                    const data = await response.json();

                    var audiences = this.state.apartmentOptions.map((a) => {
                        return {
                            type: "apartment",
                            userType: fields.userType,
                            entityId: a.value
                        };
                    });

                    if (this.state.apartmentOptions.length > 1 && fields.apartments && fields.apartments.length > 0) {
                        audiences = fields.apartments.map((a) => {
                            return {
                                type: "apartment",
                                userType: fields.userType,
                                entityId: a.value
                            };
                        });
                    }

                    await fetch((await GetAPIDomain()) + "/documents/" + data.id, {
                        method: 'patch',
                        headers: await GetAPIHeadersPatch(),
                        body: JSON.stringify([{
                            "op": "replace",
                            "path": "audiences",
                            "value": audiences
                        }])
                    });

                    this.setState({ next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <h5>Who can see the document</h5>
                <hr />
                    <Row>
                        {this.state.apartmentOptions.length > 1 && <Col>
                            <div className="form-group">
                                <SelectField
                                    id="apartments"
                                    name="apartments"
                                    label="Spaces"
                                    placeholder="All my spaces"
                                    options={this.state.apartmentOptions}
                                    value={values.apartments}
                                    isMulti={true}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.apartments}
                                    error={errors.apartments}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                        </Col>}
                    <Col>
                            <div className="form-group">
                                <label htmlFor="userType">User type</label>
                                <select name="userType"
                                    value={values.userType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.userType && touched.userType ? ' is-invalid' : '')}>
                                    <option value="" label="Any user type" />
                                    <option value="resident" label="Resident" />
                                    <option value="owner" label="Owner" />
                                </select>
                                <ErrorMessage name="userType" component="div" className="invalid-feedback" />
                            </div>
                    </Col>
                </Row>

                <hr />
                <h5>The document to upload</h5>
                <hr />
                <div className="form-group">
                    <label htmlFor="file">File to upload</label>
                    <input className="form-control-file" id="file" name="file" type="file" onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                    }} />
                </div>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "UPLOAD"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to={"/documents/" + this.props.match.params.id} />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Upload document" subTitle="Upload a new document to your 'My Documents' area" />


                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <h2>Upload document
                                    <Link to={"/documents/" + this.props.match.params.id} className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                                </h2>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}

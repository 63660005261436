import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { userContext } from '../../userContext';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Layout extends Component {
    static displayName = Layout.name;

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    render() {
        return (
            <div>
                <NavMenu />

                <div style={{paddingBottom: "80px"}}>
                {this.props.children}
                    </div>
                <footer>
                    <Container style={{ paddingTop:"50px", paddingBottom:"50px"}}>
                        <Row>
                            <Col className="col-md-4">
                                <h5 className="white navTitle">Account</h5>
                                <userContext.Consumer>
                                    {({ user, logoutUser }) => {
                                        if (HengyiApi.IsAuthenticated()) {
                                            return (
                                                <span>
                                                    <p><Link className="white" to="/">Dashboard</Link></p>
                                                    <p><Link className="white" to="/requests">Requests</Link></p>
                                                    <p><Link className="white" to="/documents/home">Documents</Link></p>
                                                    <p><Link className="white" to="/" onClick={logoutUser}>Sign out</Link></p>
                                                </span>);
                                        } else {
                                            return (
                                                <p><Link className="white" to="/">Sign in</Link></p>);
                                        }
                                    }}
                                </userContext.Consumer>

                            </Col>
                            <Col className="col-md-4">
                                <h5 className="white navTitle">About</h5>
                                <p><Link className="white" to="/contact-us">Contact us</Link> </p>
                                <p><Link className="white" to="/privacy-policy">Privacy Policy</Link> </p>
                                <p><Link to="/terms-and-conditions" className="white">Terms & Conditions</Link></p>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                    </Container>
                    <div style={{ backgroundColor: "White", textAlign: "center", paddingTop: "50px", paddingBottom: "50px" }}>
                        {this.state && <img style={{ width: "100px" }} alt="The Logo" src={this.state.assetUrl + "/tenant/image/LogoAlt.jpg?t=" + this.state.offset} />}
                    </div>
                </footer>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';
import { HengyiParkedVehicleClient, ParkedVehicleQueryParams } from '../infrastructure/HengyiClient/ParkedVehicles';

export class ParkedVehicles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            skip: 0, take: 50, tickets: "", loading: false, total: 0,
            hideCancelled: true, hideCollected: true
        };

        this.handleHideCollected = this.handleHideCollected.bind(this);
        this.handleHideCancelled = this.handleHideCancelled.bind(this);

    }

    handleHideCollected = (evt) => {
        this.setState({
            hideCollected: evt.target.checked
        }, () => this.fetchData());
    }

    handleHideCancelled = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked
        }, () => this.fetchData());
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(nextProps.match.params.parent);
        }
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(this.props.match.params.parent);
        }
    }

    async fetchData() {

        this.setState({ loading: true });
        var response = await HengyiApi.GetCurrentUser()

        var parkedVehicles = await HengyiParkedVehicleClient.List(new ParkedVehicleQueryParams()
            .WithUser(response.id)
            .WithHideCollected(this.state.hideCollected ? "true" : "false")
            .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
            .Sort("created")
            .Paginate(this.state.skip, this.state.take));

        this.setState({
            loading: false,
            parkedVehicles: parkedVehicles.data.data,
            total: parkedVehicles.data.total
        });
    }

    renderTable() {
        return (
            <Card>
                <CardHeader>
                    <h2>Valet Tickets
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                    </h2>
                </CardHeader>
                <CardHeader>
                    <div className="form-group">
                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCompleted"
                                        type="checkbox"
                                        checked={this.state.hideCollected}
                                        onChange={this.handleHideCollected} />  Hide completed tickets
                                        </label>
                            </li>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCancelled"
                                        type="checkbox"
                                        checked={this.state.hideCancelled}
                                        onChange={this.handleHideCancelled} />  Hide cancelled tickets
                                        </label>
                            </li>
                        </ul>
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    <div className="table-responsive">
                        {this.state.parkedVehicles && <table className='table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th style={{ borderTop: "none" }}>Ticket</th>
                                    <th style={{ borderTop: "none" }}>Parked</th>
                                    <th style={{ borderTop: "none" }}>Collected</th>
                                    <th style={{ borderTop: "none" }}>Status</th>
                                    <th style={{ borderTop: "none" }}>Vehicle</th>
                                    <th style={{ borderTop: "none" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.parkedVehicles.map(item =>
                                    <tr key={item.id}>
                                        <td>
                                            {item.ticketNumber && item.ticketNumber}
                                            {!item.ticketNumber && <i>Not specified</i>}
                                        </td>
                                        <td>
                                            {item.parked && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.parked}</Moment>}
                                            {!item.parked && <i>Not specified</i>}
                                        </td>
                                        <td>
                                            {item.pickedUp && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.pickedUp}</Moment>}
                                            {!item.pickedUp && <i>Not specified</i>}
                                        </td>
                                        <td>
                                            {item.status === "parked" && <Badge color="primary">Parked</Badge>}
                                            {item.status === "readyForPickup" && <Badge color="info">Ready for collection</Badge>}
                                            {item.status === "collected" && <Badge color="success">Collected</Badge>}
                                            {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                        </td>
                                        <td>
                                            {item.vehicle && <span>{item.vehicle.licensePlate} ({item.vehicle.colour} {item.vehicle.make} {item.vehicle.model})</span>}
                                            {!item.vehicle && <i>Not specified</i>}
                                        </td>
                                        <td>
                                            <Link key={item.id + "-view"} style={{ float: "right", minWidth: "0", padding: "6px" }} className="btn btn-outline-dark" to={"/valet/details/" + item.id}>Details</Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>}

                        {this.state.parkedVehicles && this.state.parkedVehicles.length === 0 &&
                            <h3 className="pt-3 pb-4 text-center text-muted"><i>No parked vehicles to display</i></h3>}

                    </div>
                </CardBody>
            </Card>);
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Requests" subTitle="Requests you've submitted" />

                <Container style={{ "paddingTop": "20px" }}>
                    <Row>
                        <Col>
                            {this.renderTable()}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class CreditCardQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }
}

//******************************
// CREDIT CARDS
//******************************
export class HengyiCreditCardClient {

    static async List(params: CreditCardQueryParams) {

        var query = (await GetAPIDomain()) + "/credit-cards" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/credit-cards/" + id, {
            headers: GetAPIHeaders()
        }));

    }

    static async Create(token: string) {

        var request: any = {
            "token": token
        };

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/credit-cards/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async MakeDefault(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/credit-cards/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "isDefault",
                "value": true
            }])
        }));
    }

    static async Rename(id: string, name : string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/credit-cards/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "nickname",
                "value": name
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/credit-cards/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders(),
            method: 'delete'
        }));
    }
}
import React, { Component } from 'react';
import { Badge, Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi, BookingQueryParams } from '../infrastructure/fetchHengy';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendar, faCross } from '@fortawesome/free-solid-svg-icons'
import { HengyiBookingsClient, ListBookingQueryParams } from '../infrastructure/HengyiClient/Bookings';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Bookings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            bookings: [],
            modal: false,
            id: "",
            search: "", futureOnly: true, hideCancellations: true
        };

        this.handleHideCancellations = this.handleHideCancellations.bind(this);
        this.handleFutureOnlyChange = this.handleFutureOnlyChange.bind(this);
    }


    handleHideCancellations = (evt) => {
        this.setState({
            hideCancellations: evt.target.checked,
            skip: 0
        }, () => this.fetchData());
    }

    handleFutureOnlyChange = (evt) => {
        this.setState({
            futureOnly: evt.target.checked,
            skip: 0
        }, () => this.fetchData());
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async fetchData() {
        this.setState({
            loading: true
        });

        var futureOnly = this.state.futureOnly ? true : null;

        var query = new ListBookingQueryParams()
            .WithHideCancellation(this.state.hideCancellations ? "true" : "false")
            .WithLimitToFutureBookings(futureOnly)
            .Paginate(this.state.skip, this.state.take);

        if (futureOnly) {
            query = query.Sort("start").Ascending()
        } else {
            query = query.Sort("start").Descending()
        }

        var bookings = await HengyiBookingsClient.List(query);

        this.setState({
            loading: false,
            bookings: bookings.data.data,
            total: bookings.data.total
        });
    }

    async cacelBooking() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var booking = await HengyiApi.CancelBooking(this.state.id);
            
            this.setState({
                loading: false
            });

            this.fetchData();
            this.toggle("");
        }
    }

    async toggle(id) {
        this.setState({ modal: !this.state.modal, id: id })
    }

    renderTable() {
        return (
            <Card>
                <CardHeader>
                    <h2>Your bookings
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/bookings/new" className="btn btn-outline-dark mr-2">New booking</Link>
                    </h2>
                </CardHeader>
                <CardHeader>
                    <div className="form-group">
                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCancellations"
                                        type="checkbox"
                                        checked={this.state.hideCancellations}
                                        onChange={this.handleHideCancellations} />  Hide cancelled bookings
                                        </label>
                            </li>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="futureOnly"
                                        type="checkbox"
                                        checked={this.state.futureOnly}
                                        onChange={this.handleFutureOnlyChange} />  Future bookings only
                                        </label>
                            </li>
                        </ul>
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    <div className="table-responsive">
                        <table className='table' aria-labelledby="tabelLabel">
                            <tbody>

                                {this.state.bookings && this.state.bookings.map(item => {

                                    var jsx = (<tr key={item.id}>
                                        <td style={{ textTransform: 'capitalize' }}>
                                            {item.schedule && item.schedule.facility && <span><FontAwesomeIcon icon={faBuilding} /> {item.schedule.facility.name}</span>}
                                            {item.schedule && item.schedule.event && <span><FontAwesomeIcon icon={faCalendar} /> {item.schedule.event.name}</span>}
                                            {!item.schedule && <span><i>Event/ facility removed</i></span>}

                                        </td>
                                        <td>{item.bookingType && item.bookingType.name}</td>
                                        <td>{!item.bookingType && <i>n/a</i>}</td>
                                        <td>
                                            <strong><Moment utc local format="ddd, DD/MM" date={item.start} />:</strong> <Moment utc local format="h:mma" date={item.start} /> - <Moment utc local format="h:mma" date={item.end} />
                                        </td>
                                        <td>
                                            {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                            {item.status === "confirmed" && <Badge color="success">Confirmed</Badge>}
                                            {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                        </td>
                                        {item.exivoEnabled && <td>PIN:  <a href={'https://picker.exivo.io/unlockdoor/' + item.externalId}>{item.exivoBookingCode}</a></td>}
                                        {!item.exivoEnabled && <td></td>}
                                        <td>
                                            {item.status !== 'cancelled' && (Date.parse(item.end) > Date.now()) && <Button key={item.id + "-view"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "0" }} className="btn btn-outline-dark" color="" onClick={() => this.toggle(item.id)} disabled={item.status == 'cancelled'}>Cancel</Button>}
                                        </td>
                                    </tr>);

                                    if (item.status === "cancelled") {
                                        return [jsx, <tr><td colSpan="7">
                                            {item.reasonCancelled && item.reasonCancelled}
                                            {!item.reasonCancelled && <i>Not reason for cancellation given</i>}
                                        </td></tr>];
                                    }

                                    return jsx;
                                }

                                    

                                )}
                            </tbody>
                        </table>


                        {this.state.bookings && this.state.bookings.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No bookings to display</i></h3>}

                        <Modal isOpen={this.state.modal}>
                            <ModalHeader toggle={() => this.toggle()}>Cancel confirmation</ModalHeader>
                            <ModalBody>
                                Are you sure you want to cancel this booking?
                    </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => this.toggle("")}>No</Button>
                                <Button color="danger" onClick={() => this.cacelBooking()}>Yes</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </CardBody>
            </Card>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Bookings" subTitle="Bookings you've submitted" />

                <Container style={{ "paddingTop": "20px" }}>

                    {this.renderTable()}

                </Container>
            </div>
        );
    }
}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class PartnerOfferCategoryQueryParams extends HengyiListQueryParams {

}

//******************************
// Partner offer categories
//******************************
export class PartnerOfferCategoryClient {

    static async List(params: PartnerOfferCategoryQueryParams) {

        var query = (await GetAPIDomain()) + "/partner-offer-categories" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offer-categories/" + id, {
            headers: await GetAPIHeaders()
        }));

    }


    static async Create(
        name: string,
        description: string) {

        var request: any = {
            name: name,
            description: description
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offer-categories/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(
        id: string,
        name: string,
        description: string) {

        var updates = [{
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
            "path": "description",
            "value": description
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offer-categories/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Remove(id: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/partner-offer-categories/" + id, {
            method: 'delete',
            headers: await GetAPIHeaders()
        }));
    }

}
import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Card, CardBody, CardHeader, Badge } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import Moment from 'react-moment';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiNoticeClient, NoticeQueryParams } from '../../infrastructure/HengyiClient/Notices';

export class Notices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            pets: [],
            modal: false,
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async archiveNotice(id) {
        this.setState({ loading: true });

        await HengyiNoticeClient.Archive(id);
        await this.fetchData();

        this.setState({ loading: false });
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var notices = (await HengyiNoticeClient.List(new NoticeQueryParams()
            .WithUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            notices: notices.data.data,
            total: notices.data.total
        });
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Title</th>
                            <th style={{ borderTop: "none" }}>Type</th>
                            <th style={{ borderTop: "none" }}>Status</th>
                            <th style={{ borderTop: "none" }}>Expiry</th>
                            <th style={{ borderTop: "none" }}>Created</th>
                            <th style={{ borderTop: "none" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.notices && this.state.notices.map(item =>
                            <tr key={item.id}>
                                <td>
                                    {item.title && item.title}
                                    {!item.title && <i>Not specified</i>}
                                </td>
                                <td>
                                    {item.type === "info" && <Badge color="info">Info</Badge>}
                                    {item.type === "warning" && <Badge color="warning">Warning</Badge>}
                                    {item.type === "alert" && <Badge color="danger">Alert</Badge>}
                                </td>
                                <td>
                                    {item.status === "pending" && <Badge color="primary">Pending</Badge>}
                                    {item.status === "approved" && <Badge color="success">Approved</Badge>}
                                    {item.status === "archived" && <Badge color="secondary">Archived</Badge>}
                                </td>
                                <td>
                                    {item.expiry && <Moment utc local format="ddd DD MMM YY, yyyy">{item.expiry}</Moment>}
                                    {!item.expiry && <i>No expiry</i>}
                                </td>
                                <td>
                                    <Moment utc local format="ddd, DD/MM h:mma" date={item.created} /></td>
                                <td>
                                    <Link to={"/profile/notices/details/" + item.id} className="btn btn-outline-dark mt-2" style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }}>Details</Link>
                                    {item.status === "approved" && <button key={item.id + "-archive"} onClick={() => this.archiveNotice(item.id)} style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }} className="btn mr-2 btn-outline-dark mt-2" to={"/notices/" + item.id}>Archive</button>}
                                </td>
                            </tr>
                        )}
                        {this.state.cards && this.state.cards.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No notices to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Notices" subTitle="These are notices you've requested to display to building occupants" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>
                            <h2>Notices
                            {this.state.loading && <Spinner animation="border" />}
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                                <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/profile/notices/request" className="btn btn-outline-dark mr-2">Request notice</Link>
                            </h2>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.renderTable()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Badge, Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Container } from 'reactstrap';
import { HengyiMessageThreadClient, MessageThreadQueryParams } from '../infrastructure/HengyiClient/MessageThreads';
import Moment from 'react-moment';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HengyiMessageClient, MessageQueryParams } from '../infrastructure/HengyiClient/Messages';
import { faCar, faBullhorn, faCommentAlt, faCalendar, faTag } from '@fortawesome/free-solid-svg-icons'
import { HeroImage } from '../layout/HeroImage';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Conversations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            init: true, data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", inUseFilter: "", statusFilter: "",
            currentThreadId: "",
            currentThread: "",
            messages: []
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.updateCurrentThread = this.updateCurrentThread.bind(this);
    }

    handleStatusChange(evt) {
        this.setState({
            statusFilter: evt.target.value
        }, () => this.update());
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        const urlParams = new URLSearchParams(this.props.location.search);

        if (urlParams.get("currentThreadId")) {
            await this.populateData(this.state.skip, this.state.take);
            await this.updateCurrentThread(urlParams.get("currentThreadId"));
        } else {
            await this.populateData(this.state.skip, this.state.take);
        }

        this.timer = setInterval(() => this.populateData(this.state.skip, this.state.take), 5000);

    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    async updateCurrentThread(threadId) {

        if (threadId) {
            this.setState({ currentThreadId: threadId, currentThread: this.state.data.filter(item => item.id === threadId)[0] }, () => this.populateData(this.state.skip, this.state.take, true));
        } else {
            this.setState({ currentThread: null, currentThreadId: null }, () => this.populateData(this.state.skip, this.state.take, true));
        }
        setTimeout(() => {
            if (document.getElementById("message")) {
                document.getElementById("message").focus();

                var objDiv = document.getElementById("message");
                objDiv.scrollIntoView(false);
            }
        }, 100);


        await HengyiMessageThreadClient.MarkAsSeen(threadId);
        await this.update(false);

    }

    async update(scroll) {
        if (this.state.currentThreadId) {
            this.setState({ currentThread: this.state.data.filter(item => item.id == this.state.currentThreadId)[0] });
        } else {
            this.setState({ currentThread: null });
        }
        await this.populateData(this.state.skip, this.state.take, scroll);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table table-linked' aria-labelledby="tabelLabel">
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            {!this.state.currentThreadId && <td style={{ width: "70px" }} className="clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} >
                                <div className={this.state.currentThreadId === item.id && "selected-div"} key={item.id + "-location-view"} to={"/messages/" + item.id}>
                                    <div class="i-block">
                                        {item.messageThreadType === "general" && <FontAwesomeIcon size="2x" icon={faCommentAlt} />}
                                        {item.messageThreadType === "campaign" && <FontAwesomeIcon size="2x" icon={faBullhorn} />}
                                        {item.messageThreadType === "booking" && <FontAwesomeIcon size="2x" icon={faCalendar} />}
                                        {item.messageThreadType === "vehicle" && <FontAwesomeIcon size="2x" icon={faCar} />}
                                        {item.messageThreadType === "offer" && <FontAwesomeIcon size="2x" icon={faTag} />}
                                    </div>
                                </div>
                            </td>}
                            <td className="clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} style={{ minWidth: "100px" }}>
                                <div className={item.residentStatus === "new" ? "bold" : ""} key={item.id + "-location-view"} to={"/messages/" + item.id}>
                                    <p style={{ marginBottom: 0, marginTop: "10px" }}>
                                        <span>
                                            {item.messageThreadType === "general" && "Conversation"}
                                            {item.messageThreadType === "campaign" && item.campaign.title}
                                            {item.messageThreadType === "booking" && "Booking"}
                                            {item.messageThreadType === "vehicle" && "Vehicle"}
                                            {item.messageThreadType === "offer" && item.campaign && item.campaign.title}
                                            {item.messageThreadType === "offer" && !item.campaign&& item.partnerOffer && item.partnerOffer.name}
                                        </span> {item.residentStatus === "new" && <Badge color="primary">new</Badge>}</p>
                                    <p style={{ maxHeight: "25px", overflow: "hidden", color: "#a2a2a2", width: "100%", whiteSpace: "normal", wordBreak: "normal", padding: "0", margin: "0" }}>
                                        {item.latestMessage && item.latestMessage.content}
                                        {!item.latestMessage && <i>No messages</i>}
                                    </p>
                                    {this.state.currentThreadId && <small style={{ color: "#a2a2a2" }}>
                                        {item.latestMessage && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.latestMessage.created}</Moment>}
                                        {item.latestMessage && <i>N/A</i>}
                                    </small>}
                                </div>
                            </td>
                            {!this.state.currentThreadId && <td className="text-right clickable" onClick={() => { this.updateCurrentThread(item.id); return false; }} >
                                <div className={item.residentStatus === "new" ? "bold" : ""} key={item.id + "-location-view"} to={"/messages/" + item.id}>
                                    <small style={{ marginRight: "15px", marginTop: "30px", display: "block" }}>
                                        {item.latestMessage && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.latestMessage.created}</Moment>}
                                        {!item.latestMessage && <i>No messages</i>}
                                    </small>
                                </div>
                            </td>}
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="2"><h4 className="text-muted text-center mt-3"><i>No messages to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Messages" subTitle="Messages with concierge" />
                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Messages ({this.state.total})
                                     {this.state.init && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Messages with concierge</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                            <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                            <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/messages/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                        </div>
                                    </Col>
                                </Row>

                            </CardHeader>


                            {!this.state.currentThread &&

                                <CardBody>
                                    <i className="feather icon-star"></i> Select a conversation below to get started
                                </CardBody>

                            }

                            {this.state.currentThread && this.state.currentThread.messageThreadType === "general" &&

                                <CardBody>
                                    <FontAwesomeIcon icon={faCommentAlt} /> Conversation between you and concierge
                            </CardBody>

                            }

                            {this.state.currentThread && this.state.currentThread.messageThreadType === "campaign" &&

                                <CardBody>
                                    <FontAwesomeIcon icon={faBullhorn} /> Reply to '<strong>{this.state.currentThread.campaign.title}</strong>'
                            </CardBody>

                            }

                            {this.state.currentThread && this.state.currentThread.messageThreadType === "vehicle" &&

                                <CardBody>
                                    <FontAwesomeIcon icon={faCar} /> Conversation about the vehicle '<strong>{this.state.currentThread.vehicle.make} {this.state.currentThread.vehicle.model} - {this.state.currentThread.vehicle.licensePlate}</strong>'
                            </CardBody>

                            }

                            {this.state.currentThread && this.state.currentThread.messageThreadType === "booking" &&

                                <CardBody>
                                    <FontAwesomeIcon icon={faCalendar} /> Conversation about booking on
                               <strong> <Moment utc local format="ddd, DD/MM" date={this.state.currentThread.booking.start} />: <Moment utc local format="h:mma" date={this.state.currentThread.booking.start} /> - <Moment utc local format="h:mma" date={this.state.currentThread.booking.end} /></strong>
                                </CardBody>
                            }


                            {this.state.currentThread && this.state.currentThread.messageThreadType === "offer" && this.state.currentThread.partnerOffer &&

                                <CardBody>
                                    <FontAwesomeIcon icon={faTag} /> Conversation about the offer <Link to={"/offers/details/" + this.state.currentThread.partnerOffer.id}>'<strong>{this.state.currentThread.partnerOffer.name}</strong>'</Link>
                                </CardBody>

                            }

                        </Card>

                        <Row>
                            <Col className={this.state.currentThreadId && "col-md-3"} style={{ height: "calc(100vh - 350px)" }}>
                                <Card style={{ height: "100%", overflowY: "scroll" }}>
                                    <CardBody className="p-0">
                                        {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                                    </CardBody>
                                </Card>
                            </Col>
                            {this.state.currentThreadId && <Col>
                                <div id="convo-card" className="card" style={{ height: "calc(100vh - 350px)" }}>
                                    <div id="chat-container" className="card-body" style={{ height: "100%", overflowY: "scroll" }}>
                                        <div className="h-list-body">
                                            {this.state.messages && this.state.messages.map(message =>
                                                <div className={message.direction}>
                                                    <p className={"chat-message " + message.direction} style={{ whiteSpace: "pre-wrap" }}>
                                                        {message.content}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <Formik
                                        initialValues={{
                                            message: "",
                                        }}
                                        onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                            if (fields.message) {
                                                var message = fields.message;
                                                setFieldValue("message", "");

                                                var newMessage = await HengyiMessageClient.Create(message, this.state.currentThreadId);
                                                var existingMessages = this.state.messages;
                                                existingMessages.push(newMessage.data);

                                                await this.update(true);
                                            }
                                        }}
                                    >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                        <Form>
                                            <InputGroup>
                                                <Field id="message" name="message" type="text" className="form-control" style={{ borderRadius: "0" }} />
                                                <ButtonGroup className="input-group-append" style={{ borderRadius: "0" }}>
                                                    <button style={{ padding: "0" }} type="submit" className="btn btn-outline-dark" >Send</button>
                                                </ButtonGroup>
                                            </InputGroup>
                                        </Form>
                                    )}</Formik>
                                </div>
                            </Col>}
                        </Row>

                    </Container>
                </div>
            );
        }
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take, scroll) {


        if (this.state.currentThreadId) {
            await HengyiMessageThreadClient.MarkAsSeen(this.state.currentThreadId);
        }

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var currentUser = await HengyiApi.GetCurrentUser();

            var response = await HengyiMessageThreadClient.List(new MessageThreadQueryParams()
                .WithUser(currentUser.id)
                .Descending()
                .Sort("last-message")
                .Search(this.state.search)
                .Paginate(skip, take));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total, init: false });
            }

            if (this.state.currentThreadId) {

                var messages = await HengyiMessageClient.List(new MessageQueryParams()
                    .WithThread(this.state.currentThreadId)
                    .Ascending()
                    .Sort("created")
                    .Paginate(0, 1000));

                if (this.state.messages.length !== messages.data.data.length)
                    scroll = true;

                this.setState({
                    messages: messages.data.data
                }, () => {
                    if (scroll) {
                        var objDiv = document.getElementById("chat-container");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }
                });

            }

        }
    }
}

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Badge, Spinner } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import Moment from 'react-moment';
import { HengyiPetClient, PetQueryParams } from '../../infrastructure/HengyiClient/Pets';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiVehicleClient, VehicleQueryParams } from '../../infrastructure/HengyiClient/Vehicles';

export class Vehicles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            vehicles: [],
            modal: false,
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var vehicles = (await HengyiVehicleClient.List(new VehicleQueryParams()
            .WithUser(currentUser.id)
            .Sort("licensePlate")
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            vehicles: vehicles.data.data,
            total: vehicles.data.total
        });
    }

    renderTable(data) {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>License Plate</th>
                            <th style={{ borderTop: "none" }}>Type</th>
                            <th style={{ borderTop: "none" }}>Make/ Model</th>
                            <th style={{ borderTop: "none" }}>WoF</th>
                            <th style={{ borderTop: "none" }}>Colour</th>
                            <th style={{ borderTop: "none" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.vehicles.map(item =>
                            <tr key={item.id}>
                                <td style={{ textTransform: 'capitalize' }}>
                                    {item.licensePlate && item.licensePlate}
                                    {!item.licensePlate && <i>Not specified</i>}
                                </td>
                                <td>
                                    {item.fuelType === "ev" && <Badge color="primary">EV</Badge>}
                                    {item.fuelType === "petrol" && <Badge color="info">Petrol</Badge>}
                                    {item.fuelType === "diesel" && <Badge color="info">Diesel</Badge>}
                                    {item.fuelType === "hybrid" && <Badge color="info">Hybrid</Badge>}
                                </td>
                                <td>
                                    {item.make} - {item.model}
                                </td>
                                <td>
                                    {item.wofExpiry && <Moment utc local format="ddd DD MMM YY, yyyy">{item.wofExpiry}</Moment>}
                                    {!item.wofExpiry && <i>Not specified</i>}
                                </td>
                                <td>
                                    {item.colour && item.colour}
                                    {!item.colour && <i>Not specified</i>}
                                </td>
                                <td>
                                    <Link to={"/profile/vehicles/edit/" + item.id} className="btn btn-outline-dark" style={{ float: "right", minWidth: "0", padding: "6px", margin: "3px" }}>Edit</Link>
                                </td>
                            </tr>
                        )}
                        {this.state.vehicles.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No vehicles to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Vehicles" subTitle="Vehicles which have been registered" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>

                            <h2>Your vehicles
                            {this.state.loading && <Spinner animation="border" />}
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>

                            </h2>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.renderTable()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}

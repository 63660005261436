import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge, Button } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { HengyiEVChargingSessionClient, EVChargingSessionQueryParams } from '../infrastructure/HengyiClient/EVChargingSessions';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { GetAssetDomain } from '../infrastructure/Helper';

export class EVChargingSessions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            skip: 0, take: 50, tickets: "", loading: false, total: 0,
            hideCancelled: true, hideCompleted: true
        };

        this.handleHideCompleted = this.handleHideCompleted.bind(this);
        this.handleHideCancelled = this.handleHideCancelled.bind(this);

    }

    async cancelSession(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            await HengyiEVChargingSessionClient.Cancel(id);

            this.setState({
                loading: false
            }, () => this.fetchData());
        }
    }

    handleHideCompleted = (evt) => {
        this.setState({
            hideCompleted: evt.target.checked
        }, () => this.fetchData());
    }

    handleHideCancelled = (evt) => {
        this.setState({
            hideCancelled: evt.target.checked
        }, () => this.fetchData());
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(nextProps.match.params.parent);
        }
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData(this.props.match.params.parent);
        }
    }

    async fetchData() {

        this.setState({ loading: true });
        var response = await HengyiApi.GetCurrentUser()

        var sessions = await HengyiEVChargingSessionClient.List(new EVChargingSessionQueryParams()
            .WithUser(response.id)
            .WithHideCompleted(this.state.hideCompleted ? "true" : "false")
            .WithHideCancelled(this.state.hideCancelled ? "true" : "false")
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take));

        this.setState({
            loading: false,
            sessions: sessions.data.data,
            total: sessions.data.total
        });
    }

    renderTable() {
        return (
            <Card>
                <CardHeader>
                    <h2>Your charge sessions
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/ev-charging-sessions/new" className="btn btn-outline-dark mr-2">New request</Link>
                    </h2>
                </CardHeader>
                <CardHeader>
                    <div className="form-group">
                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCompleted"
                                        type="checkbox"
                                        checked={this.state.hideCompleted}
                                        onChange={this.handleHideCompleted} />  Hide completed sessions
                                        </label>
                            </li>
                            <li className="list-inline-item">
                                <label style={{ margin: "0" }}>
                                    <input name="hideCancelled"
                                        type="checkbox"
                                        checked={this.state.hideCancelled}
                                        onChange={this.handleHideCancelled} />  Hide cancelled sessions
                                        </label>
                            </li>
                        </ul>
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    <div className="table-responsive">
                        {this.state.sessions && <table className='table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr>
                                    <th style={{ borderTop: "none" }}>Vehicle</th>
                                    <th style={{ borderTop: "none" }}>Amount</th>
                                    <th style={{ borderTop: "none" }}>Energy</th>
                                    <th style={{ borderTop: "none" }}>Start</th>
                                    <th style={{ borderTop: "none" }}>End</th>
                                    <th style={{ borderTop: "none" }}>Status</th>
                                    <th style={{ borderTop: "none" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sessions.map(item =>
                                    <tr key={item.id}>
                                        <td>
                                            {item.vehicle && <span>{item.vehicle.licensePlate} ({item.vehicle.colour} {item.vehicle.make} {item.vehicle.model})</span>}
                                            {!item.vehicle && <i>Not specified</i>}
                                        </td>
                                        <td>
                                            {item.status === "complete" && <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amountToBill / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />}
                                            {item.status !== "complete" && <i>n/a</i>}
                                        </td>
                                        <td>
                                            {item.status === "complete" && item.energyUsed}
                                            {item.status !== "complete" && <i>n/a</i>}
                                        </td>
                                        <td>
                                            {item.start && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.start}</Moment>}
                                            {!item.start && <i>Not started</i>}
                                        </td>
                                        <td>
                                            {item.end && <Moment utc local format="ddd DD MMM YY, h:mm a">{item.end}</Moment>}
                                            {!item.end && <i>Not ended</i>}
                                        </td>
                                        <td>
                                            {item.status === "charging" && <Badge color="primary">Charging</Badge>}
                                            {item.status === "complete" && <Badge color="success">Complete</Badge>}
                                            {item.status === "cancelled" && <Badge color="secondary">Cancelled</Badge>}
                                            {item.status === "requested" && <Badge color="info">Requested</Badge>}
                                        </td>
                                        <td>
                                            {item.status === "requested" && 
                                                <Button key={item.id + "-cancel"} style={{ float: "right", minWidth: "0", padding: "4px", margin: "3px" }} className="btn btn-outline-dark" color="" onClick={() => this.cancelSession(item.id)}>Cancel</Button>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>}

                        {this.state.sessions && this.state.sessions.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No sessions to display</i></h3>}

                    </div>
                </CardBody>
            </Card>);
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Requests" subTitle="Requests you've submitted" />

                <Container style={{ "paddingTop": "20px" }}>
                    {this.renderTable()}
                </Container>
            </div>
        );
    }

}

import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Card, CardBody, CardHeader, Badge } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import Moment from 'react-moment';
import { HengyiPetClient, PetQueryParams } from '../../infrastructure/HengyiClient/Pets';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiSwipeCardClient, SwipeCardQueryParams } from '../../infrastructure/HengyiClient/SwipeCards';

export class SwipeCards extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            pets: [],
            modal: false,
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var cards = (await HengyiSwipeCardClient.List(new SwipeCardQueryParams()
            .WithUser(currentUser.id)
            .Sort("name")
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            cards: cards.data.data,
            total: cards.data.total
        });
    }

    async cardLost(id) {
        this.setState({ loading: true });

        await HengyiSwipeCardClient.LostCard(id);
        await this.fetchData();

        this.setState({ loading: false });
    }

    async cardFound(id) {
        this.setState({ loading: true });

        await HengyiSwipeCardClient.FoundCard(id);
        await this.fetchData();

        this.setState({ loading: false });
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Name</th>
                            <th style={{ borderTop: "none" }}>Identifier</th>
                            <th style={{ borderTop: "none" }}>Type</th>
                            <th style={{ borderTop: "none" }}>Status</th>
                            <th style={{ borderTop: "none" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.cards && this.state.cards.map(item =>
                            <tr key={item.id}>
                                <td>
                                    {item.name && item.name}
                                    {!item.name && <i>Not specified</i>}
                                </td>
                                <td>
                                    {item.cardIdentifier && item.cardIdentifier}
                                    {!item.cardIdentifier && <i>Not specified</i>}
                                </td>
                                <td>
                                    {item.type === "accessCard" && <Badge color="primary">Access</Badge>}
                                    {item.type === "evChargeCard" && <Badge color="success">EV Charge</Badge>}
                                    {item.type === "key" && <Badge color="info">Key</Badge>}
                                </td>
                                <td>
                                    {item.status === "resident" && <Badge color="success">Active</Badge>}
                                    {item.status === "contractor" && <Badge color="success">Assigned to contractor</Badge>}
                                    {item.status === "lost" && <Badge color="secondary">Lost</Badge>}
                                    {item.status === "disabled" && <Badge color="secondary">Disabled</Badge>}
                                </td>
                                <td>
                                    {item.status === "resident" && <button key={item.id + "-lost"} onClick={() => this.cardLost(item.id)} style={{ float: "right", padding: "6px" }} className="btn mr-2 btn-outline-dark mt-2" to={"/swipe-cards/" + item.id}>Mark as lost</button>}
                                    {item.status === "lost" && <button key={item.id + "-lost"} onClick={() => this.cardFound(item.id)} style={{ float: "right", padding: "6px" }} className="btn mr-2 btn-outline-dark mt-2" to={"/swipe-cards/" + item.id}>Mark as found</button>}
                                </td>
                            </tr>
                        )}
                        {this.state.cards && this.state.cards.length === 0 && <tr>
                            <td colSpan="6"><h4 className="text-muted text-center mt-3"><i>No cards to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Swipe cards" subTitle="Cards registered to your account for EV charging" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>
                            <h2>Swipe cards
                            {this.state.loading && <Spinner animation="border" />}
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                                <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                                <Link style={{ padding: "6px", float: "right", marginTop: "5px" }} to="/profile/swipe-cards/request" className="btn btn-outline-dark mr-2">Request new card</Link>
                            </h2>
                        </CardHeader>
                        <CardBody className="p-0">
                            {this.renderTable()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        );
    }
}

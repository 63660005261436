import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { userContext } from '../../userContext';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';

export class ProfileDetails extends Component {
    static displayName = ProfileDetails.name;

    constructor(props) {
        super(props);
        this.state = { authenticated: true };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5),
            data: await HengyiApi.GetCurrentUser(),
            actions: await HengyiApi.GetCurrentUserActions(),
            loading: false
        });
    }

    render() {

        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (<div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Your profile" subTitle="Manage your details" />

                <Container style={{ "paddingTop": "20px" }}>

                    {this.state.actions && this.state.actions.requireTempPasswordReset &&
                        <div className="alert alert-danger">
                            <p className="mt-2"><strong>Secure your account</strong>. You have a temporary password set, please choose a secure permanent password.</p>
                            <p className="mb-2"><Link to="/profile/change-password" className="btn mr-2 mt-2 mb-2 btn-danger">Choose password</Link></p>

                        </div>}

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Your profile
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update your profile details</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ padding: "6px", float: "right" }} to="/profile/edit" className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>
                                        <Link style={{ padding: "6px", float: "right" }} to="/profile/change-password" className="btn mr-2 btn-outline-dark mt-2 mb-2">Change Password</Link>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />
                                    {this.state.data &&
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td>
                                                        {this.state.data.name && this.state.data.name}
                                                        {!this.state.data.name && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Surname:</td>
                                                    <td>
                                                        {this.state.data.surname && this.state.data.surname}
                                                        {!this.state.data.surname && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Mobile:</td>
                                                    <td>
                                                        {this.state.data.mobile && this.state.data.mobile}
                                                        {!this.state.data.mobile && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Email:</td>
                                                    <td>
                                                        {this.state.data.email && this.state.data.email}
                                                        {!this.state.data.email && <i>Not specified</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Push notifications:</td>
                                                    <td>
                                                        {this.state.data.pushNotificationsEnabled && "Enabled"}
                                                        {!this.state.data.pushNotificationsEnabled && <i>Disabled</i>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pr-2">Parcel preference: </td>
                                                    <td>
                                                        {this.state.data.preferredParcelDeliveryOption === "askEachTime" && <span>Ask each time</span>}
                                                        {this.state.data.preferredParcelDeliveryOption === "bringUp" && <span>Bring up &nbsp;
                                    {!this.state.data.preferredParcelDeliverySlot && <Badge color="info">Any time</Badge>}
                                                            {this.state.data.preferredParcelDeliverySlot === "morning" && <Badge color="info">Morning</Badge>}
                                                            {this.state.data.preferredParcelDeliverySlot === "afternoon" && <Badge color="info">Afternoon</Badge>}
                                                            {this.state.data.preferredParcelDeliverySlot === "evening" && <Badge color="info">Evening</Badge>}
                                                        </span>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </Col>
                                <Col>
                                    <h6>Space</h6>
                                    <hr />

                                    {this.state.data && this.state.data.apartments.map(item =>
                                        <p key={item.apartment.id}>{item.apartment.name} <Badge color="primary">
                                            {item.relationship === "ownerOccupier" && "Owner occupier"}
                                            {item.relationship === "owner" && "Owner"}
                                            {item.relationship === "resident" && "Resident"}
                                        </Badge></p>)}

                                    {this.state.data && (!this.state.data.apartments || this.state.data.apartments.length === 0) &&
                                        <h4 className="text-muted mt-3"><i>No spaces</i></h4>
                                    }
                                </Col>
                                <Col md="3" className="text-center">
                                    <div className="form-group">
                                        <h6 className="text-left">Profile image</h6>
                                        <hr />
                                        {this.state.data && !this.state.data.profileImageId && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                        {this.state.data && this.state.data.profileImageId && <img alt="Uploaded by submitted" className="rounded-circle" src={this.state.assetUrl + "/image/" + this.state.data.profileImageId + ".jpg"} style={{ width: "200px", height: "200px", objectFit: "cover" }} />}

                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>);
        }
    }
}

ProfileDetails.contextType = userContext;
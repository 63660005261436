import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout/Layout';
import { Home } from './components/Home';
import { InvitationResponse } from './components/InvitationResponse';
import { TermsAndConditions } from './components/policy/TermsAndConditions';
import { PrivacyPolicy } from './components/policy/PrivacyPolicy';
import { ContactUs } from './components/pages/ContactUs';
import { OnboardingOne } from './components/OnboardingOne';
import { OnboardingTwo } from './components/OnboardingTwo';
import { CompleteInduction } from './components/CompleteInduction';
import { Documents } from './components/Documents';
import { userContext } from './userContext';
import { PrivateRoute } from './components/infrastructure/PrivateRoute';
import { HengyiApi } from './components/infrastructure/fetchHengy';

import './custom.css'
import { SignIn } from './components/SignIn';
import { PasswordResetBegin } from './components/pages/PasswordResetBegin';
import { PasswordResetComplete } from './components/pages/PasswordResetComplete';

import { Offers } from './components/offers/Offers';
import { OfferDetails } from './components/offers/OfferDetails';

import { Events } from './components/events/Events';
import { EventDetails } from './components/events/EventDetails';


import { Tickets } from './components/tickets/Tickets';
import { TicketsNew } from './components/tickets/TicketsNew';
import { TicketDetails } from './components/tickets/TicketDetails';

import { ParkedVehicles } from './components/valet/ParkedVehicles';
import { ParkedVehiclesDetails } from './components/valet/ParkedVehiclesDetails';

import { EVChargingSessions } from './components/evcharge/EVChargingSessions';
import { EVChargingSessionsNew } from './components/evcharge/EVChargingSessionsNew';

import { Bookings } from './components/bookings/Bookings';
import { BookingNew } from './components/bookings/BookingNew';

import { ProfileDetails } from './components/profile/ProfileDetails';
import { ProfileEdit } from './components/profile/ProfileEdit';
import { ChangePassword } from './components/profile/ChangePassword';
import { Parcels } from './components/parcels/Parcels';

import { Conversations } from './components/conversations/Conversations';
import { ConversationsNew } from './components/conversations/ConversationsNew';
import { ConversationsBookingsNew } from './components/conversations/ConversationsBookingsNew';

import { Pets } from './components/profile/pets/Pets';
import { PetsNew } from './components/profile/pets/PetsNew';
import { PetsEdit } from './components/profile/pets/PetsEdit';

import { SwipeCards } from './components/profile/swipecards/SwipeCards';
import { SwipeCardsNew } from './components/profile/swipecards/SwipeCardsNew';

import { Notices } from './components/profile/notices/Notices';
import { NoticeDetails } from './components/profile/notices/NoticeDetails';
import { NoticesNew } from './components/profile/notices/NoticesNew';
import { NoticesEdit } from './components/profile/notices/NoticesEdit';

import { Billing } from './components/profile/billing/Billing';
import { CreditCardNew } from './components/profile/billing/CreditCardNew';

import { Occupants } from './components/profile/occupants/Occupants';
import { InvitationsNew } from './components/profile/occupants/InvitationsNew';

import { Vehicles } from './components/profile/vehicles/Vehicles';
import { VehiclesEdit } from './components/profile/vehicles/VehiclesEdit';

import { Apartments } from './components/profile/apartments/Apartments';
import { ApartmentEdit } from './components/profile/apartments/ApartmentEdit';

import { UploadDocument } from './components/documentupload/UploadDocument';
import { HengyiFeatureClient, FeatureQueryParams } from './components/infrastructure/HengyiClient/Features';

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            features: []
        };
        this.logout = this.logout.bind(this);
        this.refreshApplicationState = this.refreshApplicationState.bind(this);
    }

    logout() {
        this.setState({
            user: {},
            features: []
        });
        HengyiApi.LogOut();
    }

    async refreshApplicationState() {

        var user = await HengyiApi.GetCurrentUser();
        var features = await HengyiFeatureClient.List(new FeatureQueryParams());
        
        if (user) {

            this.setState({
                    user: {
                        id: user.id,
                        name: user.name,
                        surname: user.surname,
                        mobile: user.mobile,
                        email: user.email
                },
                features: features.data.data.filter(f => f.enabled).map(p => p.id)
                });

        } else {
            this.setState({
                user: {},
                features: []
            });
        }
    }

    async componentDidMount() {
        this.refreshApplicationState();
        document.title = "Residential"
    }

    render() {

        const value = {
            user: this.state.user,
            logoutUser: this.logout,
            refresh: this.refreshApplicationState,
            features: this.state.features
        }
        
        return (
            <userContext.Provider value={value}>
                <Layout>
                    <PrivateRoute exact path='/' component={Home} />
                    <Route path='/sign-in' component={SignIn} />
                    <PrivateRoute path='/onboarding/step-01' component={OnboardingOne} />
                    <PrivateRoute path='/onboarding/step-02' component={OnboardingTwo} />
                    <PrivateRoute path='/induction/complete' component={CompleteInduction} />
                    <Route path='/invitation/accept/:code' component={InvitationResponse} />
                    <PrivateRoute path='/documents/:parent' component={Documents} />
                    <Route path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/contact-us' component={ContactUs} />
                    <Route path='/password-reset/begin' component={PasswordResetBegin} />
                    <Route path='/password-reset/complete/:code' component={PasswordResetComplete} />

                    <PrivateRoute exact path='/ev-charging-sessions' component={EVChargingSessions} />
                    <PrivateRoute exact path='/ev-charging-sessions/new' component={EVChargingSessionsNew} />

                    <PrivateRoute exact path='/requests' component={Tickets} />
                    <PrivateRoute exact path='/requests/new' component={TicketsNew} />
                    <PrivateRoute exact path='/requests/details/:id' component={TicketDetails} />

                    <PrivateRoute exact path='/valet' component={ParkedVehicles} />
                    <PrivateRoute exact path='/valet/details/:id' component={ParkedVehiclesDetails} />

                    <PrivateRoute exact path='/events' component={Events} />
                    <PrivateRoute exact path='/events/details/:id' component={EventDetails} />

                    <PrivateRoute exact path='/offers' component={Offers} />
                    <PrivateRoute exact path='/offers/details/:id' component={OfferDetails} />

                    <PrivateRoute exact path='/bookings' component={Bookings} />
                    <PrivateRoute exact path='/bookings/new' component={BookingNew} />
                    <PrivateRoute exact path='/bookings/:id/message' component={ConversationsBookingsNew} />

                    <PrivateRoute exact path='/messages' component={Conversations} />
                    <PrivateRoute exact path='/messages/new' component={ConversationsNew} />

                    <PrivateRoute exact path='/profile' component={ProfileDetails} />
                    <PrivateRoute exact path='/profile/edit' component={ProfileEdit} />
                    <PrivateRoute exact path='/profile/change-password' component={ChangePassword} />
                    <PrivateRoute exact path='/deliveries' component={Parcels} />

                    <PrivateRoute exact path='/profile/billing' component={Billing} />
                    <PrivateRoute exact path='/profile/credit-cards/new' component={CreditCardNew} />
                    <PrivateRoute exact path='/profile/apartments' component={Apartments} />
                    <PrivateRoute exact path='/profile/apartments/edit/:id' component={ApartmentEdit} />

                    <PrivateRoute exact path='/profile/occupants' component={Occupants} />
                    <PrivateRoute exact path='/profile/occupants/new' component={InvitationsNew} />

                    <PrivateRoute exact path='/profile/pets' component={Pets} />
                    <PrivateRoute exact path='/profile/pets/new' component={PetsNew} />
                    <PrivateRoute exact path='/profile/pets/edit/:id' component={PetsEdit} />

                    <PrivateRoute exact path='/profile/swipe-cards' component={SwipeCards} />
                    <PrivateRoute exact path='/profile/swipe-cards/request' component={SwipeCardsNew} />

                    <PrivateRoute exact path='/profile/notices' component={Notices} />
                    <PrivateRoute exact path='/profile/notices/request' component={NoticesNew} />
                    <PrivateRoute exact path='/profile/notices/details/:id' component={NoticeDetails} />
                    <PrivateRoute exact path='/profile/notices/edit/:id' component={NoticesEdit} />

                    <PrivateRoute exact path='/profile/vehicles' component={Vehicles} />
                    <PrivateRoute exact path='/profile/vehicles/edit/:id' component={VehiclesEdit} />

                    <PrivateRoute exact path='/my-documents/:id/upload' component={UploadDocument} />

                </Layout>
            </userContext.Provider>
        );
    }
}

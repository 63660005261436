import React, { Component } from 'react';
import { Button, Badge, Spinner, Card, CardBody, CardHeader } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { HengyiCreditCardClient, CreditCardQueryParams } from '../../infrastructure/HengyiClient/CreditCards';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { HengyiChargeClient, ChargeQueryParams } from '../../infrastructure/HengyiClient/Charges';
import { HengyiInvoiceClient, InvoiceQueryParams } from '../../infrastructure/HengyiClient/Invoices';

export class InvoicesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async viewOnline(id) {
        var links = await HengyiInvoiceClient.GetLinks(id);

        this.setState({
            currentInvoice: id,
            currentInvoiceLinks: links.data
        });

        return false;
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var response = (await HengyiInvoiceClient.List(new InvoiceQueryParams()
            .WithUser(currentUser.id)
            .Sort("created").Descending()
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <table className='table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th style={{ borderTop: "none" }}>Number</th>
                            <th style={{ borderTop: "none" }}>Amount</th>
                            <th style={{ borderTop: "none" }}>Status</th>
                            <th style={{ borderTop: "none" }}>Created</th>
                            <th style={{ borderTop: "none" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map(item =>
                            <tr key={item.id}>
                                <td>
                                    {item.invoiceNumber && item.invoiceNumber}
                                    {!item.invoiceNumber && <i>Not specified</i>}
                                </td>
                                <td>
                                    <NumberFormat fixedDecimalScale="true" decimalScale="2" value={(item.amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => value} />
                                </td>
                                <td>
                                    {item.status === "draft" && <Badge color="primary">Draft</Badge>}
                                    {item.status === "open" && <Badge color="primary">Open</Badge>}
                                    {item.status === "paid" && <Badge color="success">Paid</Badge>}
                                    {item.status === "uncollectable" && <Badge color="secondary">Uncollectable</Badge>}
                                    {item.status === "void" && <Badge color="secondary">Voided</Badge>}
                                    {item.status === "deleted" && <Badge color="secondary">Deleted</Badge>}
                                </td>
                                <td>
                                    <Moment utc local format="ddd, DD/MM h:mma" date={item.created} />
                                </td>
                                {this.state.currentInvoice === item.id && this.state.currentInvoiceLinks && <td>
                                    <a style={{ padding: "6px", float: "right" }} href={this.state.currentInvoiceLinks.hostedInvoice} className="btn mr-2 btn-outline-dark mt-2 mb-2">View online</a>
                                    <a style={{ padding: "6px", float: "right" }} href={this.state.currentInvoiceLinks.pdfLink} className="btn mr-2 btn-outline-dark mt-2 mb-2">View PDF</a>
                                </td>}
                                {this.state.currentInvoice != item.id && <td>
                                    <Link style={{ padding: "6px", float: "right" }} to="/profile/billing" onClick={(e) => { e.preventDefault(); this.viewOnline(item.id); }} className="btn mr-2 btn-outline-dark mt-2 mb-2">View</Link>
                                </td>}
                            </tr>
                        )}
                        {this.state.data.length === 0 && <tr>
                            <td colSpan="5"><h4 className="text-muted text-center mt-3"><i>No invoices to display</i></h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <Card style={{ marginTop: "40px" }}>
                {this.state.viewInvoiceLink && <Redirect to={this.state.viewInvoiceLink} />}
                <CardHeader>
                    <h2>Invoices
                            {this.state.loading && <Spinner animation="border" />}
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                        <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                    </h2>
                </CardHeader>
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }
}

import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { OffersTable } from './OffersTable';
import { GetAssetDomain } from '../infrastructure/Helper';

export class Offers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        return (

            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Offers" subTitle="Exclusive offers for residents" />

                <Container style={{ "paddingTop": "20px" }}>

                    <OffersTable />

                </Container>
            </div>
        );
    }
}

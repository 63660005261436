import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class PrivacyPolicy extends Component {

    render() {
        return (
            <Container className="privacyPolicy" style={{ "paddingTop": "130px" }}>
                <h1 style={{ "textAlign": "center" }}>The Pacifica Portal Privacy Policy</h1>
                <hr style={{ "paddingBottom": "40px" }} />

                <p>
                    Hengyi Pacific NZ Limited  and its related companies ("<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>") are committed to protecting your privacy. This privacy policy gives you information on how we collect and use your personal information through the Pacifica Portal via the website at <a href="https://mypacifica.nz">https://mypacifica.nz</a> and/or app ("<strong>the Portal</strong>") , which is the portal for the Pacifica tower, located at 10-12 Commerce Street, Auckland, 1010 ("<strong>The Pacifica</strong>"). 
                </p>
                <p> We need to collect, hold, use and disclose your personal information in order to allow you to use and get the full benefits of the Portal and its associated services. This Privacy Policy explains how, when doing that, we comply with our obligations under the Privacy Act 1993, and the new Privacy Act 2020 once it comes into force on 1 December 2020 (both "<strong>the Privacy Act</strong>", as applicable).
                </p>
                <p>You can click through to the specific areas that you are interested in below.</p>

                <ol className="termsClause">
                    <li><a href="/privacy-policy#1">Scope of Privacy Policy</a></li>
                    <li><a href="/privacy-policy#2">The information we collect </a></li>
                    <li><a href="/privacy-policy#3">How we collect information</a></li>
                    <li><a href="/privacy-policy#4">How we use information</a></li>
                    <li><a href="/privacy-policy#5">Disclosures</a></li>
                    <li><a href="/privacy-policy#6">Overseas Transfers</a></li>
                    <li><a href="/privacy-policy#7">Security</a></li>
                    <li><a href="/privacy-policy#8">How long we keep information for</a></li>
                    <li><a href="/privacy-policy#9">Your rights</a></li>
                    <li><a href="/privacy-policy#10">Changes to this Privacy Policy</a></li>
                </ol>

                <ol className="termsClause">
                    <li id="1">
                        <strong>SCOPE OF PRIVACY POLICY</strong>
                        <p>This privacy policy applies to "personal information" as defined in the Privacy Act and includes any information that can be used to identify an individual. This may include names, addresses, telephone numbers, email addresses and professions or occupations. If the information we collect personally identifies an individual or an individual is reasonably identifiable from it, the information will be considered to be personal information.</p>
                        <p>Owners, residential tenants, occupiers, guests, employees, the body corporate management, third party service providers and anyone else who may need to use the Portal in connection with The Pacifica (together "<strong>Users</strong>") may register and use the Portal. We collect personal information from Users when they register and use the Portal. This privacy policy covers how we collect, use, store, process and disclose the personal information collected from Users.</p>
                    </li>
                    <li id="2">
                        <strong>THE INFORMATION WE COLLECT</strong>
                        <p>We may collect the following types of personal information through the Portal: </p>
                        <ul>
                            <li style={{ "padding": "0" }}><strong>Identity information:</strong> including first name, last name, title, date of birth, gender and age of Users;</li>
                            <li style={{ "padding": "0" }}><strong>Contact information:</strong> including billing address, delivery address, email address and telephone numbers of Users;</li>
                            <li style={{ "padding": "0" }}><strong>Property information:</strong> including apartment owned, leased or occupied, real property purchased, rented or leased from others, insurance information, number of occupants and any other information uploaded or entered in the Portal relating to the property;</li>
                            <li style={{ "padding": "0" }}><strong>Payment information:</strong> including bank account and/or payment card details and payment history for payments made through the Portal;</li>
                            <li style={{ "padding": "0" }}><strong>Pet information:</strong> including number and details of any pets (eg one dog or cat) that will be residing in an apartment with its occupants;</li>
                            <li style={{ "padding": "0" }}><strong>Vehicle information:</strong> including vehicle make and registration number for any vehicles that occupants will be parking in The Pacifica carparks;</li>
                            <li style={{ "padding": "0" }}><strong>Valet information:</strong> including car pick up and driver requests for both on site and off site valet parking, EV bookings, payments and usage monitoring;</li>
                            <li style={{ "padding": "0" }}><strong>Guest Management:</strong> including bookings, guest communication, reporting and billing; </li>
                            <li style={{ "padding": "0" }}><strong>Transaction information:</strong> including details of products and services ordered and/or purchased through the Portal;</li>
                            <li style={{ "padding": "0" }}><strong>Visitor information:</strong> including visitor details and access/sign-in information;</li>
                            <li style={{ "padding": "0" }}><strong>Event information:</strong> including details about current, future and past events and Users who attended and/or worked at or contributed to those events;</li>
                            <li style={{ "padding": "0" }}><strong>Employee/staff information:</strong> including name, job title, contact information, work orders;</li>
                            <li style={{ "padding": "0" }}><strong>Identity information:</strong> including first name, last name, title, date of birth, gender and age of Users;</li>
                            <li style={{ "padding": "0" }}><strong>Health and safety information:</strong> including current hazards, incidents, escalation and notifications;</li>
                            <li style={{ "padding": "0" }}><strong>Technical information:</strong> including internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website;</li>
                            <li style={{ "padding": "0" }}><strong>Profile information:</strong> including your username and password, purchases or orders made by you, your interests, subscriptions, preferences, feedback and survey responses;</li>
                            <li style={{ "padding": "0" }}><strong>Usage information:</strong> including information about how you use the Portal and its associated products and services;</li>
                            <li style={{ "padding": "0" }}><strong>Marketing and communications information:</strong> including your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                        </ul>
                        <p>You can choose not to provide your personal information to us, but that may impact on our ability to provide services and products to you and the functionality of the Portal.  </p>
                    </li>

                    <li id="3">
                        <p><strong>HOW WE COLLECT INFORMATION </strong></p>
                        <ul>
                            <li style={{ "padding": "0" }}>We use different methods to collect personal information from and about you:</li>
                            <li style={{ "padding": "0" }}>You may give us your personal information by entering/uploading it to the Portal, submitting requests, filling out forms or by corresponding with us by post, phone, email or otherwise.</li>
                            <li style={{ "padding": "0" }}>As you use and interact on the Portal, we will automatically collect information about your equipment, browsing actions and patterns.  We collect this personal information by using cookies, server logs and other similar technologies.  Please see the 'Cookies' section below for further details about how we use cookies.</li>
                        </ul>
                        <p>We will receive personal information about you from various third parties as set out below: </p>
                        <ul>
                            <li style={{ "padding": "0" }}>From third party service providers that provide services through the Portal including insurance providers, utilities providers, furniture providers, laundry services, retail providers, our EV provider and others;</li>
                            <li style={{ "padding": "0" }}>From financial reporting agencies;</li>
                            <li style={{ "padding": "0" }}>From credit reporting agencies;</li>
                            <li style={{ "padding": "0" }}>From law enforcement agencies and other government entities;</li>
                            <li style={{ "padding": "0" }}>From other employees and contractors that form part of our service network;</li>
                            <li style={{ "padding": "0" }}>From other owners, tenants and/or guests that have provided information about you; and</li>
                            <li style={{ "padding": "0" }}>From our related companies and affiliates that collect information on our behalf.</li>
                        </ul>
                        <p><strong>Cookies</strong></p>
                        <p>We may use cookies and other interactive techniques such as web beacons to collect information about how you use our website to: </p>
                        <ul>
                            <li style={{ "padding": "0" }}>Provide you with a more enjoyable, customised service and experience on the Portal; </li>
                            <li style={{ "padding": "0" }}>Enable you to use certain services on the Portal; </li>
                            <li style={{ "padding": "0" }}>Help us develop and deliver better services and products which are customised to Users' interests; and </li>
                            <li style={{ "padding": "0" }}>Help us manage security and fraud risks.</li>
                        </ul>
                        <p>We may also use persistent cookies so that you can set your preferences for using the Portal (for example, to remember your log in details).  </p>
                        <p>You can usually set the settings on your browser to let you know when you are offered cookies so that you can decide whether or not to accept them.  However, if you disable or refuse cookies, please be aware that some parts of this website may not function properly.</p>

                        <p><strong>Third-Party Links</strong></p>
                        <p>The Portal may include links to third-party websites, plug-ins and applications.  Clicking on those links or enabling those connections may allow third parties to collect or share data about you.  We do not control these third-party websites and are not responsible for how they may use your personal information.</p>
                    </li>

                    <li id="4">
                        <p><strong>HOW WE USE INFORMATION  </strong></p>
                        <p>We will use your personal information to: </p>
                        <ul>
                            <li style={{ "padding": "0" }}>Conduct identity verification and background checks for screening potential tenants and guests, for building security and for access approvals;</li>
                            <li style={{ "padding": "0" }}>Maintain your account and provide you with products and services, to facilitate service requests/orders (eg for a plumber, electrician, cleaners, home service, cleaning service or food delivery) and to inform or update you of status, and/or changes to the products or services you have requested/ordered;</li>
                            <li style={{ "padding": "0" }}>Notify employees and contractors of service/work requests;</li>
                            <li style={{ "padding": "0" }}>Communicate with you and facilitate communications between owners, residential tenants, guests, employees and/or contractors;</li>
                            <li style={{ "padding": "0" }}>Process booking requests;</li>
                            <li style={{ "padding": "0" }}>Manage courier deliveries;</li>
                            <li style={{ "padding": "0" }}>Process payments and keep records of such payments for accounting purposes; </li>
                            <li style={{ "padding": "0" }}>Answer enquiries and provide information or advice about an apartment or existing and new products and services offered through the Portal;</li>
                            <li style={{ "padding": "0" }}>Assist with management of amenities and utilities, eg by tracking availability and usage;</li>
                            <li style={{ "padding": "0" }}>Manage security issues and notification of security issues;</li>
                            <li style={{ "padding": "0" }}>Comply with our legal, regulatory, tax and/or accounting requirements; </li>
                            <li style={{ "padding": "0" }}>Continually improve the Portal and the services and products provided through it; </li>
                            <li style={{ "padding": "0" }}>Assist with any queries, requests or complaints that you may have;</li>
                            <li style={{ "padding": "0" }}>Contact you about promotions, special offers and new services and products which we or our third party services providers provide that we think may be of interest to you. You may contact us at any time using the details at the end of this Privacy Policy to request that you no longer receive marketing material from us; and</li>
                            <li style={{ "padding": "0" }}>Carry out marketing and product analysis.</li>
                        </ul>
                    </li>

                    <li id="5">
                        <p><strong>DISCLOSURES</strong></p>
                        <p>We may need to share your personal information with: </p>
                        <ul>
                            <li style={{ "padding": "0" }}>Third party service providers and suppliers that provide products and services on our behalf or as part of our service network; </li>
                            <li style={{ "padding": "0" }}>Related companies and affiliates who may need to use your personal information for the same purposes as us; </li>
                            <li style={{ "padding": "0" }}>Property owners and property managers where prospective tenants apply to lease an apartment or existing tenants put service requests in through the Portal that need to be approved by the applicable owner or property manager;</li>
                            <li style={{ "padding": "0" }}>Property owners, property managers and accommodation booking agents where prospective guests may apply to book serviced accommodation in an apartment or guests put service requests in through the Portal that need to be approved by the applicable owner or property manager;</li>
                            <li style={{ "padding": "0" }}>Third parties involved in helping us improve the Portal and its services. For this purpose, we will use aggregated, statistical data from and about Users and will not disclose information which identifies you;</li>
                            <li style={{ "padding": "0" }}>Credit reference agencies;</li>
                            <li style={{ "padding": "0" }}>Debt collection agencies to collect any money that you may owe us;</li>
                            <li style={{ "padding": "0" }}>Potential purchasers of our business in the event we are involved in a potential merger, reorganisation, acquisition or sale of all or a portion of our assets, or other corporate transaction; and </li>
                            <li style={{ "padding": "0" }}>Anyone who we need to share information with by reason of law or to address any complaints you make or are made about your information to a third party or regulator (such as the Privacy Commissioner).   </li>
                        </ul>
                        <p>Information is only disclosed to these organisations for the purposes set out in, and in accordance with, this Privacy Policy. We take reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.</p>
                    </li>

                    <li id="6">
                        <p><strong>OVERSEAS TRANSFERS</strong></p>
                        <p>In some cases, we may also need to share your personal information with third parties outside of New Zealand.  Personal information that you provide to us may be stored by organisations outside of New Zealand. For example, we use Microsoft Azure data centres in Australia to host and store data (including personal information).</p>
                        <p>Prior to transferring any personal information overseas, we do our best to ensure that it will be protected by safeguards that are comparable to those provided under New Zealand privacy laws, or will otherwise be protected to a comparable degree by those overseas service providers or companies (eg, by contractual obligation). In each case, personal information will only be used in a manner that is consistent with this Privacy Policy.  </p>
                    </li>

                    <li id="7">
                        <p><strong>SECURITY</strong></p>
                        <p>Information transmitted over the internet is not always secure, but we will use reasonable security safeguards to protect information we hold against loss, access, use, modification and unauthorised disclosure.   We have procedures to deal with any suspected personal information breaches and will notify you and the Privacy Commissioner where there is a notifiable privacy breach under the Privacy Act.</p>
                    </li>

                    <li id="8">
                        <p><strong>HOW LONG WE KEEP INFORMATION FOR</strong></p>
                        <p>We will only retain your personal information for as long as we have a legitimate business need to do so (in connection with the purposes set out above). Where we no longer have an ongoing legitimate business need to retain and store your personal information, we will either delete or anonymise it, or, if this is not possible (for example because such information has been stored in backup archives), then we will securely store your personal information and isolate it from further access until deletion is possible. We will de-identify and aggregate information wherever possible.</p>
                    </li>

                    <li id="9">
                        <p><strong>YOUR RIGHTS</strong></p>
                        <p>You have rights to see and request the correction of any personal information we hold about you.  If you want to exercise these rights or to ask us questions about this privacy policy, please contact us using the following details: </p>
                        <p>Hengyi Pacific NZ Limited</p>
                        <p>Email address: info@mypacifica.nz</p>
                        <p>This privacy policy is governed by New Zealand law.  For more information about privacy issues in New Zealand and protecting your privacy, visit the New Zealand Privacy Commissioner's website.</p>
                    </li>

                    <li id="10">
                        <p><strong>CHANGES TO THIS PRIVACY POLICY</strong></p>
                        <p>We keep our privacy policy under regular review and will provide notice of changes to this privacy policy on the Portal. </p>
                        <p>This privacy policy was last updated on 15th September 2020.</p>
                    </li>
                </ol>
            </Container>
        );
    }
}

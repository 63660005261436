import { ApiResponse, ListQueryParams, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class UsersQueryParams extends HengyiListQueryParams {
    
}

export class HengyiUserClient {

    static async List(params: UsersQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));
    }

    static async ClearTempPassword(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id +"/clear-temp-password", {
            method: 'post',
            headers: GetAPIHeaders()
        }));
    }
    static async SetTempPassword(id: string, tempPassword: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id +"/set-temp-password", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                tempPassword: tempPassword
            })
        }));
    }

    static async SetStatus(id: string, status: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/"+id, {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                status: status
            })
        }));
    }

    static async UpdateUser(id: string, name: string, surname: string, email: string, mobile: string, imageId?: string,
        preferredParcelDeliveryOption?: string, preferredParcelDeliverySlot?: string, pushNotificationsEnabled?: Boolean) {

        var updates = [{
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
                "path": "surname",
                "value": surname
        }, {
            "op": "replace",
                "path": "email",
                "value": email
        }, {
            "op": "replace",
                "path": "mobile",
                "value": mobile
            }, {
                "op": "replace",
                "path": "profileImageId",
                "value": imageId
            }, {
                "op": "replace",
                "path": "preferredParcelDeliveryOption",
                "value": preferredParcelDeliveryOption
            }, {
                "op": "replace",
                "path": "preferredParcelDeliverySlot",
                "value": preferredParcelDeliverySlot
            }, {
                "op": "replace",
                "path": "pushNotificationsEnabled",
                "value": pushNotificationsEnabled
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }
}
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiPetClient } from '../../infrastructure/HengyiClient/Pets';
import { GetAPIDomain, GetStripePublicKey } from '../../infrastructure/Helper';
import { SelectField } from '../../infrastructure/SelectField';
import DatePicker from 'react-datepicker';
import { loadStripe } from '@stripe/stripe-js';
import {
    ElementsConsumer,
    CardElement,
    Elements
} from '@stripe/react-stripe-js';
import { HengyiCreditCardClient } from '../../infrastructure/HengyiClient/CreditCards';

const stripePromise = loadStripe(GetStripePublicKey());

export class CreditCardNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async fetchData() {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var currentUser = await HengyiApi.GetCurrentUser();

            this.setState({
                init: true,
                userOptions: [],
                loading: false,
                userId: currentUser.id
            });
        }
    }

    async handleSubmit(event, elements, stripe) {

        this.setState({ saving: true });

        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        await HengyiCreditCardClient.Create(paymentMethod.id);

        this.setState({
            next: true
        });
    };

    renderForm() {
        return (
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <form onSubmit={event => this.handleSubmit(event, elements, stripe)}>
                            <h6>Card details</h6>
                            <CardElement options={CARD_OPTIONS} />
                            <div className="pt-4">
                                <button type="submit" disabled={!stripe} className="btn btn-outline-dark">
                                    {this.state.saving && <Spinner animation="border" />}
                                    {!this.state.saving && "Save card details"}
                                </button>
                            </div>
                        </form>
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/profile/billing"} />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Add credit card" subTitle="Register a new credit card to settle your acount automatically" />

                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Add credit card
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Credit Card details secured by <a target="_blank" href="https://stripe.com/">Stripe Payments</a></span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right" }} to="/profile/billing" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }
}

const CARD_OPTIONS = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: {
        base: {
            padding: '10px 12px',
            color: '#32325d',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            },
        },
        invalid: {
            color: '#fa755a',
        }
    },
};
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Container } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { GetAssetDomain } from '../infrastructure/Helper';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';

export class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [], loading: false, authenticated: true, success: false
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });
        this.setState({
            data: await HengyiApi.GetCurrentUser(),
            actions: await HengyiApi.GetCurrentUserActions(),
            loading: false
        });
    }

    renderForm() {
        return (
            <Formik
                initialValues={{
                    existingPassword: "",
                    newPassword: ""
                }}

                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    var response = await HengyiApi.SetUserPassword(fields.existingPassword, fields.newPassword);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ success: true, id: response.data.id });
                    }

                }}>
                {({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                    <Form>
                        <Row>
                            <Col>

                                <div className="form-group">
                                    <label htmlFor="existingPassword">Existing password</label>
                                    <Field name="existingPassword" type="password" className={'form-control' + (errors.existingPassword && touched.existingPassword ? ' is-invalid' : '')} />
                                    <ErrorMessage name="existingPassword" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPassword">New password</label>
                                    <Field name="newPassword" type="password" className={'form-control' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')} />
                                    <ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                        </Row>

                        <div className="form-group mt-3">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                {!isSubmitting && "SAVE"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    render() {

        if (this.state.success === true) {

            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Change your password" subTitle="Choose a new password" />

                    <Container style={{ "paddingTop": "20px" }}>

                        <div className="alert alert-success">
                            <p className="mt-2"><strong>Password changed</strong>. Please use your new password from now on.</p>
                            <p className="mb-2"><Link to="/profile" className="btn mr-2 mt-2 mb-2 btn-success">Continue to my profile</Link><Link to="/" className="btn mr-2 mt-2 mb-2 btn-success">Continue to dashboard</Link></p>
                        </div>

                    </Container>
                </div>);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Change your password" subTitle="Choose a new password" />

                    <Container style={{ "paddingTop": "20px" }}>

                        {this.state.actions && this.state.actions.requireTempPasswordReset &&
                            <div className="alert alert-danger">
                                <p className="mt-2"><strong>Secure your account</strong>. You have a temporary password set, this is only to be used to help set up your account.</p>
                                <p className="mb-2">Please choose a new secure permanent password.</p>
                            </div>}

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <h5> Change password
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                        </h5>
                                        <span className="d-block m-t-5 text-muted">Use the form below to choose a new password</span>
                                    </Col>
                                    <Col>
                                        <div style={{ paddingRight: "10px" }}>
                                            <Link style={{ padding: "6px", float: "right" }} to="/profile" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            );
        }
    }
}

import React, { Component } from 'react';
import { Button, Badge, Spinner, Card, CardBody, CardHeader, Row, Col, InputGroup, Input, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';
import { PartnerOfferClient, PartnerOfferQueryParams } from '../infrastructure/HengyiClient/PartnerOffers';
import { PartnerOfferCategorySelector } from '../infrastructure/PartnerOfferCategorySelector';

export class OffersTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: "", categoryId: "", showExpired:false
        };
        this.handleCategoryIdChange = this.handleCategoryIdChange.bind(this);
        this.handleShowExpired = this.handleShowExpired.bind(this);
    }

    handleShowExpired = (evt) => {
        this.setState({
            showExpired: evt.target.checked,
            skip: 0
        }, () => this.fetchData());
    }

    handleCategoryIdChange(name, value) {
        this.setState({
            categoryId: value
        }, () => this.fetchData());
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async clear() {

        this.setState({
            status: "",
            search: "",
            categoryId: "",
            skip: 0
        }, () => this.fetchData());

    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var params = new PartnerOfferQueryParams()
            .Sort("weigth")
            .Ascending();

        if (this.state.categoryId) {
            params.WithParam("categoryId", this.state.categoryId.value);
        }

        if (this.state.showExpired) {
            params.WithParam("includeExpiredOffers", this.state.showExpired);
        }

        var response = (await PartnerOfferClient.List(params.Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {

        const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

        return (
            <div>

                {array_chunks(this.state.data, 3).map((chunk, index) =>

                    <Row key={index} className="mt-4">

                        {chunk.map((item, innerIndex) =>
                            <Col key={index + "-" + innerIndex} md="4">

                                <Link to={"/offers/details/" + item.id } className="forEvent">
                                    <Card>
                                        {item.imageId && <CardHeader style={{ "minHeight": "150px", position: "relative", "backgroundPosition": "center", "backgroundSize": "cover", "backgroundImage": "url('" + this.state.assetUrl + "/image/" + item.imageId + ".jpg')" }}>
                                            {!item.seen && <Badge color="success">New</Badge>}
                                            <Badge style={{ position: "absolute", bottom: "15px", left:"20px" }} color="primary">{item.partnerOfferCategory.name}</Badge>
                                        </CardHeader>}
                                        {!item.imageId && <CardHeader style={{ "minHeight": "150px", "backgroundPosition": "center", "backgroundSize": "cover", "backgroundImage": "url('/img/missing-image.png')" }}>
                                            {!item.seen && <Badge color="success">New</Badge>}
                                            <Badge style={{ position: "absolute", bottom: "15px", left: "20px"}} color="primary">{item.partnerOfferCategory.name}</Badge>
                                        </CardHeader>}
                                        <CardBody>
                                            <h5>{item.name}</h5>
                                            <p style={{ "height": "50px", "overflowY": "hidden" }}>{item.description}</p>
                                            <i>Exipry: <Moment utc local format="ddd, DD/MM h:mma" date={item.expiry} /></i>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>)}

                    </Row>
                )}
            </div>);
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }}>

                <Card style={{marginBottom: "30px"}}>
                    <CardHeader>
                        <h2>Offers
                            {this.state.loading && <Spinner animation="border" />}
                            <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark" >Next</button>
                            <button style={{ padding: "6px", float: "right", marginTop: "5px" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark" >Prev</button>
                        </h2>
                    </CardHeader>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5 style={{ position: "relative", top: "5px" }}>Filter</h5>
                            </Col>
                            <Col>
                                <PartnerOfferCategorySelector
                                    placeholder="Category"
                                    value={this.state.categoryId}
                                    onChange={this.handleCategoryIdChange}
                                    hideLabel={true} />
                            </Col>
                            <ButtonGroup>
                                {(this.state.categoryId) &&
                                    <button style={{ padding: "6px", marginRight: "24px" }} onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                        <span className="pcoded-micon">Clear</span>
                                    </button>}
                            </ButtonGroup>

                        </Row>
                    </CardHeader>

                    <CardHeader>
                        <div className="form-group">
                            <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                <li className="list-inline-item">
                                    <label style={{ margin: "0" }}>
                                        <input name="showExpired"
                                            type="checkbox"
                                            checked={this.state.showExpired}
                                            onChange={this.handleShowExpired} />  Show expired
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </CardHeader>

                </Card>

               

                {this.state.data && this.state.data.length > 0 && this.renderTable()}
                {this.state.data && this.state.data.length == 0 && <h5 className="text-center"><i>No offers available</i></h5>}
            </div>
        );
    }
}

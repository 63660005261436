import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ApartmentQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithAccessibility(value: Boolean) {
        this.WithParam("hasAccessibilityRequirements", value);
        return this;
    }
}



export class HengyiApartmentClient {

    static async List(params: ApartmentQueryParams) {

        var query = (await GetAPIDomain()) + "/apartments" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async AddUser(id: string, userId: string, relationship: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id + "/add-user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId,
                "relationship": relationship
            })
        }));
    }

    static async RemoveUser(id: string, userId: string, relationship: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id + "/remove-user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "userId": userId
            })
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async UpdateAccessibilityRequirements(id: string, hasAccessibilityRequirements?: Boolean, accessibilityRequirement?: string,
        accessibilityOrientationRequired?: Boolean, accessibilityMedicalRequired?: string) {

        var updates = [{
            "op": "replace",
            "path": "hasAccessibilityRequirements",
            "value": hasAccessibilityRequirements
        }, {
            "op": "replace",
            "path": "accessibilityRequirement",
            "value": accessibilityRequirement
        }, {
            "op": "replace",
            "path": "accessibilityOrientationRequired",
            "value": accessibilityOrientationRequired
        }, {
            "op": "replace",
            "path": "accessibilityMedicalRequired",
            "value": accessibilityMedicalRequired
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));

    }

    static async Update(id: string,
        name: string, description: string, 
        lettingAgentName?: string, lettingAgentBusiness?: string, lettingAgentMobile?: string, lettingAgentEmail?: string) {

        var updates = [{
            "op": "replace",
            "path": "description",
            "value": description
        }, {
            "op": "replace",
            "path": "name",
            "value": name
        }, {
            "op": "replace",
            "path": "lettingAgentName",
            "value": lettingAgentName
        }, {
            "op": "replace",
            "path": "lettingAgentBusiness",
            "value": lettingAgentBusiness
        }, {
            "op": "replace",
            "path": "lettingAgentMobile",
            "value": lettingAgentMobile
        }, {
            "op": "replace",
            "path": "lettingAgentEmail",
            "value": lettingAgentEmail
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/apartments/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }
}
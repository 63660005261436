import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from './infrastructure/fetchHengy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faIdCardAlt, faBuilding, faUserFriends, faTicketAlt, faPaw, faCreditCard, faAddressCard, faCar, faBatteryThreeQuarters, faBox, faCalendar, faClipboardCheck, faFilePdf, faInfoCircle, faExclamationCircle, faExclamationTriangle, faCalendarAlt, faBoxes, faFile, faEnvelope, faTag } from '@fortawesome/free-solid-svg-icons'
import { HengyiNoticeClient, NoticeQueryParams } from './infrastructure/HengyiClient/Notices';
import { HengyiMessageThreadClient, MessageThreadQueryParams } from './infrastructure/HengyiClient/MessageThreads';
import { HengyiEVChargingSessionClient, EVChargingSessionQueryParams } from './infrastructure/HengyiClient/EVChargingSessions';
import { HengyiParcelClient } from './infrastructure/HengyiClient/Parcels';
import { ParcelQueryParams } from './infrastructure/HengyiClient/Parcels';
import Moment from 'react-moment';
import { PartnerOfferClient, PartnerOfferQueryParams } from './infrastructure/HengyiClient/PartnerOffers';
import { GetAssetDomain } from './infrastructure/Helper';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { documents: "", authenticated: true, newFileCount: 0, modal: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async toggle() {
        this.setState({ modal: !this.state.modal })
    }

    async fetchData() {

        var currentUser = await HengyiApi.GetCurrentUser();

        if (currentUser) {

            var actions = await HengyiApi.GetCurrentUserActions();

            this.setState({
                name: currentUser.name,
                actions: actions
            });

            var fileResponse = await HengyiApi.GetNewFileCount();
            if (fileResponse.successful) {
                this.setState({
                    newFileCount: fileResponse.data
                });
            }

            var notices = await HengyiNoticeClient.List(new NoticeQueryParams()
                .WithStatus("approved")
                .WithHideArchived(true)
                .WithHideExpired(true)
                .Sort("created")
                .Descending()
                .Paginate(0, 5));
            if (notices.successful) {
                this.setState({
                    notices: notices.data.data
                });
            }

            var messageResponse = await HengyiMessageThreadClient.List(new MessageThreadQueryParams()
                .WithUser(currentUser.id)
                .WithUnreadForResident()
                .Paginate(0, 1));

            if (messageResponse.successful) {
                this.setState({
                    numberOfUnreadThreads: messageResponse.data.total,
                });
            }

            var sessions = await HengyiEVChargingSessionClient.List(new EVChargingSessionQueryParams()
                .WithUser(currentUser.id)
                .WithHideCompleted("true")
                .WithHideCancelled("true")
                .Sort("created").Descending()
                .Paginate(0, 1));

            if (sessions.successful)
                this.setState({
                    evChargeCount: sessions.data.total
                });



            var parcels = await HengyiParcelClient.List(new ParcelQueryParams()
                .WithUser(currentUser.id)
                .WithPreferenceInidcation(false)
                .WithHideCollected(true)
                .Sort("created").Descending()
                .Paginate(0, 1));

            if (parcels.successful)
                this.setState({
                    parcelCount: parcels.data.total
                });


            var newOfferCount = await PartnerOfferClient.List(new PartnerOfferQueryParams()
                .WithNewOffers()
                .Paginate(0, 1))

            if (newOfferCount.successful)
                if (newOfferCount && newOfferCount.data) {
                    this.setState({
                        newOfferCount: newOfferCount.data.total
                    });
                }

        } else {
            this.setState({ authenticated: false });
        }


    }

    render() {

        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (<div>
                <userContext.Consumer>
                    {({ user, logoutUser, features }) => {

                        return (<div>

                            <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle={'Welcome Home' + (this.state.name ? ', ' + this.state.name : '')} subTitle="My Residential Portal" />


                            {!this.state.actions && <Container style={{ "paddingTop": "20px", textAlign: "center" }}><Spinner animation="border" /></Container>}

                            {this.state.actions && <Container style={{ "paddingTop": "20px" }}>

                                <Row>
                                    <Col>

                                        <div id="alerts">

                                            {!this.state.actions.apartmentSettled &&
                                                <div className="alert alert-info">
                                                    <h6>Settlement not complete</h6>
                                                    <p className="mt-2 mb-2">You currently have limited access to the portal, once settlement is complete you will gain full access.</p>
                                                </div>
                                            }

                                            {features && features.includes("facilities") && features.includes("bookings") && this.state.actions.apartmentSettled && !this.state.actions.hasUsedTheLiftFacility && <div className="alert alert-info">
                                                <h6><FontAwesomeIcon icon={faBoxes} /> Book the service lift</h6>
                                                <p className="mt-2 mb-2">Book the service lift to ensure availability on your preferred move in time</p>
                                                <Link className="btn btn-info btn-sm" to={"/bookings/new?facilityId=" + this.state.actions.liftFacilityId}>Book service lift</Link>
                                            </div>}
                                            {features && features.includes("inductions") && this.state.actions.apartmentSettled && this.state.actions.inductionEnabled && <div>

                                                {this.state.actions.inductionStatus === "notComplete" &&
                                                    <div className="alert alert-warning">
                                                        <p className="mt-2 mb-2"><strong>Please complete the building induction</strong></p>
                                                        <p className="mb-4">Welcome, review the induction document to famailarise yourself with the building.</p>
                                                        <Link to="/induction/complete" className="btn mr-2 mt-2 mb-2 btn-warning">Complete induction</Link>
                                                    </div>
                                                }

                                                {this.state.actions.inductionStatus === "outOfDate" &&
                                                    <div className="alert alert-info">
                                                        <p className="mt-2 mb-2"><strong>We've updated the induction document</strong></p>
                                                        <p className="mb-4">Please review the updated induction document to stay informed on changes to building policy and procedure.</p>
                                                        <Link to="/induction/complete" className="btn mr-2 mt-2 mb-2 btn-info">Review updated document</Link>
                                                    </div>
                                                }

                                            </div>}

                                        </div>

                                        <Row>

                                            {features && features.includes("conversations") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/messages">
                                                        <CardBody>
                                                            <div style={{ display: "inline-block", position: "relative" }}>
                                                                <FontAwesomeIcon icon={faEnvelope} size="3x" style={{ marginTop: "15px", marginBottom: "15px" }} />
                                                                {this.state.numberOfUnreadThreads > 0 && <Badge style={{ position: "absolute", top: "12px", right: "-8px" }} color="danger">{this.state.numberOfUnreadThreads}</Badge>}
                                                            </div>
                                                            <h5 className="text-center no-decoration text-dark">
                                                                Messages
                                                            </h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}

                                            {features && features.includes("parcels") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/deliveries">
                                                        <CardBody>
                                                            <div style={{ display: "inline-block", position: "relative" }}>
                                                                <FontAwesomeIcon icon={faBox} size="3x" style={{ marginTop: "15px", marginBottom: "15px" }} />
                                                                {this.state.parcelCount > 0 && <Badge style={{ position: "absolute", top: "12px", right: "-8px" }} color="danger">{this.state.parcelCount}</Badge>}
                                                            </div>
                                                            <h5 className="text-center no-decoration text-dark">Deliveries</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}

                                            {features && features.includes("tickets") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/requests">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faClipboardCheck} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Report</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                        </Row>

                                        <Row style={{ paddingTop: "30px" }}>


                                            {features && features.includes("valet") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/valet">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faTicketAlt} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Valet</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}

                                            {features && features.includes("bookings") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/bookings">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Bookings</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}

                                            {features && features.includes("documents") && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/documents/home">
                                                        <CardBody>
                                                            <div style={{ display: "inline-block", position: "relative" }}>
                                                                <FontAwesomeIcon icon={faFile} size="3x" style={{ marginTop: "15px", marginBottom: "15px" }} />
                                                                {this.state.newFileCount > 0 && <Badge style={{ position: "absolute", top: "5px", right: "-8px" }} color="danger">{this.state.newFileCount}</Badge>}
                                                            </div>
                                                            <h5 className="text-center no-decoration text-dark">Documents</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                        </Row>

                                        <Row style={{ paddingTop: "30px" }}>
                                            {features && features.includes("partner-offers") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/offers">
                                                        <CardBody>

                                                            <div style={{ display: "inline-block", position: "relative" }}>
                                                                <FontAwesomeIcon icon={faTag} size="3x" style={{ marginTop: "15px", marginBottom: "15px" }} />
                                                                {this.state.newOfferCount > 0 && <Badge style={{ position: "absolute", top: "5px", right: "-8px" }} color="danger">{this.state.newOfferCount}</Badge>}
                                                            </div>

                                                            <h5 className="text-center no-decoration text-dark">Offers</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}


                                            {features && features.includes("events") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/events">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faCalendar} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Events</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                            {features && features.includes("ev-charging") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/ev-charging-sessions">
                                                        <CardBody>

                                                            <div style={{ display: "inline-block", position: "relative" }}>
                                                                <FontAwesomeIcon icon={faBatteryThreeQuarters} size="3x" style={{ marginTop: "15px", marginBottom: "15px" }} />
                                                                {this.state.evChargeCount > 0 && <Badge style={{ position: "absolute", top: "5px", right: "-8px" }} color="danger">{this.state.evChargeCount}</Badge>}
                                                            </div>

                                                            <h5 className="text-center no-decoration text-dark">EV Charging</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                        </Row>

                                        <h3 style={{ paddingTop: "30px" }}>My profile</h3>
                                        <hr />

                                        <Row>
                                            <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faAddressCard} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">My details</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>
                                            {features && features.includes("billing") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/billing">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faCreditCard} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Billing</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                            {features && features.includes("pets") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/pets">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faPaw} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Pets</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}

                                            {features && features.includes("vehicles") && this.state.actions.apartmentSettled && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/vehicles">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faCar} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Vehicles</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                        </Row>

                                        {this.state.actions.apartmentSettled && <Row style={{ paddingTop: "30px" }}>
                                            <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/occupants">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faUserFriends} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Occupants</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/apartments">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faBuilding} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Spaces</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>
                                            {features && features.includes("swipe-cards") && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/swipe-cards">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faIdCardAlt} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Swipe cards</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                            {features && features.includes("notices") && <Col>
                                                <Card>
                                                    <Link className="text-center no-decoration text-dark link-hover" to="/profile/notices">
                                                        <CardBody>
                                                            <FontAwesomeIcon icon={faBookmark} size="3x" style={{ margin: "15px" }} />
                                                            <h5 className="text-center no-decoration text-dark">Notices</h5>
                                                        </CardBody>
                                                    </Link>
                                                </Card>
                                            </Col>}
                                        </Row>}

                                    </Col>
                                    <Col md="3">
                                        {features && features.includes("conversations") && this.state.actions && this.state.actions.apartmentSettled && <div>
                                            {this.state.numberOfUnreadThreads > 0 && <div className="alert alert-info">
                                                <h6><FontAwesomeIcon icon={faEnvelope} /> Messages</h6>
                                                <p className="mt-2 mb-2">There {this.state.numberOfUnreadThreads != 1 && "are"} {this.state.numberOfUnreadThreads == 1 && "is"} <strong>{this.state.numberOfUnreadThreads}</strong> new message{this.state.numberOfUnreadThreads != 1 && "s"} to review</p>
                                                <Link className="btn btn-info btn-sm" to="/messages">View messages</Link>
                                            </div>}

                                            {this.state.numberOfUnreadThreads <= 0 && <div className="alert alert-secondary">
                                                <h6><FontAwesomeIcon icon={faEnvelope} /> Messages</h6>
                                                <p className="mt-2 mb-2">There are no new messages</p>
                                                <Link className="btn btn-secondary btn-sm" to="/messages">View messages</Link>
                                            </div>}
                                        </div>}

                                        {features && features.includes("documents") && this.state.newFileCount > 0 &&
                                            <div className="alert alert-info">
                                                <h6><FontAwesomeIcon icon={faFilePdf} /> Documents</h6>
                                                <p className="mt-2 mb-2">There {this.state.newFileCount != 1 && "are"} {this.state.newFileCount == 1 && "is"} <strong>{this.state.newFileCount}</strong> new file{this.state.newFileCount != 1 && "s"} available</p>
                                                <Link className="btn btn-info btn-sm" to="/documents/home">View documents</Link>
                                            </div>}

                                        {features && features.includes("notices") && this.state.notices && this.state.notices.length > 0 &&
                                            <div className="alert alert-info">
                                                <h6><FontAwesomeIcon icon={faInfoCircle} /> Notices</h6>
                                                <hr />
                                                <ul style={{ padding: "0" }}>
                                                    {this.state.notices && this.state.notices.map(notice => {

                                                        return (
                                                            <li style={{ listStyle: "none" }}><a className="list-link" href="#" onClick={(event) => {
                                                                this.setState({
                                                                    "noticeMessage": notice.description, "noticeTitle": notice.title, "modal": true
                                                                });
                                                                event.preventDefault();
                                                                return false;
                                                            }}>{notice.title}</a><sub>
                                                                    <Moment style={{ display: "block", marginTop: "3px" }} utc local format="ddd, DD/MM" date={notice.created} />
                                                                </sub><hr /></li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        }

                                    </Col>
                                </Row>


                            </Container>}
                        </div>)
                    }}
                </userContext.Consumer>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={() => this.toggle()}> {this.state.noticeTitle}</ModalHeader>
                    <ModalBody>
                        {this.state.noticeMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.toggle("")}>Ok</Button>
                    </ModalFooter>
                </Modal>

            </div>);
        }
    }
}

Home.contextType = userContext;
import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, TicketTypeQueryParams } from '../../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { GetAPIHeaders, GetAPIDomain } from '../../infrastructure/Helper';
import { SelectField } from "../../infrastructure/SelectField";
import { HengyiFloorClient, FloorQueryParams } from '../../infrastructure/HengyiClient/Floors';
import { HengyiApartmentClient } from '../../infrastructure/HengyiClient/Apartments';
import { ApartmentQueryParams } from '../../infrastructure/HengyiClient/Apartments';
import { GetAssetDomain } from '../../infrastructure/Helper';

function required(value) {
    if (!value) {
        return 'This is a required field';
    }
}

export class SwipeCardsNew extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], init: false, loading: false, authenticated: true, next: false, id: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.populateData();
    }

    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var user = await HengyiApi.GetCurrentUser();

            var apartmentResponse = await HengyiApartmentClient.List(new ApartmentQueryParams()
                .WithUser(user.id)
                .Sort("name")
                .Paginate(0, 50));

            this.setState({
                init: true,
                loading: false,
                apartmentOptions: apartmentResponse.data.data.map(function (item) { return { value: item.id, label: item.name }; }),
                apartments: apartmentResponse.data.data,
            });
        }
    }

    renderForm() {

        return (<Formik
            initialValues={{
                apartmentId: this.state.apartmentOptions[0],
                description: "",
                location: "Space Access"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.apartmentId) {
                    setFieldError("apartmentId", 'This is a required field');
                    return;
                }

                var actions = await HengyiApi.GetCurrentUserActions();
                var apartment = this.state.apartments.filter(a => a.id == fields.apartmentId.value)[0];

                const params = {
                    ticketTypeId: actions.lostCardTicketTypeId,
                    floorId: apartment.floor.id,
                    description: fields.description,
                    apartmentId: apartment.id,
                    location: fields.location
                };

                const response = await fetch((await GetAPIDomain()) + "/tickets", {
                    method: 'post',
                    headers: GetAPIHeaders(),
                    body: JSON.stringify(params)
                });

                const data = await response.json();

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {


                        if (data) {
                            data.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }
                    }

                } else {

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        await fetch((await GetAPIDomain()) + "/tickets/" + data.id + "/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });
                    }

                    this.setState({ next: true });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                <div className="form-group">
                    <SelectField
                        id="apartmentId"
                        name="apartmentId"
                        label="Floor"
                        placeholder="Space"
                        options={this.state.apartmentOptions}
                        value={values.apartmentId}
                        isMulti={false}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        touched={touched.apartmentId}
                        error={errors.apartmentId}
                        isClearable={true}
                        backspaceRemovesValue={true}
                    />
                    <ErrorMessage name="apartmentId" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">

                    <label htmlFor="location">Card type</label>
                    <select type="select" name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')}>
                        <option value="EV Charging Fob">EV Charging Fob</option>
                        <option value="Apartment Access">Space Access</option>
                    </select>

                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <Field name="description" type="text" component="textarea" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "REQUEST SWIPE CARD"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/requests" />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Requests" subTitle="Lodge a new swipe card request" />


                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <h2>New swipe card request
                                    <Link to="/requests" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                                </h2>
                            </CardHeader>
                            <CardBody>
                                {this.state.init && this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}

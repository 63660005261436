import React, { Component } from 'react';
import { Button, Badge, Spinner, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { HengyiEventClient, EventQueryParams } from '../infrastructure/HengyiClient/Events';

export class UpcomingEventsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            data: [],
            id: ""
        };
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        var response = (await HengyiEventClient.Feed(new EventQueryParams()
            .WithUser(currentUser.id)
            .WithStart(new Date())
            .Paginate(this.state.skip, this.state.take)));

        this.setState({
            loading: false,
            data: response.data.data,
            total: response.data.total
        });
    }

    renderTable() {

        const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

        const group_by_date = function (collection) {
            var i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {

                val = new Date(collection[i].start).toLocaleDateString();
                index = values.indexOf(val);
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };

        return (
            <div>
                {group_by_date(this.state.data).map((events, eventIndex) => 

                    <div key={"date-group-" + events[0].start}>

                        <h6 className="mt-4 mb-0" ><Moment utc local format="dddd, MMMM DD" date={events[0].start}/></h6>
                        <hr />
                        {array_chunks(events, 3).map((chunk, index) =>

                            <Row key={index} className="mt-4">

                                {chunk.map((item, innerIndex) =>
                                    <Col key={index + "-" + innerIndex} md="4">

                                        <Link to={"/events/details/" + item.event.id + "?date=" + new Date(item.start).toISOString()} className="forEvent">
                                            <Card>
                                                {item.event.backgroundImageId && <CardHeader style={{ "minHeight": "150px", "backgroundPosition": "center", "backgroundSize": "cover", "backgroundImage": "url('" + this.state.assetUrl + "/image/" + item.event.backgroundImageId + ".jpg')" }}>
                                                    {item.booking && <Badge color="success">Booked</Badge>}
                                                    {item.bookable && !item.booking  && <Badge color="primary">Bookable</Badge>}
                                                </CardHeader>}
                                                {!item.event.backgroundImageId && <CardHeader style={{ "minHeight": "150px", "backgroundPosition": "center", "backgroundSize": "cover", "backgroundImage": "url('/img/missing-image.png')" }}>
                                                    {item.booking && <Badge color="success">Booked</Badge>}
                                                    {item.bookable && !item.booking && <Badge color="primary">Bookable</Badge>}
                                                </CardHeader>}
                                                <CardBody>
                                                    <h5>{item.event.name}</h5>
                                                    <strong><Moment utc local format="ddd, DD/MM" date={item.start} />:</strong> <Moment utc local format="h:mma" date={item.start} />
                                                    <p style={{ "height": "50px", "overflowY": "hidden" }}>{item.event.description}</p>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </Col>)}

                            </Row>
                        )}
                    </div>
                )}
            </div>);
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }}>
               
                {this.state.data && this.state.data.length > 0 && this.renderTable()}
                {this.state.data && this.state.data.length == 0 && <h5 className="text-center"><i>No upcoming events</i></h5>}
            </div>
        );
    }
}

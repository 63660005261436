import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class TermsAndConditions extends Component {

    render() {
        return (
            <Container style={{ "paddingTop": "130px" }}>
                <h1 style={{ "textAlign": "center" }}>The Pacifica Portal Terms and Conditions</h1>
                <hr style={{ "paddingBottom": "40px" }} />
                <ol className="termsClause">
                    <li>
                        <strong>Application:</strong> These terms and conditions ("<strong>Terms</strong>"), as well as our Privacy Policy at <a href="https://mypacifica.nz">https://mypacifica.nz</a> apply to your use of the Pacifica Portal ("<strong>Portal</strong>") which is the portal for the Pacifica Building, located at 10 Commerce Street, Auckland, 1010 ("<strong>The Pacifica</strong>"). By creating an account or profile on the Portal and using the Portal and its associated services, you agree to these Terms and our Privacy Policy. Please read them carefully.
                  </li>
                    <li>
                        <strong>Who we are:</strong> This Portal is operated by Hengyi Pacific NZ Limited  the management company for  The Pacifica. In these Terms, references to "we", "us" and "our" are references to Hengyi Pacific NZ Limited .  You can contact us at info@mypacifica.nz
                  </li>
                    <li>
                        <strong>Portal services:</strong> The Portal offers a range of services and functionality to different users including owners, residential tenants, occupiers, guests, employees, the body corporate management, third party service providers and anyone else who may need to use the Portal in connection with The Pacifica (together "<strong>Users</strong>"). The types of services offered differ depending on the User (eg staff have access to different functionality and services to residents and guests, and third party service providers have more limited access to the Portal which allows them to manage their service offerings and bookings) and such services and access rights may change from time to time. Where you order or book services through the Portal, we tell you any extra terms that apply on top of these Terms (which you must comply with).
                  </li>
                    <li>
                        <strong>Third party service providers:</strong>  We may have arrangements with third parties to provide certain services offered through the Portal (eg dog walkers, yoga instructors, laundry services and EV charging providers). Where a third party service provider is involved in providing your services, different or additional terms may apply, and the fees for your services might be different, including an additional administration/transaction fee of 2.5%. Please ask the third party service provider for more details. We may receive a commission from third party service providers in relation to their provision of services, and will disclose this to you through those service providers where this is the case. We are not responsible for any products or services that you purchase from the Third Party Supplier.
                  </li>
                    <li>
                        <strong>Fees and payment:</strong> When setting up your account, you will need to provide valid credit card or bank details. You will be informed of the fees that apply to a service, including service charges and an administration/transaction fee of 2.5% for any third party service procured via the Portal, when you are booking or ordering that service. Such fees will automatically be deducted from or charged to your account when you confirm your booking or order for the service. If payment does not go through (eg because you have changed your card details) you will not be able to complete your booking or order until you have resolved the issue.
                  </li>
                    <li>
                        <strong>Cancelling a service order:</strong> Depending on the service, you may be able to cancel an order or booking within 24 hours of such order or booking. You will be informed if and how you can do this when you are booking or ordering that service. If you cancel a service order or booking you may not be entitled to receive a refund for that booking. Please read the terms carefully when booking a service to determine if you will be entitled to a refund for cancellation.
                  </li>
                    <li>
                        <strong>We may cancel a service order:</strong> We may cancel a service order or booking at any time by notifying you via the Portal if we or the relevant third party service provider cannot offer that service to you for any reason (eg because of availability). If we cancel your service order, we will refund your payment for that service (unless cancellation is due to an issue with payment for the service).
                  </li>
                    <li>
                        <strong>Changes:</strong> We may make changes to the Portal, services, the price of services and/or these Terms from time to time. We may also cancel or phase out certain services. We will give you advance notice of:
                        <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>any changes to these Terms that apply to you;</li>
                            <li style={{ "padding": "0" }}>any increase to your ongoing fees that apply to you (we will try to give at least 30 days' notice of this); and</li>
                            <li style={{ "padding": "0" }}>any major change we make to the Portal or our services, where we think the change may have a negative impact on a significant portion of affected Users (we will try to give at least 30 days' notice of this, where practicable). </li>
                        </ol>
                        We may not give notice of changes that happen in the ordinary course of things, such as changes to our services (which happens all the time as our rights, relationships, and user interests change), or changes that we think will have a neutral or positive impact on affected Users.
                  </li>
                    <li>
                        <strong>Notice:</strong> Where we notify you of changes, we may do so by emailing you and/or publishing the change on our website.  If you are unhappy with any change we have made, you may choose to delete your account as described in clause 14.
                  </li>
                    <li>
                        <strong>Content and Intellectual Property:</strong>  All intellectual property and other rights in the Portal, associated services, and any content included on the Portal and in those services, are owned by us or our licensors.  Provided that you comply with these terms and other applicable laws, we grant you a limited licence to use the services that you purchase (in the manner that those services are provided to you), for your own personal non-commercial purposes only.  We do not grant you any other licence.  You must not, and you must not attempt or assist, authorise, enable or permit anyone else, to:

                       <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>access the Portal or any of our services or content without permission;</li>
                            <li style={{ "padding": "0" }}>copy, adapt, play or show in public, or communicate to the public, any part of the Portal or any of its associated services or content;</li>
                            <li style={{ "padding": "0" }}>otherwise redistribute, resupply, retransmit, reverse-engineer, decode, decompile, reformat or interfere with the Portal, or its associated services or content; or</li>
                            <li style={{ "padding": "0" }}>remove, circumvent, or interfere with any of the copyright notices or attributions, content protection, rights management, encryption or security technologies used for the Portal, its associated services or content.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Your general responsibilities:</strong>  All users of the Portal must:

                       <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>follow our reasonable instructions and directions about using the Portal and associated services, and only use the Portal and its associated services for lawful purposes;</li>
                            <li style={{ "padding": "0" }}>not interfere with our or anyone else's account, or use of our Portal or associated services; and</li>
                            <li style={{ "padding": "0" }}>comply with all applicable laws and not breach our or anyone else's rights. </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Your account, and letting other people use it:</strong>  To create an account on the Portal, you need to be an owner, tenant, occupier, guest, employee, third party service provider, or have some other relevant interest in The Pacifica which means you need to use the Portal. If you create an account on the Portal, you agree to keep it secure, for example by keeping your username and account passwords secret.  You should also make sure that you keep your account information updated, as we use this information to contact you if we need to.  If you think someone has had access to your account without permission, please tell us straight away.  Tenants may let other occupants use their account, but nobody else. Whenever someone other than you uses your account, you must:


                       <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>supervise them appropriately;</li>
                            <li style={{ "padding": "0" }}>pay any fees that apply, regardless of who incurs the fees; and</li>
                            <li style={{ "padding": "0" }}>make sure they comply with the law and these Terms. </li>
                        </ol>

                        For clarity, owners and tenants must have separate accounts as those accounts will have access to different functionality and services within the Portal. An owner may manage more than one apartment on the same account.

                  </li>
                    <li>
                        <strong>Communications:</strong> We may allow Users to communicate through or in connection with the Portal. Any communications between Users via or in connection with the Portal must not include any material that could be considered unlawful, harmful, harassing, defamatory, offensive, threatening, obscene, misleading, infringing or otherwise inappropriate. If we become aware of any communications that we believe violate these Terms, we may (without limiting our other rights) take steps to terminate your access to the Portal or our services and/or delete your account.
                  </li>
                    <li>
                        <strong>Deleting your account:</strong> You can delete your account at any time in your account settings. Your account will automatically be deleted when your relationship with us and The Pacifica ends (eg because your sell or stop leasing an apartment or stop providing services to The Pacifica).
                  </li>
                    <li>
                        <strong>We may delete or suspend your account:</strong> We may delete or suspend your account at any time by giving you 28 days written notice via the Portal or contacting you using the contact details registered to your account. We may also immediately delete or suspend your account or access to any service (without limiting our other rights) if:

                       <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>it is reasonably necessary for operational, technical or security reasons;</li>
                            <li style={{ "padding": "0" }}>we have reasonable grounds to believe that you are in breach of these Terms or your account is being used fraudulently or to break the law; or</li>
                            <li style={{ "padding": "0" }}>you do not pay any applicable fees by the due date or there is money owing on your account.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>After your account is deleted:</strong> When your account is deleted, you will no longer have access to the Portal, its information, or the services provided through it. If you would like to create a new account at any point in the future please contact us at info@mypacifica.nz
                  </li>
                    <li>
                        <strong>Portal limitations:</strong> We try to make sure that the Portal and its services are reliable and give a great user experience. But we cannot promise that the Portal or its services will always be available or completely error free or that defects will always be corrected, especially because we rely on the internet, and other third party networks and services.  If you notice a fault with the Portal or a service, please let us know and we will try to fix it.  But please be aware that:

                       <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>the performance of the Portal may depend on what kind of device and connection you have, and what else is running on your device at the time;</li>
                            <li style={{ "padding": "0" }}>we have regular maintenance windows where the Portal may not be available.  We try to schedule these for low-demand times; </li>
                            <li style={{ "padding": "0" }}>although we use industry standard security tools, the internet is never completely secure and we don't guarantee security; </li>
                            <li style={{ "padding": "0" }}>the availability of the Portal, and/or features and functionality, may vary depending on where you are located;</li>
                            <li style={{ "padding": "0" }}>where the Portal or services link to third party websites or services, we are not responsible for the content, availability or security of such third party websites or services; and</li>
                            <li style={{ "padding": "0" }}>we are not responsible for third party outages, or any other cause or event beyond our reasonable control.  </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Liability of Service Providers:</strong>  Where we use service providers to provide the services,  Hengyi Pacific (NZ) Limited, its affiliates, any management company for The Pacifica, and their respective employees and agents, do not take any liability for those third party services.
                    </li>
                    <li>
                        <strong>Indemnity:</strong> By agreeing to these Terms, you agree to indemnify and hold harmless Hengyi Pacific (NZ) Limited , its affiliates, any management company for The Pacifica, and their respective employees and agents against any and all claims, suits, actions, liabilities, costs and expenses arising out of a breach by you or any user of your account of these Terms or arising out of a breach of your obligations under these Terms, unless your breach is directly caused by our own breach of the Terms.
                    </li>
                    <li>
                        <strong>Liability:</strong>   Nothing in these Terms affects your rights under mandatory provisions of applicable law that cannot be excluded.  Except for liability under those laws (and any other liability that cannot be limited by law):


                        <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>if you do not have a current account with us, then we have no liability to you whatsoever (whether in contract, tort including negligence, under statute, in equity or otherwise) in connection with this website, these Terms or our services; and</li>
                            <li style={{ "padding": "0" }}>
                                if you do have a current account with us, then your total liability to us and our total liability to you (whether in contract, tort including negligence, under statute, in equity or otherwise) in connection with the Portal, these Terms and/or our services will not exceed:

                                <ol style={{ "listStyleType": "lower-roman" }}>
                                    <li style={{ "padding": "0" }}>$100 for any event or series of related events; and </li>
                                    <li style={{ "padding": "0" }}>$500 for all events in a 12-month period.</li>
                                </ol>
                            </li>
                        </ol>

                        This liability cap will not apply to:
                        <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>either of our fraud, intentional breach or intentional damage; or  </li>
                            <li style={{ "padding": "0" }}>non-payment, breach of clause 10, intellectual property infringement, or criminal activity by you or someone else using your account.</li>
                        </ol>

                        We will not be liable for any loss that is caused by you or by your failure to take reasonable steps to avoid or minimise your loss.
                        
                    </li>
                    <li>
                        <strong>General</strong>
                        <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li style={{ "padding": "0" }}>These Terms are governed by New Zealand law, and you submit to the jurisdiction of the New Zealand courts. </li>
                            <li style={{ "padding": "0" }}>We may at any time assign your account, the services or this contract to a third party, so long as this does not materially affect your rights.  You must not assign or otherwise transfer your rights or obligations under this contract without our prior written consent.</li>
                            <li style={{ "padding": "0" }}>If any part of these Terms is held to be invalid, unenforceable or illegal, then the rest of these Terms will continue in full force and effect.</li>
                            <li style={{ "padding": "0" }}>If our contract ends for any reason, it does not affect any rights and responsibilities that are intended to continue or come into force afterwards. </li>
                        </ol>
                    </li>
                </ol>
            </Container>
        );
    }
}

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Badge, Spinner } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { HengyiApi } from '../../infrastructure/fetchHengy';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { Link, Redirect } from 'react-router-dom';

export class Apartments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skip: 0,
            take: 50,
            total: 0,
            vehicles: [],
            modal: false,
            id: ""
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        if (!this.state.loading) {
            this.setState({ loading: true });
            this.fetchData();
        }
    }

    async previous() {
        this.setState({
            skip: this.state.skip - this.state.take
        }, () => this.fetchData());
    }

    async next() {
        this.setState({
            skip: this.state.skip + this.state.take
        }, () => this.fetchData());
    }

    async fetchData() {

        this.setState({
            loading: true
        });

        var currentUser = await HengyiApi.GetCurrentUser();
        this.setState({
            loading: false,
            apartments: currentUser.apartments
        });
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Space" subTitle="Details about your space" />

                <Container>

                    {this.state.apartments && this.state.apartments.map(item =>
                        <Card className="mt-5">
                            <CardHeader>  <h6 className="m-0" style={{ lineHeight: "34px" }}>{item.apartment.name}&nbsp;
                                    {item.relationship === "resident" && <Badge color="primary">Resident</Badge>}
                                {item.relationship === "owner" && <Badge color="primary">Owner</Badge>}
                                {item.relationship === "ownerOccupier" && <Badge color="primary">Owner occupier</Badge>}

                                <Link style={{ float: "right", padding: "6px" }} to={"/profile/apartments/edit/" + item.apartment.id} className="btn mr-2 btn-outline-dark">Edit</Link>
                            </h6></CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h6>Details</h6>
                                        <hr />
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Number: </td>
                                                <td>{item.apartment.number}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Settled: </td>
                                                <td>{item.apartment.settled ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Power meter (ICP):</td>
                                                <td>{item.apartment.powerMeterNumber ? item.apartment.powerMeterNumber : <i>Not specified</i>}</td>
                                            </tr>
                                            {item.apartment.propertyTitleNumber && <tr>
                                                <td style={{ paddingRight: "20px" }}>Property title #: </td>
                                                <td>{item.apartment.propertyTitleNumber}</td>
                                            </tr>}
                                        </table>
                                    </Col>
                                    <Col>
                                        <h6>Accessibility</h6>
                                        <hr />
                                        {item.apartment.hasAccessibilityRequirements && <table>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Orientation required: </td>
                                                <td>{item.apartment.accessibilityOrientationRequired ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Medical requirements: </td>
                                                <td>{item.apartment.accessibilityMedicalRequired}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Details: </td>
                                                <td>{item.apartment.accessibilityRequirement}</td>
                                            </tr>
                                        </table>}
                                        {!item.apartment.hasAccessibilityRequirements && <i>No specific accessibility requirements listed</i>}
                                    </Col>
                                    <Col>
                                        <h6>Letting agent</h6>
                                        <hr />
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "20px" }}>Agent: </td>
                                                <td>{item.apartment.lettingAgentName ? item.apartment.lettingAgentName : <i>None</i>}</td>
                                            </tr>
                                            {item.apartment.lettingAgentName && <>
                                                <tr>
                                                    <td style={{ paddingRight: "20px" }}>Business: </td>
                                                    <td>{item.apartment.lettingAgentBusiness ? item.apartment.lettingAgentBusiness : <i>Not specified</i>}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingRight: "20px" }}>Mobile: </td>
                                                    <td>{item.apartment.lettingAgentMobile ? item.apartment.lettingAgentMobile : <i>Not specified</i>}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingRight: "20px" }}>Email: </td>
                                                    <td>{item.apartment.lettingAgentEmail ? item.apartment.lettingAgentEmail : <i>Not specified</i>}</td>
                                                </tr></>}
                                        </table>
                                    </Col>
                                </Row>


                                {item.apartment.description && <div><hr />{item.apartment.description}</div>}
                            </CardBody>
                        </Card>
                    )}

                </Container>
            </div>
        );
    }
}

import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ListBookingTypeQueryParams extends HengyiListQueryParams {
    WithScheduleId(value: string) {
        this.WithParam("scheduleId", value);
        return this;
    }

    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithEvent(value: string) {
        this.WithParam("eventId", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }
}

export class HengyiBookingTypesClient {

    static async List(params: ListBookingTypeQueryParams) {

        var query = (await GetAPIDomain()) + "/booking-types" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            headers: GetAPIHeaders()
        }));
    }

    static async Create(
        name: string,
        isMultiple: boolean,
        doesNotRequireApproval: boolean,
        maximumBookingLength: number,
        scheduleId: string,
        audiences?: any) {

        var request: any = {
            name: name,
            isMultiple: isMultiple,
            maximumBookingLength: maximumBookingLength,
            scheduleId: scheduleId,
            audiences: audiences,
            doesNotRequireApproval: doesNotRequireApproval
        }

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify(request)
        }));
    }

    static async Update(
        id: string,
        name: string,
        isMultiple: boolean,
        doesNotRequireApproval: boolean,
        maximumBookingLength: number,
        scheduleId: string,
        audiences?: any) {

        var updates = [{
                "op": "replace",
                "path": "name",
                "value": name
            }, {
                "op": "replace",
                "path": "scheduleId",
                "value": scheduleId
            }, {
                "op": "replace",
                "path": "isMultiple",
                "value": isMultiple
            }, {
                "op": "replace",
                "path": "doesNotRequireApproval",
                "value": doesNotRequireApproval
            }, {
                "op": "replace",
                "path": "maximumBookingLength",
                "value": maximumBookingLength
            }, {
                "op": "replace",
                "path": "audiences",
                "value": audiences
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Remove(id: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/booking-types/" + id, {
            method: 'delete',
            headers: GetAPIHeaders()
        }));
    }
}
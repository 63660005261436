import React, { Component } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody, Spinner, Alert } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { Redirect } from 'react-router-dom';
import { GetAPIDomain, GetAPIHeadersPatch } from './infrastructure/Helper';
import { HengyiApi } from './infrastructure/fetchHengy';
import { HengyiApartmentClient, ApartmentQueryParams } from './infrastructure/HengyiClient/Apartments';
import { GetAssetDomain } from './infrastructure/Helper';

function required(value) {
    if (!value)
        return 'This is a required field';
}

export class OnboardingOne extends Component {

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    constructor(props) {
        super(props);
        this.state = { loading: true, hasTrust: false, trust: "", email: "", name: "", surname: "", mobile: "", next: false, apartments: "" };
    }

    renderForm() {
        return (<Formik
            initialValues={{
                email: this.state.email,
                name: this.state.name,
                surname: this.state.surname,
                mobile: this.state.mobile,
                moveInIntention: "",
                moveInIntentionNotes: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                const response = await fetch((await GetAPIDomain()) + "/users/current", {
                    method: 'patch',
                    headers: GetAPIHeadersPatch(),
                    body: JSON.stringify([{
                        "op": "replace",
                        "path": "email",
                        "value": fields.email
                    }, {
                        "op": "replace",
                        "path": "name",
                        "value": fields.name
                    }, {
                        "op": "replace",
                        "path": "surname",
                        "value": fields.surname
                    }, {
                        "op": "replace",
                        "path": "mobile",
                        "value": fields.mobile
                    }, {
                        "op": "replace",
                        "path": "moveInIntention",
                        "value": fields.moveInIntention
                    }, {
                        "op": "replace",
                        "path": "moveInIntentionNotes",
                        "value": fields.moveInIntentionNotes
                    }])
                });

                if (!response.ok) {

                    if (response.status === 404) {
                        this.setState({ next: true });
                    } else {

                        const data = await response.json();

                        data.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });
                    }

                } else {
                    this.setState({ next: true });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values }) => (
            <Form>

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field name="name" type="text" validate={required} className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="surname">Surname</label>
                    <Field name="surname" validate={required} type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                    <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field name="email" validate={required} type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <Field name="mobile" validate={required} type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                    <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="moveInIntention">Moving in within</label>
                    <Field name="moveInIntention" validate={required} as="select" className={'form-control' + (errors.moveInIntention && touched.moveInIntention ? ' is-invalid' : '')}>
                        <option value="">Select an option</option>
                        <option value="1 month">1 month from settlement</option>
                        <option value="2 months">2 months from settlement</option>
                        <option value="3 months +">3 months+ from settlement</option>
                        <option value="Plan to rent">Plan to rent</option>
                        <option value="Plan to sell">Plan to sell</option>
                        <option value="Other">Other</option>
                    </Field>
                    <ErrorMessage name="moveInIntention" component="div" className="invalid-feedback" />
                </div>

                {values.moveInIntention === "Other" &&
                    <div className="form-group">
                        <label htmlFor="moveInIntentionNotes">Notes</label>
                        <Field name="moveInIntentionNotes" type="text" component="textarea" className={'form-control' + (errors.moveInIntentionNotes && touched.moveInIntentionNotes ? ' is-invalid' : '')} />
                        <ErrorMessage name="moveInIntentionNotes" component="div" className="invalid-feedback" />
                    </div>}

                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "CONTINUE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
                </Form>
            )}</Formik>);
    }


    renderContent() {
        this.setCurrentUserDetails();

        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>)
        } else {
            return this.renderForm();
        }
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/" />);
        } else {
            return (

                <div>
                    <HeroImage hideNav={true} backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Your profile" subTitle="Please check the details we hold on record are accurate" />
                    <Container style={{ "paddingTop": "20px" }}>
                        <Row>
                            <Col>
                                <h2>Your profile</h2>

                                {this.state.trust && <Alert color="info">
                                    We have you listed as a contact for <strong>{this.state.trust}</strong>, if this is not correct please let us know.
                    </Alert>}

                                <hr />
                                {this.renderContent()}
                            </Col>
                            <Col md="3">
                                {this.state.apartments && this.state.apartments.map(apartment =>
                                    <Card style={{marginBottom:"15px"}}>
                                        <CardHeader>{apartment.name}</CardHeader>
                                        <CardBody>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td><strong>Space</strong></td>
                                                    <td style={{ textAlign: "right" }}>{apartment.number}</td>
                                                </tr>
                                                {apartment.type && <tr>
                                                    <td><strong>Details</strong></td>
                                                    <td style={{ textAlign: "right" }}>{apartment.type.name}</td>
                                                </tr>}
                                            </table>
                                        </CardBody>
                                    </Card>)}
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }

    async setCurrentUserDetails() {

        var currentUser = await HengyiApi.GetCurrentUser();

        var response = await HengyiApartmentClient.List(new ApartmentQueryParams()
            .WithUser(currentUser.id)
            .Paginate(0, 50));

        if (this.state.loading) {

            this.setState({
                loading: false,
                name: currentUser.name,
                surname: currentUser.surname,
                email: currentUser.email,
                mobile: currentUser.mobile,
                trust: currentUser.trust,
                apartments: response.data.data
            });
        }
    }
}

OnboardingOne.contextType = userContext;
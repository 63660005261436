import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';

export class ParkedVehicleQueryParams extends HengyiListQueryParams {

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    IsPaid(value: Boolean) {
        this.WithParam("paid", value);
        return this;
    }

    WithHideCancelled(value: Boolean) {
        this.WithParam("hideCancelled", value);
        return this;
    }

    WithHideCollected(value: Boolean) {
        this.WithParam("hideCollected", value);
        return this;
    }
}

//******************************
// PARKED VEHICLES
//******************************
export class HengyiParkedVehicleClient {

    static async List(params: ParkedVehicleQueryParams) {

        var query = (await GetAPIDomain()) + "/parked-vehicles" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parked-vehicles/" + id, {
            headers: GetAPIHeaders()
        }));

    }

    static async Update(id: string, carParkId: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parked-vehicles/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "carParkId",
                "value": carParkId
            }])
        }));
    }

    static async SetAsCancelled(id: string, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parked-vehicles/" + id + "/cancel", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "message": notes
            })
        }));
    }

    static async SetAsCollected(id: string, notes: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/parked-vehicles/" + id + "/colected", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "message": notes
            })
        }));
    }
}
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Badge } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAPIDomain, GetAssetDomain } from '../infrastructure/Helper';
import { HengyiUserClient } from '../infrastructure/HengyiClient/Users';

export class ProfileEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, next: false };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        this.fetchData();
    }

    async fetchData() {
        this.setState({ loading: true });

        var response = await HengyiApi.GetCurrentUser();

        if (response.profileImageId) {
            this.setState({
                file: (await GetAssetDomain()) + "/image/" + response.profileImageId + ".jpg"
            });
        }

        this.setState({
            loading: false,
            init: true,
            data: response,
            profileImageId: response.profileImageId,
            name: response.name,
            surname: response.surname,
            email: response.email,
            mobile: response.mobile,
            preferredParcelDeliveryOption: response.preferredParcelDeliveryOption,
            preferredParcelDeliverySlot: response.preferredParcelDeliverySlot,
            pushNotificationsEnabled: response.pushNotificationsEnabled,
            preferredParcelDeliverySlot: response.preferredParcelDeliverySlot,
            preferredParcelDeliveryOption: response.preferredParcelDeliveryOption
        });
    }

    render() {

        if (this.state.next) {
            return (<Redirect to={"/profile"} />);
        } else {
            return (<div>
                <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Your profile" subTitle="Manage your details" />

                <Container style={{ "paddingTop": "20px" }}>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Edit your profile
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Keep your details up to date</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ padding: "6px", float: "right" }} to="/profile" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.state.init && <Formik
                                initialValues={{
                                    name: this.state.name,
                                    surname: this.state.surname,
                                    email: this.state.email,
                                    mobile: this.state.mobile,
                                    pushNotificationsEnabled: this.state.pushNotificationsEnabled,
                                    preferredParcelDeliveryOption: this.state.preferredParcelDeliveryOption,
                                    preferredParcelDeliverySlot: this.state.preferredParcelDeliverySlot
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    var imageId = this.state.profileImageId;

                                    if (fields.file) {

                                        let formData = new FormData();
                                        formData.append("file", fields.file);

                                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                                            method: 'post',
                                            headers: {
                                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                                            },
                                            body: formData
                                        });

                                        if (imageResult.ok) {
                                            imageId = (await imageResult.json()).id;
                                        }
                                    }

                                    const response = await HengyiUserClient.UpdateUser(this.state.data.id,
                                        fields.name,
                                        fields.surname,
                                        fields.email,
                                        fields.mobile,
                                        imageId,
                                        fields.preferredParcelDeliveryOption,
                                        fields.preferredParcelDeliverySlot,
                                        fields.pushNotificationsEnabled);

                                    if (!response.successful) {

                                        if (response.status === 404) {
                                            this.setState({ next: true });
                                        } else {

                                            response.validationErrors.map(error => {
                                                setFieldError(error.key, error.message);
                                                return {
                                                    [error.key]: error.message
                                                };
                                            });
                                        }

                                    } else {
                                        this.setState({ next: true });
                                    }

                                }}
                            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                                <Form>
                                    <Row>
                                        <Col>

                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="surname">Surname</label>
                                                <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                                <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="mobile">Mobile</label>
                                                <Field name="mobile" type="text" className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                                                <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                                            </div>

                                            <Row>
                                                <Col>
                                                    <h6 className="mt-4">Delivery preferences</h6>
                                                    <hr />
                                                    <div className="form-group">
                                                        <label htmlFor="type">Parcel preference</label>
                                                        <select type="select" name="preferredParcelDeliveryOption"
                                                            value={values.preferredParcelDeliveryOption}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={'form-control' + (errors.preferredParcelDeliveryOption && touched.preferredParcelDeliveryOption ? ' is-invalid' : '')}>
                                                            <option value="askEachTime">Ask each time</option>
                                                            <option value="bringUp">Bring up to my space (may incur small fee)</option>
                                                        </select>
                                                        <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                                                    </div>
                                                    {values.preferredParcelDeliveryOption === "bringUp" && <div className="form-group">
                                                        <label htmlFor="type">Preferred delivery slot</label>
                                                        <select type="select" name="preferredParcelDeliverySlot"
                                                            value={values.preferredParcelDeliverySlot}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={'form-control' + (errors.preferredParcelDeliverySlot && touched.preferredParcelDeliverySlot ? ' is-invalid' : '')}>
                                                            <option value="">Any delivery slot</option>
                                                            <option value="morning">Morning (before 12)</option>
                                                            <option value="afternoon">Afternoon (12-5)</option>
                                                            <option value="evening">Evening (after 5)</option>
                                                        </select>
                                                        <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                                                    </div>}
                                                </Col>
                                                <Col>
                                                    <h6 className="mt-4">Notification preferences</h6>
                                                    <hr />
                                                    <div className="form-group">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <label><Field name="pushNotificationsEnabled" type="checkbox" checked={values.pushNotificationsEnabled} /> Push notifications enabled</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="3" className="text-center">
                                            <div className="form-group">
                                                <h6 className="text-left">Profile image</h6>

                                                {this.state.file && <div>
                                                    <img className="rounded-circle" alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", height: "250px", marginTop: "20px", objectFit: "cover" }} />
                                                </div>}

                                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                                    this.setState({
                                                        file: URL.createObjectURL(event.target.files[0])
                                                    })
                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                }} />

                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="form-group mt-3">
                                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                            {!isSubmitting && "SAVE"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </button>
                                    </div>
                                </Form>
                            )}</Formik>}
                        </CardBody>
                    </Card>
                </Container>
            </div>);
        }
    }
}

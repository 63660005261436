import { ApiResponse, HengyiListQueryParams } from '../fetchHengy'
import { GetAPIDomain, GetAPIHeaders, GetAPIHeadersPatch } from '../Helper';


export class ListBookingQueryParams extends HengyiListQueryParams {
    WithDate(value: string) {
        this.WithParam("date", value);
        return this;
    }

    WithStatus(value: string) {
        this.WithParam("status", value);
        return this;
    }

    WithTarget(value: string) {
        this.WithParam("target", value);
        return this;
    }

    WithHideCancellation(value: boolean) {
        this.WithParam("hideCancellations", value);
        return this;
    }

    WithLimitToFutureBookings(value: boolean) {
        this.WithParam("limitToFutureBookings", value);
        return this;
    }

    WithFacility(value: string) {
        this.WithParam("facilityId", value);
        return this;
    }

    WithEvent(value: string) {
        this.WithParam("eventId", value);
        return this;
    }

    WithUser(value: string) {
        this.WithParam("userId", value);
        return this;
    }
}

export class HengyiBookingsClient {

    static async List(params: ListBookingQueryParams) {

        var query = (await GetAPIDomain()) + "/bookings" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: GetAPIHeaders()
        }));
    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            headers: GetAPIHeaders()
        }));
    }

    static async Create(
        notes: string,
        contactName: string,
        contactNumber: string,
        userId: string,
        bookingTypeId: string,
        scheduleId: string,
        start: string,
        end: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/", {
            method: 'post',
            headers: GetAPIHeaders(),
            body: JSON.stringify({
                "notes": notes,
                "contactName": contactName,
                "contactNumber": contactNumber,
                "userId": userId,
                "bookingTypeId": bookingTypeId,
                "scheduleId": scheduleId,
                "start": start,
                "end": end,
            })
        }));
    }

    static async UpdateNotes(
        id: string,
        notes: string,
        contactName: string,
        contactNumber: string) {

        var updates = [{
            "op": "replace",
            "path": "notes",
            "value": notes
        }, {
            "op": "replace",
            "path": "contactName",
            "value": contactName
        }, {
            "op": "replace",
            "path": "contactNumber",
            "value": contactNumber
        }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Update(
        id: string,
        notes: string,
        contactName: string,
        contactNumber: string,
        userId: string,
        bookingTypeId: string,
        scheduleId: string,
        approvedById: string,
        start: string,
        end: string,
        status: number) {

        var updates = [{
                "op": "replace",
                "path": "notes",
                "value": notes
            }, {
                "op": "replace",
                "path": "contactName",
                "value": contactName
            }, {
                "op": "replace",
                "path": "contactNumber",
                "value": contactNumber
            }, {
                "op": "replace",
                "path": "userId",
                "value": userId
            }, {
                "op": "replace",
                "path": "bookingTypeId",
                "value": bookingTypeId
            }, {
                "op": "replace",
                "path": "scheduleId",
                "value": scheduleId
            }, {
                "op": "replace",
                "path": "approvedById",
                "value": approvedById
            }, {
                "op": "replace",
                "path": "start",
                "value": start
            }, {
                "op": "replace",
                "path": "end",
                "value": end
            }, {
                "op": "replace",
                "path": "status",
                "value": status
            }];

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id, {
            method: 'patch',
            headers: GetAPIHeadersPatch(),
            body: JSON.stringify(updates)
        }));
    }

    static async Approve(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id + "/approve", {
            method: 'post',
            headers: GetAPIHeaders()
        }));
    }

    static async Cancel(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/bookings/" + id + "/cancel", {
            method: 'post',
            headers: GetAPIHeaders()
        }));
    }
}
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { HengyiApi } from './infrastructure/fetchHengy';
import { GetAssetDomain } from './infrastructure/Helper';
import { Link } from 'react-router-dom';

export class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, next: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    renderContent() {

        if (this.state.next === "dashboard") {
            return (<Redirect to="/" />);
        } else if (this.state.next === "change-password") {
            return (<Redirect to="/profile/change-password" />);
        } else {
            return (<Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (await HengyiApi.SignIn(fields.email, fields.password, error => {

                        if (error === "too_many_attempts") {
                            setFieldError("email", "Too many attempts, please wait 5 minutes before trying again");
                        } else if (error === "account_locked") {
                            setFieldError("email", "Your account is locked, please contact concierge to unlock");
                        } else {
                            setFieldError("email", "Invalid email or password");
                        }

                    })) {

                        this.context.refresh();
                        var actions = await HengyiApi.GetCurrentUserActions();
                        if (actions.requireTempPasswordReset) {
                            this.setState({ next: "change-password" });
                        } else {
                            this.setState({ next: "dashboard" });
                        }
                    }
                }}
            >{({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group text-right">
                        <Link to="/password-reset/begin">Forgotten password?</Link>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SIGN IN"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {

        return (

            <div>
                <HeroImage hideNav={true} backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Welcome Home" subTitle="Please sign in to access your account" />
                <Container style={{ "paddingTop": "20px" }}>
                    <h2>Sign in</h2>
                    <hr />
                    {this.renderContent()}
                </Container>
            </div>
        );

    }
}

SignIn.contextType = userContext;
import React, { Component } from 'react';
import { Container, Spinner, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert  } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { HengyiApi, FacilityQueryParams, ScheduleQueryParams, BookingTypeQueryParams } from '../infrastructure/fetchHengy';
import { Formik, Field, Form, ErrorMessage, ErrorSummary } from 'formik';
import Moment from 'react-moment';
import moment, { duration } from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { GetAssetDomain } from '../infrastructure/Helper';
import { HengyiSchedulesClient, ListSchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { HengyiBookingTypesClient, ListBookingTypeQueryParams } from '../infrastructure/HengyiClient/BookingTypes';
import { HengyiBookingSlotsClient, ListBookingSlotsQueryParams } from '../infrastructure/HengyiClient/BookingSlots';

function required(value) {
    if (!value)
        return 'This is a required field';
}

export class BookingNewItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            init: true,
            loading: true,
            next: false,

            modal: false,
            errorMessage: "",
            weekdays: [64, 1, 2, 4, 8, 16, 32],
           
            scheduleOptions: [],
            bookingTypeOptions: [],
            bookingSlotsOptions: [],

            scheduleId: "",
            bookingTypeId: "",
            bookings: [],
            errorMessages: []
        };
    }

    async componentDidMount() {

        const {
            date,
            facilityId,
            eventId
        } = this.props;

        var result = await HengyiSchedulesClient.List(new ListSchedulesQueryParams()
            .WithFacility(facilityId)
            .WithEvent(eventId)
            .WithDate(date).Paginate(0, 1000));

        var scheduleOptions = result.data.data;
        
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5),
            scheduleOptions: scheduleOptions
        }, () => this.RefreshOptions());

    }

    async RefreshOptions() {

        const {
            date,
            facilityId,
            eventId
        } = this.props;

        //Ensure scheduleId is set if available
        var scheduleId = this.state.scheduleId;
        var bookingTypeId = this.state.bookingTypeId;

        if (!scheduleId && this.state.scheduleOptions.length === 1) {

            scheduleId = this.state.scheduleOptions[0];

            this.setState({
                scheduleId: this.state.scheduleOptions[0],
            });
        }

        //Set booking options
        if (!scheduleId) {
            this.setState({
                bookingTypeOptions: [],
                bookingTypeId: ""
            });
        } else {
            var response = await HengyiBookingTypesClient.List(new ListBookingTypeQueryParams()
                .WithScheduleId(scheduleId.id).Paginate(0, 1000));

            var bookingTypeOptions = response.data.data.map(i => { return { value: i.id, label: i.name, maximumBookingLength: i.maximumBookingLength, isMultiple: i.isMultiple }; });

            this.setState({
                bookingTypeOptions: bookingTypeOptions
            });

            if (!bookingTypeId && bookingTypeOptions.length === 1) {

                bookingTypeId = bookingTypeOptions[0];

                this.setState({
                    bookingTypeId: bookingTypeOptions[0],
                });
            }
        }

        //Set booking options
        this.setState({ loading: true });

        var response = await HengyiBookingSlotsClient.List(new ListBookingSlotsQueryParams()
            .WithScheduleId(scheduleId.id)
            .WithBookingDate(date));

        this.setState({
            loading: false,
            init: true,
            bookingSlotsOptions: response.data
        });
    }

    async showMessage(msg) {
        this.setState({ modal: true, errorMessage: msg })
    }

    async clearMessage() {
        this.setState({ modal: false, errorMessage: "" })
    }

    onBookingChange = (index, duration) => {

        var bookings = this.state.bookings;

        //check if the user can select multiple bookings.
       // if (this.state.bookingType.isMultiple) {
            //check if user want to de-select an item
            if (this.isDeselect(index)) {
                return;
            }

            //validate selection are consecutive eg. 1,2,3 and not 1,2,5
        if (!this.isConsecutiveSelection(index)) {
            this.showMessage("You can only select hours in a consecutive order.");
                return;
            }

            //validate that current selections does not exceed bookingType.maximumBookingLength.
            if (this.exceedsMaximumBookingLength(duration)) {
                this.showMessage("Sorry, you have reached the maximum allowance of " + this.state.bookingTypeId.maximumBookingLength + " minutes for this booking.");
                return;
            }
        //} else {
        //    //multiple selection is not allowed clear array.
        //    bookings = [];
        //}

        bookings.push(index);
        this.setState({ bookings: bookings });
    }

    isDeselect(index) {
        var bookings = this.state.bookings;
        var idx = bookings.findIndex(i => i == index);
        //item not found (is new selection)
        if (idx == -1) {
            return false;
        }

        var length = bookings.length;
        //its first item, no need to remove subsequent items
        if (idx == 0) {
            length = 1;
        }

        bookings.splice(idx, length);
        this.setState({ bookings: bookings });
        return true;
    }

    exceedsMaximumBookingLength(duration) {
        var bookings = this.state.bookings;
        var total = (bookings.length + 1) * duration;
        return total > this.state.bookingTypeId.maximumBookingLength;
    }

    isConsecutiveSelection(index) {
        var bookings = this.state.bookings;
        var length = bookings.length - 1;
        var lastIndex = bookings[length];
        lastIndex++;

        //No elements on array (is the first selection).
        if (isNaN(lastIndex)) {
            return true;
        }

        return index == lastIndex;
    }

    renderForm() {
        return (<Formik
            initialValues={{
                bookingDate: "",
                scheduleId: "",
                bookingTypeId: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError, setFieldValue}) => {

                const {
                    date,
                    onSuccess
                } = this.props;

                var user = await HengyiApi.GetCurrentUser();

                var duration = this.state.bookingSlotsOptions[0].duration * this.state.bookings.length;
                var startTime = this.state.bookingSlotsOptions[this.state.bookings[0]].start;

                var time = moment(startTime, "HH:mm");
                var start = moment(new Date(date).toISOString())
                    .startOf('day')
                    .add(time.format("HH"), "hours")
                    .add(time.format("mm"), "minutes");

                var end = moment(start).add(duration, "minutes");

                var response = await HengyiApi.CreateBooking("", "", "", user.id, this.state.bookingTypeId.value, this.state.scheduleId.id, start.toISOString(), end.toISOString());

                if (!response.successful) {
                    this.showMessage(response.validationErrors.map(item => item.message).join(", "));
                } else {
                    this.setState({ id: response.data.id });
                    if (onSuccess)
                        onSuccess();
                }
            }}
        >{({ errors, isSubmitting, values, setFieldValue }) => (
                <Form>

                    {!this.state.loading && this.state.scheduleOptions && this.state.scheduleOptions.length === 0 && 
                        <div className="alert alert-danger" style={{ marginTop: "30px" }}>
                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Unavailable</strong> There are no slots available on that date, please choose a different day.</p>
                        </div>
                    }

                    {this.state.scheduleOptions && this.state.scheduleOptions.length > 1 &&
                        <div className="form-group">
                                <h2>Which schedule?</h2>
                                <hr />
                                <ListGroup>
                                    {this.state.scheduleOptions.map((item) => (
                                        <ListGroupItem key={item.id}
                                            style={{ margin: "0", textAlign: "left" }}
                                            active={values.scheduleId && item.id === values.scheduleId.id}
                                            onClick={() => {

                                                this.setState({
                                                    bookingSlotsOptions: [],
                                                    bookingTypeOptions: [],
                                                    bookings: [],
                                                    scheduleId: item,
                                                    bookingTypeId: ""
                                                });
                                                this.clearMessage();

                                                setFieldValue("scheduleId", item);
                                                setFieldValue("bookingTypeId", "");
                                                this.RefreshOptions();

                                            }}>

                                            <ListGroupItemHeading>{item.name}</ListGroupItemHeading>
                                            <ListGroupItemText style={{ textTransform: 'capitalize' }}>
                                               From {item.open} to {item.close}
                                            </ListGroupItemText>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                        </div>}

                    {this.state.bookingTypeOptions && this.state.bookingTypeOptions.length > 1 &&
                        <div className="form-group">
                                <h2>Select a booking type</h2>
                                <hr />

                                <ListGroup>
                            {this.state.bookingTypeOptions.map((item) => (
                                        <ListGroupItem key={item.value}
                                            style={{ margin: "0", textAlign: "left" }}
                                            active={(values.bookingTypeId && item.value === values.bookingTypeId.value)}
                                            onClick={() => {
                                                this.setState({
                                                    bookingSlotsOptions: [],
                                                    bookings: [],
                                                    bookingTypeId: item
                                                });
                                                this.clearMessage();
                                                setFieldValue("bookingTypeId", item);
                                                this.RefreshOptions();
                                            }}>

                                            <ListGroupItemHeading>{item.label}</ListGroupItemHeading>
                                            <ListGroupItemText>
                                                This booking has a maximum allowance of {item.maximumBookingLength} minutes.
                                        <br />
                                                {!item.isMultiple && <i>You can only have one active booking at a time.</i>}
                                            </ListGroupItemText>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                        </div>}
   
                    {this.state.bookingSlotsOptions && this.state.bookingSlotsOptions.length > 0 && <div className="form-group">
                        <h6>Select a booking time</h6>
                        <hr />

                        {this.state.bookingSlotsOptions.length === 0 && <h3 className="pt-3 pb-4 text-center text-muted"><i>No bookings to display</i></h3>}
                        <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap" }}>
                            {this.state.bookingSlotsOptions.map((item, index) => (
                                <ListGroupItem key={item.start}
                                    style={{
                                        flexWrap: "wrap", "width": "120px"
                                    }}
                                    disabled={!item.available}
                                    active={this.state.bookings && this.state.bookings.findIndex(i => i == index) != -1}
                                    onClick={() => { this.onBookingChange(index, item.duration); }}>

                                    <ListGroupItemHeading style={{ textAlign: "center" }}>{item.start.substring(0, item.start.length - 3)}</ListGroupItemHeading>
                                    <small>{item.capacity - item.booked} remaining</small>
                                </ListGroupItem>
                            ))}
                        </ListGroup>

                    </div>}


                    <Alert color="danger" isOpen={this.state.errorMessages.length > 0}>
                        {this.state.errorMessages.map(error => (
                            <p>{error.message}</p>
                        ))}
                    </Alert>

                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={() => this.clearMessage()}>Information</ModalHeader>
                    <ModalBody>
                        { this.state.errorMessage }
                    </ModalBody>
                    <ModalFooter>
                            <Button color="primary" onClick={() => this.clearMessage("")}>Ok</Button>
                    </ModalFooter>
                </Modal>

                <div className="form-group">
                    <Collapse isOpen={this.state.bookings.length != 0}>
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "CREATE BOOKING"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </Collapse>
                </div>
                
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/bookings" />);
        } else {
            return (<div><span>{this.state.init}</span>{ this.state.init && this.renderForm() }</div>);
        }
    }
}

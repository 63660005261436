import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { userContext } from '../../userContext';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow fixed-top" light style={{"backgroundColor":"#ffffffb5"}}>
                    <Container>
                    <NavbarBrand tag={Link} to="/">
                        {this.state && this.state.assetUrl && <img src={this.state.assetUrl + "/tenant/image/Logo.jpg?t=" + this.state.offset} alt="The Logo" style={{ "maxWidth": "150px" }} />}
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">

                                <userContext.Consumer>
                                {({ user, logoutUser }) => {
                                    if (HengyiApi.IsAuthenticated()) {
                                            return (
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/" onClick={logoutUser}>Sign out</NavLink>
                                                </NavItem>);
                                        } else {
                                            return (
                                                <NavItem>
                                                    <NavLink tag={Link} className="text-dark" to="/sign-in">Sign in</NavLink>
                                                </NavItem>);
                                        }
                                    }}
                                </userContext.Consumer>

                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
        );
    }
}
  
import React, { Component } from 'react';
import { Container, Navbar, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserCog } from '@fortawesome/free-solid-svg-icons'
import { Route } from 'react-router';
import './SubNav.css';
import { HengyiApi } from '../infrastructure/fetchHengy';


export class SubNav extends Component {

    constructor(props) {
        super(props);
        this.state = { actions: "" };
    }

    async componentDidMount() {
        this.setState({
            actions: await HengyiApi.GetCurrentUserActions()
        });
    }

    render() {
        return (
            <Navbar className="navbar-expand-sm ng-white border-bottom box-shadow" light style={{ "backgroundColor": "#ffffffb5" }}>
                <Container>
                    <ul className="nav navbar-nav flex-grow">

                        <NavItem>
                            <Route path="/" exact>
                                {({ match }) =>
                                    <NavLink tag={Link} className={(match ? 'active ' : undefined) + "text-dark"} to="/"><FontAwesomeIcon icon={faHome} /></NavLink>
                                }
                            </Route>
                        </NavItem>

                        <Route path="/" exact>
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem"}}>Dashboard</NavItem>}
                        </Route>

                        <Route path="/messages">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Messages</NavItem>}
                        </Route>

                        <Route path="/documents">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Documents</NavItem>}
                        </Route>

                        <Route path="/my-documents">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Documents</NavItem>}
                        </Route>

                        <Route path="/profile/notices">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Notices</NavItem>}
                        </Route>

                        <Route path="/bookings">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Bookings</NavItem>}
                        </Route>

                        <Route path="/requests">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Reports</NavItem>}
                        </Route>

                        <Route path="/events">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Events</NavItem>}
                        </Route>

                        <Route path="/deliveries">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Deliveries</NavItem>}
                        </Route>

                        <Route path="/ev-charging-sessions">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>EV Charging</NavItem>}
                        </Route>

                        <Route path="/valet">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Valet</NavItem>}
                        </Route>

                        <Route path="/profile" exact>
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>My details</NavItem>}
                        </Route>
                        <Route path="/profile/change-password" exact>
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>My details</NavItem>}
                        </Route>
                        <Route path="/profile/edit" exact>
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>My details</NavItem>}
                        </Route>

                        <Route path="/profile/billing">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Billing</NavItem>}
                        </Route>

                        <Route path="/profile/pets">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Pets</NavItem>}
                        </Route>

                        <Route path="/profile/vehicles">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Vehicles</NavItem>}
                        </Route>

                        <Route path="/profile/occupants">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Occupants</NavItem>}
                        </Route>

                        <Route path="/profile/apartments">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Spaces</NavItem>}
                        </Route>

                        <Route path="/profile/swipe-cards">
                            {({ match }) => match && <NavItem style={{ padding: "0.5rem 1rem" }}>Swipe cards</NavItem>}
                        </Route>

                    </ul>
                </Container>
            </Navbar>
        );
    }

}

import React, { Component } from 'react';
import { Container, Row, Col, CardHeader, Card, CardBody, Spinner, Alert } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HeroImage } from './layout/HeroImage';
import { userContext } from '../userContext';
import { Redirect, Link } from 'react-router-dom';
import { GetAPIDomain, GetAPIHeadersPatch } from './infrastructure/Helper';
import { HengyiApi } from './infrastructure/fetchHengy';
import SignatureCanvas from 'react-signature-canvas'
import { HengyiInductionClient } from './infrastructure/HengyiClient/Inductions';
import { GetAssetDomain } from './infrastructure/Helper';

function required(value) {
    if (!value)
        return 'This is a required field';
}

export class CompleteInduction extends Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, next: false, submitting: false };
    }

    sigCanvas;

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });

        await this.load();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                agreed: false
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.agreed) {
                    setFieldError("agreed", "Please download and read the document");
                    return;
                }

                this.setState({
                    submitting: true
                });

                var canvas = this.sigCanvas.getCanvas();

                canvas.toBlob(async blob => {

                    let formData = new FormData();
                    formData.append("file", blob);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    var imageId = null;

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }

                    await HengyiInductionClient.Create(null, null, imageId);
                    this.setState({
                        next: true,
                        submitting: false
                    });
                });

               

                //if (!response.ok) {

                //    if (response.status === 404) {
                //        this.setState({ next: true });
                //    } else {

                //        const data = await response.json();

                //        data.validationErrors.map(error => {
                //            setFieldError(error.key, error.message);
                //            return {
                //                [error.key]: error.message
                //            };
                //        });
                //    }

                //} else {
                //    this.setState({ next: true });
                //}

            }}
        >{({ errors, status, touched, isSubmitting, values }) => (
            <Form>
                    <div className="form-group">
                        <ul className="list-unstyled">
                            <li>
                                <label><Field name="agreed" type="checkbox" checked={values.agreed} /> I have downloaded and read the induction document</label>
                                {touched && errors.agreed && <p className="error-text">{errors.agreed}</p>}
                            </li>
                        </ul>
                    </div>

                    {values.agreed && <div>
                        <h4>Signature</h4>
                        <p><i>Please sign in the area below</i></p>
                        <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={(ref) => { this.sigCanvas = ref }}/>
                    </div>}

                   <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!this.state.submitting && "I HAVE READ AND AGREE TO THE DOCUMENT"}
                            {this.state.submitting && <Spinner animation="border" />}
                        </button>
                        <Link className="btn mr-2 btn-outline-dark mt-2" to="/">Cancel</Link>
                    </div>
                </Form>
            )}</Formik>);
    }


    async downloadFile(id, name) {

        var data = await HengyiApi.GetDocumentDownloadLink(id);

        var link = document.createElement('a');
        link.href = data.link;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

        this.setState({ loading: true });
        this.load();
    }


    renderContent() {
        return this.renderForm();
    }

    render() {
        if (this.state.next) {
            return (<Redirect to="/" />);
        } else {
            return (

                <div>
                    <HeroImage hideNav={true} backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t="+this.state.offset} padding="100px" coverTitle="Building induction" subTitle="Please review the induction document" />
                    <Container style={{ "paddingTop": "20px" }}>
                        <h2>Induction document</h2>
                        <p>Please download and review the induction documentation for the building.</p>
                        <hr />
                        {this.state.file && <Row style={{paddingTop: "10px"}}>
                                <Col>
                                    <h5>
                                    <img src={this.state.file.thumbnailImage} alt="File thumbnail" style={{ position: "relative", top: "-3px", height: "35px", marginRight: "15px" }} />
                                    {this.state.file.name}
                                    </h5>
                            </Col>
                            <Col style={{
                                    textAlign: "right"
                                }}>
                                <button onClick={() => { this.downloadFile(this.state.file.id, this.state.file.name + "." + this.state.file.fileType); return false; }} className="btn btn-outline-dark" style={{ right: "-15px", position: "relative" }}>Download</button>
                            </Col>
                        </Row>}
                       
                        <hr />
                        {this.renderContent()}
                    </Container>
                </div>
            );
        }
    }

    async load() {

        var actions = await HengyiApi.GetCurrentUserActions();

        if (actions.inductionDocumentId) {
            this.setState({
                file: (await HengyiApi.GetDocument(actions.inductionDocumentId)).data
            });
        }

        if (this.state.loading) {

            this.setState({
                loading: false
            });
        }
    }
}

CompleteInduction.contextType = userContext;
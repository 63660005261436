import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter,Badge, Button, Container, Card, CardBody, Row, Col } from 'reactstrap';
import { HeroImage } from '../layout/HeroImage';
import { Link } from 'react-router-dom';
import { HengyiApi } from '../infrastructure/fetchHengy';
import { GetAssetDomain } from '../infrastructure/Helper';
import Moment from 'react-moment';
import { HengyiEventClient } from '../infrastructure/HengyiClient/Events';
import { HengyiSchedulesClient, ListSchedulesQueryParams } from '../infrastructure/HengyiClient/Schedules';
import { BookingNewItem } from '../bookings/BookingNewItem';
import { HengyiBookingsClient, ListBookingQueryParams } from '../infrastructure/HengyiClient/Bookings';
import { userContext } from '../../userContext';


export class EventDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            showBookingForm: false
        };
        this.cancelBooking = this.cancelBooking.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
    }

    async toggle(id) {
        this.setState({ modal: !this.state.modal, id: id })
    }

    async cancelBooking() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var booking = await HengyiApi.CancelBooking(this.state.id);
           
            this.setState({
                loading: false
            });

            await this.populateData(this.props.match.params.id);
            this.toggle("");
        }
    }

    async componentDidMount() {

        this.populateData(this.props.match.params.id);
        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        })
    }

    async populateData(id) {

        var date = new URLSearchParams(this.props.location.search).get("date");
        var currentUser = await HengyiApi.GetCurrentUser();

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await HengyiEventClient.Get(id);

            if (response.data.isBookable) {

                var schedule = await HengyiSchedulesClient.List(new ListSchedulesQueryParams()
                    .WithEvent(id)
                    .WithDate(date));

                var bookings = await HengyiBookingsClient.List(new ListBookingQueryParams()
                    .WithDate(date)
                    .WithEvent(id)
                    .WithUser(currentUser.id));

                this.setState({
                    schedule: schedule.data.data,
                    bookings: bookings.data.data
                });
            }

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

    async handleMessageChange(event) {
        this.setState({ message: event.currentTarget.value });
    }

    async addMessage() {

        var message = this.state.message;
        this.setState({ message: "" });

        if (message) {
            var response = await HengyiApi.AddTicketMessage(this.props.match.params.id, message);
            this.setState({
                data: response.data
            });
        }
    }

    render() {
        return (
            <div>
                <HeroImage backgroundImg={this.state.data && this.state.data.backgroundImageId ? this.state.assetUrl + "/image/" + this.state.data.backgroundImageId + ".jpg" : ""} padding="100px" coverTitle="Events" subTitle="Details about the event" />


                <userContext.Consumer>
                    {({ user, logoutUser, features }) => {

                        return (<Container style={{ "paddingTop": "20px" }}>
                            <div className="mb-3">
                                <h2>{this.state.data && this.state.data.location}
                                    <Link to="/events" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}> Back </Link>
                                </h2>
                                <hr />
                            </div>
                            {this.state.data && <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6>Details</h6>
                                            <hr />
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Name:</td>
                                                        <td>
                                                            {this.state.data.name && this.state.data.name}
                                                            {!this.state.data.name && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Location:</td>
                                                        <td>
                                                            {this.state.data.location && this.state.data.location}
                                                            {!this.state.data.location && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    {!this.state.data.isBookable &&
                                                        <tr>
                                                            <td className="pr-2">Start:</td>
                                                            <td>
                                                                {this.state.data.startTime && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.startTime}</Moment>}
                                                                {!this.state.data.startTime && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {!this.state.data.isBookable &&
                                                        <tr>
                                                            <td className="pr-2">End:</td>
                                                            <td>
                                                                {this.state.data.endTime && <Moment utc local format="ddd DD MMM YY, h:mm a">{this.state.data.endTime}</Moment>}
                                                                {!this.state.data.endTime && <i>Not specified</i>}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {this.state.data.isBookable &&
                                                        <tr>
                                                            <td className="pr-2">Event date:</td>
                                                            <td>
                                                                <Moment utc local format="ddd DD MMM YY">{new URLSearchParams(this.props.location.search).get("date")}</Moment>: {this.state.schedule && this.state.schedule.map(item => <span key={item.open}>{item.open.substring(0, item.open.length - 3)} - {item.close.substring(0, item.close.length - 3)}</span>)}
                                                            </td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td className="pr-2">Description:</td>
                                                        <td>
                                                            {this.state.data.description && this.state.data.description}
                                                            {!this.state.data.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        {features && features.includes("bookings") && this.state.data.isBookable && <Col>
                                            <h6>Booking</h6>
                                            <hr />
                                            {(!this.state.bookings || this.state.bookings.length == 0) && !this.state.showBookingForm &&
                                                <div className="alert alert-primary">
                                                    <Row>
                                                        <Col>
                                                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>Planning on attending? Please book in advance</p>
                                                        </Col>
                                                        <Col md="2">
                                                            <Button style={{ float: "right" }} onClick={() => this.setState({ showBookingForm: true })} to="/events" className="btn mr-2 mt-2 mb-2 btn-info">Book</Button>
                                                        </Col>
                                                    </Row>
                                                </div>}

                                            {(!this.state.bookings || this.state.bookings.length == 0) && this.state.showBookingForm &&
                                                <BookingNewItem onSuccess={() => this.populateData(this.props.match.params.id)} eventId={this.props.match.params.id} date={new URLSearchParams(this.props.location.search).get("date")} />}

                                            {(this.state.bookings && this.state.bookings.length > 0) && this.state.bookings.map(item =>

                                                <div className={item.status === "pending" ? "alert alert-info" : "alert alert-success"}>
                                                    <h6>
                                                        <Moment utc local format="ddd, DD/MM" date={item.start} />: <Moment utc local format="h:mma" date={item.start} /> - <Moment utc local format="h:mma" date={item.end} />


                                                        {item.status === "pending" && <Badge color="primary" style={{ float: "right" }}>Pending</Badge>}
                                                        {item.status === "confirmed" && <Badge color="success" style={{ float: "right" }}>Confirmed</Badge>}
                                                        {item.status === "cancelled" && <Badge color="secondary" style={{ float: "right" }}>Cancelled</Badge>}

                                                    </h6>
                                                    <p className="mt-0">
                                                        {item.bookingType.name}
                                                    </p>
                                                    <p className="mt-0">
                                                        {item.status !== 'cancelled' && (Date.parse(item.end) > Date.now()) && <Button key={item.id + "-view"} className={item.status === "confirmed" ? "btn btn-success btn-sm" : "btn btn-info btn-sm"} color="" onClick={() => this.toggle(item.id)} disabled={item.status == 'cancelled'}>Cancel booking</Button>}
                                                    </p>
                                                </div>)}

                                        </Col>}
                                        {!this.state.data.isBookable && <Col>
                                            <h6>Booking</h6>
                                            <hr />
                                            <div className="alert alert-success">
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>No booking required for this event</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>}
                                    </Row>
                                </CardBody>
                            </Card>}

                        </Container>)
                    }}
                </userContext.Consumer>


                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={() => this.toggle()}>Cancel confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure you want to cancel this booking?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.toggle("")}>No</Button>
                        <Button color="danger" onClick={() => this.cancelBooking()}>Yes</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }

}

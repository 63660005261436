import React, { Component } from 'react';
import { Container, Spinner, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { HeroImage } from '../../layout/HeroImage';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HengyiNoticeClient } from '../../infrastructure/HengyiClient/Notices';
import DatePicker from 'react-datepicker';
import { GetAssetDomain } from '../../infrastructure/Helper';

function required(value) {
    if (!value) {
        return 'This is a required field';
    }
}

export class NoticesNew extends Component {

    constructor(props) {
        super(props);
        this.state = { typeOptions: [], floorOptions: [], authenticated: true, next: false, id: "" };
    }

    async componentDidMount() {

        this.setState({
            assetUrl: await GetAssetDomain(),offset: (Math.round(new Date().getTime() / 5000) * 5)
        });
    }

    renderForm() {

        return (<Formik
            initialValues={{
                type: "info",
                description: "",
                expiry: "",
                name: ""
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.expiry) {
                    setFieldError("expiry", "Please set an expiry date");
                    return;
                }

                var response = await HengyiNoticeClient.Create(fields.title, fields.description, null, fields.expiry, fields.type);

                console.log(response);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }
            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="title">Name</label>
                                <Field name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                                {touched && errors.title ? <p className="error-text">{errors.title}</p> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="type">Type</label>
                                <select type="select" name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                    <option value="info">Information</option>
                                    <option value="warning">Warning</option>
                                    <option value="alert">Alert</option>
                                </select>
                                <ErrorMessage name="parcelType" component="div" className="invalid-feedback" />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="expiry">Expiry</label>

                                <DatePicker id="expiry"
                                    autoComplete="off"
                                    className="form-control"
                                    selected={values.expiry}
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(v, f) => {
                                        setFieldValue("expiry", v);
                                    }} />

                                {touched.expiry && errors.expiry ? <p className="error-text">{errors.expiry}</p> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <Field name="description" type="text" component="textarea" className="form-control" />
                                {touched.description && errors.description ? <p className="error-text">{errors.description}</p> : null}
                            </div>
                        </Col>
                    </Row>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "CREATE NOTICE"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {
        if (this.state.next) {
            return (<Redirect to={"/profile/notices/details/" + this.state.id} />);
        } else {
            return (
                <div>
                    <HeroImage backgroundImg={this.state.assetUrl + "/tenant/image/ResidentBanner.jpg?t=" + this.state.offset} padding="100px" coverTitle="Notices" subTitle="Submit a new notice to be approved" />


                    <Container style={{ "paddingTop": "20px" }}>
                        <Card>
                            <CardHeader>
                                <h2>New notice
                                    <Link to="/profile/notices" className="btn btn-outline-dark mt-2" style={{ padding: "6px", float: "right" }}>Cancel</Link>
                                </h2>
                            </CardHeader>
                            <CardBody>
                                {this.renderForm()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>

            );
        }
    }

}
